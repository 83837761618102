import React from 'react';
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

function AutoAddress(props) {
  const inputRef = useRef(null);
  const { state } = useLocation();

  //focusing the input element
  const focusInput = () => {
    inputRef.current.focus();
  };

  useEffect(() => {
    setValue(props.address);
    focusInput();
  }, []);

  // Setup for using Google Places Autocomplete
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  // Handle the selection of a suggestion
  const handleSelect =
    ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter as "false"

        setValue(description, false);

        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            props.changeaddress(description, lat, lng);

          })
          .catch((error) => {
            console.log('😱 Error: ', error);
          });
      };

  // Run this effect when the state changes
  useEffect(() => {
    if (state?.fullAddress?.address) {
      setValue(state.fullAddress?.address, false)
      clearSuggestions();
    }
  }, [state])

  // Function to render suggestions
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className='text-center cursor-pointer hover:text-color-primary '
          key={id}
          onClick={handleSelect(suggestion)}
        >
          <strong className='text-lg '>{main_text}</strong> {secondary_text}
        </li>
      );
    });

  return (
    <>
      <div className='flex items-center bg-white pl-1 rounded-l-md'>

        <input
          ref={inputRef}
          className={props.className}
          placeholder={props.label}
          value={value}
          onChange={handleInput}
          disabled={false}
          {...props}
        />
      </div>

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      <div className='absolute z-40 flex w-64 ml-3 center-styl'>
        {status === 'OK' && (
          <ul className='w-full p-2 bg-white shadow-lg '>
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </>
  );
}

export default AutoAddress;
