import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useLoginEmailAccount } from "hooks";
import GoogleApi from "components/SocialLogin/GoogleApi";
import FacebookApi from "components/SocialLogin/FacebookApi";
import { RiArrowDropDownFill } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

function Signin() {
  let navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/";
  // Hook to handle the API call for logging in with an email account
  const { mutateAsync: loginEmailAccount, isLoading } = useLoginEmailAccount();

  const [showPassword, setShowPassword] = useState(false);
  const [dropdownlogin, setDropdownlogin] = useState(false);
  const [loginAs, setLoginAs] = useState("Guest");
  const dropdownRef = useRef(null);

  //handling clicks outside the dropdown menu
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownlogin(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Formik function for managing form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      pass: "",
      username: "",
      loginAs: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .email("Invalid email format")
        .required("Email is Required"),
      pass: Yup.string().required("Password is Required"),
    }),
    onSubmit: async (values) => {
      values.loginAs = loginAs;
      const response = await loginEmailAccount(values);
      // navigate("/");
      navigate(from, { replace: true });
    },
  });
  return (
    <div className=" flex  flex-row-reverse rounded-lg md:h-[650px] shadow  sm:w-[80%] mx-auto overflow-hidden ">
      <div className="w-full rounded-md  ">
        <div className="relative p-4 w-[100%] md:h-[650px] flex flex-col bg-[#FFFFFF] min-w-0 break-words  mb-6  bg-auth ">
          <div className="px-6 py-6 mb-0 rounded-t ">
            <div
              ref={dropdownRef}
              className="relative  border border-slate-300 mb-2 p-2 rounded-md w-[200px] mx-auto"
            >
              <div
                onClick={() => setDropdownlogin(!dropdownlogin)}
                className=" flex justify-between items-center cursor-pointer"
              >
                <p className="text-color-darkgrey/50">Sign in as {loginAs}</p>

                <span>
                  <RiArrowDropDownFill className="text-2xl" />
                </span>
              </div>
              {dropdownlogin && (
                <div
                  className="absolute top-[100%] w-full bg-white left-0 border border-color-grey"
                  onClick={(e) => e.stopPropagation()} 
                >
                  <label
                    onClick={() => setLoginAs("Guest")}
                    className="flex items-center gap-2 p-2 border-b border-b-color-grey hover:bg-color-yellow/20"
                    htmlFor="guest-radio"
                  >
                    <input
                      type="radio"
                      name="user-type"
                      id="guest-radio"
                      checked={loginAs === "Guest"}
                    />
                    <p>Guest</p>
                  </label>
                  <label
                    onClick={() => setLoginAs("Host")}
                    className="flex items-center gap-2 p-2 border-b border-b-color-grey hover:bg-color-yellow/20"
                    htmlFor="host-radio"
                  >
                    <input
                      type="radio"
                      name="user-type"
                      id="host-radio"
                      checked={loginAs === "Host"}
                    />
                    <p>Host</p>
                  </label>
                  <label
                    onClick={() => setLoginAs("Influencer")}
                    className="flex items-center gap-2 p-2 hover:bg-color-yellow/20"
                    htmlFor="Influencer-radio"
                  >
                    <input
                      type="radio"
                      name="user-type"
                      id="Influencer-radio"
                      checked={loginAs === "Influencer"}
                    />
                    <p>Influencer</p>
                  </label>
                </div>
              )}
            </div>

            <div className="mb-3 text-center">
              <h6 className="text-2xl font-bold text-grey-500 ">Log In</h6>
              <h6 className="text-grey-500 text-md">
                Welcome! Let's get started!
              </h6>
            </div>
          </div>
          <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
            <form onSubmit={formik.handleSubmit}>
              <div className="relative w-full mb-3">
                <label
                  className="block mb-2 text-base font-medium text-[#000000]"
                  htmlFor="username"
                >
                  Email Address
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  className="w-full px-3 py-3 font-medium placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                  placeholder="Enter Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="py-1 text-sm text-red-500">
                    {/* Displaying specific error messages */}
                    {formik.errors.username === "Email is Required"
                      ? "Email is required"
                      : formik.errors.username === "Invalid email format"
                      ? "Invalid email format"
                      : formik.errors.username}
                  </div>
                ) : null}
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block mb-2 text-base font-medium text-[#000000]"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    name="pass"
                    id="pass"
                    type={showPassword ? "text" : "password"}
                    className="w-full px-3 py-3 font-medium placeholder:text-sm placeholder:text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl focus:outline-none focus:ring"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pass}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <IoEyeOutline className="text-[#929293] text-xl" />
                    ) : (
                      <IoEyeOffOutline className="text-[#929293] text-xl" />
                    )}
                  </div>
                </div>
                {formik.touched.pass && formik.errors.pass ? (
                  <div className="py-1 text-sm text-red-500">
                    {formik.errors.pass}
                  </div>
                ) : null}
              </div>

              <div className="flex items-center justify-between pt-10">
                <div className="flex items-center gap-3">
                  <input type="checkbox" />
                  <p className="text-sm font-medium text-black">Remember me</p>
                </div>
                <Link to="/auth/forgotpassword" className="text-sm font-medium">
                  Forgot Password
                </Link>
              </div>

              <div className="mt-6 text-center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <button
                      className="w-full px-6 py-3 mb-1 mr-1 font-bold text-white bg-blue-500 rounded-full shadow text-md hover:shadow-lg "
                      type="submit"
                    >
                      LOG IN
                    </button>
                  </>
                )}
              </div>

              <GoogleApi name="Sign in with Google" />
            </form>

            <p className="flex justify-center mt-2 text ">
              <span className="mr-2 text-gray-400">
                Don't have an account ?
              </span>
              <a href="/auth/signup" className="font-semibold">
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="relative hidden w-full md:block">
        <div className="absolute top-0 left-0 w-full h-full px-8 py-10 rounded-lg bg-black/50">
          <h2 className="text-3xl font-semibold text-white">
            Unlock Your Travel World{" "}
          </h2>
          <h2 className="text-3xl font-semibold text-white">
            Sign In to BnByond
          </h2>
          <div className="flex items-center mt-4">
            <img
              src={require("assets/img/icon.png")}
              className="w-4 mr-2 "
              alt=""
            />
            <p className="text-white">
              Unique stays and extraordinary experiences
            </p>
          </div>
        </div>
        <div className="h-full ">
          <img
            className="object-cover logo-landing"
            src={require("assets/img/login.jpg")}
          />
        </div>
      </div>
    </div>
  );
}

export default Signin;
