import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import userServices from 'services/httpService/userAuth/userServices';
import { storeLocalData } from '../services/auth/localStorageData';
import ErrorService from 'services/formatError/ErrorService';

export const AdminLoginEmailAccount = () => {
  return useMutation(
    (body) => {
      return userServices.adminLogin('/admin/adminlogin', body);  
    },
    {
      onSuccess: (data) => {
        console.log('data11111', data);

        // Check if the login was successful and if a valid response was received
        if (data.status) {
          // Always store user data in local storage, irrespective of the role
          storeLocalData(data.data);

          const roles = data.data.data.role;
          const isAdmin = data.data.data.isAdmin;
          console.log("Shit roles", roles)
          let userRole = '';

          // Check if the user is an admin
          if (isAdmin) {
            toast.success('Admin successfully logged in');
            return; // Exit the function early if it's an admin
          }

          // Determine the user role if not an admin
          if (roles.includes('subadmin')) {
            userRole = 'subadmin';
          } else if (roles.includes('Financial')) {
            userRole = 'Financial';
          } else if (roles.includes('Blogger')) {
            userRole = 'Blogger';
          } else {
            userRole = 'user'; 
          }

          // Display role-specific success messages
          switch (userRole) {
            case 'subadmin':
              toast.success('Subadmin successfully logged in');
              break;
            case 'Financial':
              toast.success('Financial user successfully logged in');
              break;
            case 'Blogger':
              toast.success('Blogger successfully logged in');
              break;
            default:
              toast.success('User logged in successfully');
              break;
          }
        }
      },
      onError: (err) => {
        toast.error(ErrorService.uniformError(err));
      },
    }
  );
};
