import React, { useState } from 'react'
import { DeviceHistory } from './DeviceHistory';
import { ChangePassword } from './ChangePassword';

export const LoginPrivacy = () => {
    const [selectedTab, setSelectedTab] = useState('password');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-color-green h-auto w-full p-5">
                <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
                    <ul className="flex flex-wrap -mb-px cursor-pointer">
                        <li className="me-2 text-[#AFB5C1]">
                            <p
                                onClick={() => handleTabClick('password')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'password'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                                Change Password                           
                                 </p>
                        </li>
                        <li className="me-2 ">
                            <p
                                onClick={() => handleTabClick('deviceHistory')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'deviceHistory'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                               Device History
                            </p>
                        </li>
                    </ul>
                </div>
                {selectedTab === 'password' && <ChangePassword />}
                {selectedTab === 'deviceHistory' && <DeviceHistory />}
            </div>
        </div>
    )
}
