import React, { useState, useEffect } from "react";
import { Container, Popover, Checkbox, FormControlLabel } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import AdminModal from "../AdminModal/AdminModal";
import userServices from "services/httpService/userAuth/userServices";
import icon3 from "assets/img/filter.png";
import icon4 from "assets/img/searchbar.png";
import icon5 from "assets/img/export.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { toast } from "react-toastify";

const roles = [
  { title: "Financial", value: "Financial" },
  { title: "Blogger", value: "Blogger" },
  { title: "Sub Admin", value: "subadmin" },
];

export default function Permissions() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRoles, setShowRoles] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const getUsers = async () => {
    try {
      setIsLoading(true);
      const response = await userServices.getAllEmployees();
      const fetchedUsers = response.data;
      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const lowercasedSearch = searchItem.toLowerCase();
    const filtered = users.filter(
      (user) =>
        user.emplname.toLowerCase().includes(lowercasedSearch) ||
        user.email.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredUsers(filtered);
  }, [searchItem, users]);

  const handleSearchChange = (e) => {
    setSearchItem(e.target.value);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenPopover = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleRoleChange = (roleValue) => {
    setSelectedUser(prevUser => ({
      ...prevUser,
      role: prevUser.role.includes(roleValue)
        ? prevUser.role.filter(r => r !== roleValue)
        : [...prevUser.role, roleValue]
    }));
  };

  const handleStatusChange = (event) => {
    setSelectedUser({
      ...selectedUser,
      status: event.target.checked,
    });
  };

  const handleUpdate = async () => {
    if (selectedUser) {
      try {
        await userServices.editEmployee(selectedUser._id, {
          role: selectedUser.role,
          status: selectedUser.status,
        });
        getUsers();
        handleClosePopover();
      } catch (error) {
        console.error("Error updating employee:", error);
      }
    }
  };

  // Toggles the selection of all users and updates the selected users list
  const handleSelectAll = () => {
    const newIsAllSelected = !isAllSelected;
    setIsAllSelected(newIsAllSelected);

    const updatedUsers = filteredUsers.map((user) => ({
      ...user,
      isSelected: newIsAllSelected,
    }));
    setFilteredUsers(updatedUsers);

    if (newIsAllSelected) {
      setSelectedUsers(updatedUsers.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  // Toggles the selection of a single user and updates the selected users list
  const handleCheckboxChange = (userId) => {
    const updatedUsers = filteredUsers.map((user) => {
      if (user._id === userId) {
        return { ...user, isSelected: !user.isSelected };
      }
      return user;
    });

    const updatedSelectedUsers = updatedUsers
      .filter((user) => user.isSelected)
      .map((user) => user._id);

    setFilteredUsers(updatedUsers);
    setSelectedUsers(updatedSelectedUsers);

    // Update the "Select All" state based on updated selected users
    setIsAllSelected(updatedSelectedUsers.length === filteredUsers.length);
  };

  // Updates the "Select All" checkbox state based on the selected users
  useEffect(() => {
    setIsAllSelected(
      selectedUsers.length === filteredUsers.length && filteredUsers.length > 0
    );
  }, [selectedUsers, filteredUsers]);


  // Handles the export button click and triggers the export if any transactions are selected
  const handleExportClick = () => {
    if (selectedUsers.length === 0) {
      toast.warning("Please Select Roles To Export");
      return;
    }

    document.getElementById("test-table-xls-button").click();
  };

  return (
    <section className="bg-[#f8f7fa]">
      <Container maxWidth="xl">
        <div className="my-4 flex justify-between pt-7">
          <h1 className="text-[24px] font-extrabold">Roles & Permissions</h1>
          {/* <button
            className="bg-[#2459BF] w-130 rounded-full w-[170px] h-[50] text-color-cream"
            onClick={handleOpenModal}
          >
            + ADD NEW ROLE
          </button> */}
        </div>

        <div className="my-3 flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white">
          <div className="w-full md:p-4 p-2 flex justify-between gap-2 bg-white rounded-t-xl items-center">
            <div>
              <p className="font-medium sm:text-lg text-sm">All Roles</p>
            </div>
            <div className="flex space-x-2">
              {/* <div className="flex justify-between sm:p-4 p-2 items-center w-[100px] h-[45px] border border-gray-300 rounded-md">
                <img src={icon3} alt="Filter" />
                <p className="font-normal text-xs text-gray-600">Filter</p>
              </div> */}
              <button
                onClick={handleExportClick}
                className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
              >
                <div className="w-4 h-4">
                  <img src={icon5} alt="Export Icon" className="w-full h-full" />
                </div>
                <div className="ml-2 sm:ml-0">
                  <p className="font-normal text-xs text-gray-600">Export</p>
                </div>
              </button>


              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="hidden"
                table="selected-users-table"
                filename="transactions"
                sheet="transactions"
                buttonText="Export"
              />

              <div className="relative md:w-64">
                <input
                  type="search"
                  value={searchItem}
                  onChange={handleSearchChange}
                  placeholder="Search by name, email"
                  className="border border-gray-400 outline-none sm:py-3 sm:px-4 py-3 text-xs rounded-md w-full"
                />
                <img
                  src={icon4}
                  alt="search icon"
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div className="overflow-auto">
            {isLoading ? (
              <div className="text-center py-4">Loading...</div>
            ) : (
              <>
                <table className="table-auto border-collapse w-[800px] md:w-full bg-white">
                  <thead>
                    <tr className="bg-[#fafbff]">
                      <th className="px-4 py-2 border-t border-b text-left">
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={isAllSelected}
                          onChange={handleSelectAll}
                        />
                        Name
                      </th>
                      <th className="px-4 py-2 border-t text-left border-b">Role</th>
                      <th className="px-4 py-2 border-t text-left border-b">Joined On</th>
                      <th className="px-4 py-2 border-t text-left border-b">Daily Report</th>
                      <th className="px-4 py-2 border-t text-left border-b">Permissions</th>
                      <th className="px-4 py-2 border-t text-left border-b">Status</th>
                      <th className="px-4 py-2 border-t text-left border-b">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user.id}>
                        <td className="px-4 py-2 border-t border-b">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={user.isSelected || false} // Ensure no undefined issues
                              onChange={() => handleCheckboxChange(user._id)}
                              className="mr-2"
                            />

                            <img
                              src={user.pic}
                              alt="Profile"
                              className="w-8 h-8 rounded-full mr-2"
                            />
                            <div className="flex flex-col">
                              <span className="font-medium text-sm leading-5 text-black">
                                {user.emplname}
                              </span>
                              <span className="font-medium text-xs leading-4 text-gray-400">
                                {user.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          {user.role?.join(", ")}
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          <div className="flex flex-col">
                            <span>{user.date}</span>
                            <span className="font-medium text-xs leading-4 text-gray-400">
                              {user.timeStamp?.slice(0, 10)}
                            </span>
                          </div>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          <button className="font-extrabold text-xs leading-4 text-center text-[#2459BF]">
                            View Report
                          </button>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          <button className="font-extrabold text-xs leading-4 text-center text-[#2459BF]">
                            View Details
                          </button>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          <div className={`w-[56px] h-[30px] ${user.status ? "bg-green-500" : "bg-red-500"} bg-opacity-10 rounded-full flex justify-center items-center`}>
                            <button className={`font-extrabold text-xs leading-4 text-center ${user.status ? "text-green-500" : "text-red-500"}`}>
                              {user.status ? "Active" : "Inactive"}
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          <BsThreeDotsVertical
                            className="cursor-pointer"
                            onClick={(event) => handleOpenPopover(event, user)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* seperate table for exporting to excel file */}
                <table id="selected-users-table" style={{ display: 'none' }}>
                  <thead>
                    <tr className="bg-[#fafbff]">
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Joined On</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user.id}>
                        <td>{user.emplname}</td>
                        <td> {user.email}</td>
                        <td>{user.role?.join(", ")}</td>
                        <td>{user.date + " " + user.timeStamp?.slice(0, 10)}</td>
                        <td>{user.status ? "Active" : "Inactive"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {filteredUsers.length === 0 && !isLoading && (
              <div className="text-center py-4">No users found</div>
            )}
          </div>
        </div>
      </Container>
      {isModalOpen && <AdminModal closeModal={handleCloseModal} />}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {selectedUser && (
          <div className="p-4 w-60 h-80">
            <h3 className="text-lg font-semibold mb-4">Edit User</h3>
            <div className="mb-24">
              <label htmlFor="roles" className="block text-sm font-medium text-gray-700 mb-1">
                Roles
              </label>
              <div
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer bg-white"
                onClick={() => setShowRoles(!showRoles)}
              >
                {selectedUser.role && selectedUser.role.length > 0 ? selectedUser.role.join(", ") : "Select Roles"}
              </div>
              {showRoles && (
                <div className="absolute z-10 mt-1 w-52 bg-white border border-gray-300 rounded-md shadow-lg">
                  {roles.map((role) => (
                    <div key={role.value} className="p-2 hover:bg-gray-100">
                      <label className="flex items-center space-x-2 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={selectedUser.role?.includes(role.value)}
                          onChange={() => handleRoleChange(role.value)}
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                        <span>{role.title}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser.status}
                  onChange={handleStatusChange}
                />
              }
              label="Active"
            />
            <button
              onClick={handleUpdate}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        )}
      </Popover>
    </section>
  );
}