import { ButtonFilled } from 'common/buttons/buttonFilled'
import React from 'react'
import { BiVolumeMute } from 'react-icons/bi'
import { FaRegUser } from 'react-icons/fa'
import { SlShareAlt } from 'react-icons/sl'

const ChatProfileDetails = ({ fullName, image, setSelectedProfile1, status }) => {
    return (
        <div className="w-full md:w-2/5 md:border-l-2 md:border-color-green md:px-5 fixed md:relative bg-white left-0 px-2 top-0 overflow-y-scroll   h-[100vh]">
            <div className="flex items-center justify-between">
                <div className=" py-4 text-black text-[20px] font-medium">
                    Details
                </div>
                <div className="cursor-pointer"
                 onClick={() => setSelectedProfile1(null)}
                 >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                    >
                        <path
                            d="M1.9668 1.61914L16.9638 16.6162"
                            stroke="black"
                            strokeWidth="2.3"
                            strokeLinecap="round"
                        />
                        <path
                            d="M16.9648 1.61914L1.96783 16.6162"
                            stroke="black"
                            strokeWidth="2.3"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </div>
            <div className="flex flex-col items-center justify-start cursor-pointer">
                <div className=''>
                    <img
                        src={image}
                        className="object-cover w-[150px] h-[150px] rounded-full"
                        alt=""
                    />
                </div>
                <div className="pt-4 text-xl font-medium">
                    {fullName}
                </div>
                {
                    status ?
                        <div className="flex gap-2">

                            <div className="rounded-full my-auto bg-yellow-500 h-[10px] border-5 w-[10px]"></div>
                            <p className="text-sm font-normal">
                                Online
                            </p>
                        </div>
                        :
                        <div className="flex gap-2">

                            <div className="rounded-full my-auto bg-yellow-500 h-[10px] border-5 w-[10px]"></div>
                            <p className="text-sm font-normal">
                                Offline
                            </p>
                        </div>
                }
                <div className="flex gap-5 py-5">
                    <div className="flex items-center justify-evenly">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <div className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer text-color-blue bg-color-blue bg-opacity-10 ">
                                <FaRegUser className='text-xl' />
                            </div>
                            <p className="">Profile</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-evenly">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <div className="w-10 h-10 m-auto cursor-pointer bg-opacity-10">
                                <div className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer text-color-blue bg-color-blue bg-opacity-10 ">
                                    <BiVolumeMute className='text-xl' />
                                </div>
                            </div>
                            <p className="">Mute</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-evenly">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <div className="w-10 h-10 m-auto cursor-pointer bg-opacity-10">
                                <div className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer text-color-blue bg-color-blue bg-opacity-10 ">
                                    <SlShareAlt className='text-xl' />
                                </div>
                            </div>
                            <p className="">Search</p>
                        </div>
                    </div>
                </div>
                <div className="w-full pb-5">
                    <ButtonFilled text="View full itinerary" />
                </div>
            </div>
        </div>
    )
}

export default ChatProfileDetails
