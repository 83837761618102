// import React, { useState } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { toast } from "react-toastify";
// import userServices from "services/httpService/userAuth/userServices";

// const roles = ["Financial", "Blogger", "subadmin"];

// const initialData = {
//   emplname: "",
//   jobTitle: "",
//   email: "",
//   phone: "",
//   gender: "",
//   password: "",
//   pic: null,
//   role: [],
// };

// export default function UserForm() {
//   const [formData, setFormData] = useState(initialData);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [showRoles, setShowRoles] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleRoleChange = (role) => {
//     const updatedRoles = formData.role.includes(role)
//       ? formData.role.filter((r) => r !== role)
//       : [...formData.role, role];
//     setFormData({ ...formData, role: updatedRoles });
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const s3Url = "https://bnbpictures.s3.amazonaws.com/";
//         const fullImageUrl = `${s3Url}${file.name}`;
//         setImagePreview(reader.result);
//         setFormData({ ...formData, pic: fullImageUrl });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const bnbyondData = JSON.parse(localStorage.getItem("Bnbyond"));
//     const userId = bnbyondData?.data?._id;
//     const finalFormData = { ...formData, registeredByAdmin: userId };
//     try {
//       // Assuming userServices.createEmployee is imported and available
//       await userServices.createEmployee(finalFormData);
//       toast.success("Employee created successfully!");
//       setFormData(initialData);
//       setImagePreview(null);
//     } catch (error) {
//       toast.error("Error creating employee.");
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
//       <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
//         <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Create Employee</h2>
//         <div className="grid md:grid-cols-2 gap-6">
//           <div>
//             <label htmlFor="emplname" className="block text-sm font-medium text-gray-700 mb-1">
//               Username
//             </label>
//             <input
//               type="text"
//               id="emplname"
//               name="emplname"
//               value={formData.emplname}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
//               placeholder="Enter your username"
//               required
//             />
//           </div>
//           <div>
//             <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 mb-1">
//               Job Title
//             </label>
//             <input
//               type="text"
//               id="jobTitle"
//               name="jobTitle"
//               value={formData.jobTitle}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
//               placeholder="Enter job title"
//               required
//             />
//           </div>
//           <div>
//             <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
//               Email
//             </label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
//               placeholder="Enter your email"
//               required
//             />
//           </div>
//           <div>
//             <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
//               Phone
//             </label>
//             <PhoneInput
//               country={"us"}
//               value={formData.phone}
//               onChange={(phone) => setFormData({ ...formData, phone })}
//               inputStyle={{
//                 width: "100%",
//                 height: "2.5rem",
//                 fontSize: "0.875rem",
//                 borderRadius: "0.375rem",
//                 paddingLeft: "3rem",
//               }}
//               containerStyle={{
//                 width: "100%",
//               }}
//             />
//           </div>
//           <div>
//             <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
//               Gender
//             </label>
//             <select
//               id="gender"
//               name="gender"
//               value={formData.gender}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
//               required
//             >
//               <option value="">Select Gender</option>
//               <option value="Male">Male</option>
//               <option value="Female">Female</option>
//               <option value="Other">Other</option>
//             </select>
//           </div>
//           <div className="relative">
//             <label htmlFor="roles" className="block text-sm font-medium text-gray-700 mb-1">
//               Roles
//             </label>
//             <div
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer bg-white"
//               onClick={() => setShowRoles(!showRoles)}
//             >
//               {formData.role.length > 0 ? formData.role.join(", ") : "Select Roles"}
//             </div>
//             {showRoles && (
//               <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
//                 {roles.map((role) => (
//                   <div key={role} className="p-2 hover:bg-gray-100">
//                     <label className="flex items-center space-x-2 cursor-pointer">
//                       <input
//                         type="checkbox"
//                         checked={formData.role.includes(role)}
//                         onChange={() => handleRoleChange(role)}
//                         className="form-checkbox h-4 w-4 text-blue-600"
//                       />
//                       <span>{role}</span>
//                     </label>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//           <div>
//             <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
//               Password
//             </label>
//             <input
//               type="password"
//               id="password"
//               name="password"
//               value={formData.password}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
//               placeholder="Enter your password"
//               required
//             />
//           </div>
//           <div>
//             <label htmlFor="pic" className="block text-sm font-medium text-gray-700 mb-2">
//               Upload Image
//             </label>
//             <div className="flex items-center justify-center w-full">
//               <label
//                 htmlFor="pic"
//                 className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
//               >
//                 <div className="flex flex-col items-center justify-center pt-5 pb-6">
//                   <svg
//                     className="w-8 h-8 mb-4 text-gray-500"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 20 16"
//                   >
//                     <path
//                       stroke="currentColor"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
//                     />
//                   </svg>
//                   <p className="mb-2 text-sm text-gray-500">
//                     <span className="font-semibold">Click to upload</span> or drag and drop
//                   </p>
//                 </div>
//                 <input id="pic" type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
//               </label>
//             </div>
//           </div>
//         </div>
//         {imagePreview && (
//           <div className="mt-4">
//             <p className="text-sm font-medium text-gray-700 mb-2">Image Preview:</p>
//             <img src={imagePreview} alt="Preview" className="w-32 h-32 object-cover rounded-md shadow" />
//           </div>
//         )}
//         <button
//           type="submit"
//           className="w-full mt-6 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// }


import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import userServices from "services/httpService/userAuth/userServices";
import { FaCloudUploadAlt } from "react-icons/fa";


const roles = [
  { title: "Financial", value: "Financial" },
  { title: "Blogger", value: "Blogger" },
  { title: "Sub Admin", value: "subadmin" },
];

const UserForm = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showRoles, setShowRoles] = useState(false);
  const fileInputRef = React.createRef(); // Ref to trigger file input
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowRoles(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    emplname: Yup.string().required("Username is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    pic: Yup.string().required("Profile picture is required"),
    role: Yup.array().min(1, "At least one role must be selected"),
  });

  const formik = useFormik({
    initialValues: {
      emplname: "",
      jobTitle: "",
      email: "",
      phone: "",
      gender: "",
      password: "",
      pic: null,
      role: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const bnbyondData = JSON.parse(localStorage.getItem("Bnbyond"));
      const userId = bnbyondData?.data?._id;

      // Create a new FormData instance
      const formData = new FormData();

      // Append each field to the FormData object
      formData.append("emplname", values.emplname);
      formData.append("jobTitle", values.jobTitle);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("gender", values.gender);
      formData.append("password", values.password);
      formData.append("role", JSON.stringify(values.role));
      if (values.pic) {
        formData.append("pic", values.pic);
      }
      formData.append("registeredByAdmin", userId);

      try {
        await userServices.createEmployee(formData);
        toast.success("Employee created successfully!");
        resetForm();
        setImagePreview(null);
      } catch (error) {
        toast.error("Error creating employee.");
        console.error("Error:", error);
      }
    },
  });

  // Handle Image Change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const s3Url = "https://bnbpictures.s3.amazonaws.com/";
        // const fullImageUrl = ${s3Url}${file.name};
        setImagePreview(reader.result);
        formik.setFieldValue("pic", file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Trigger file input click when the user clicks the upload area
  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl"
      >
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Create Employee
        </h2>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Username */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            <input
              type="text"
              name="emplname"
              value={formik.values.emplname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your username"
            />
            {formik.touched.emplname && formik.errors.emplname && (
              <p className="text-red-500 text-sm">{formik.errors.emplname}</p>
            )}
          </div>

          {/* Job Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter job title"
            />
            {formik.touched.jobTitle && formik.errors.jobTitle && (
              <p className="text-red-500 text-sm">{formik.errors.jobTitle}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your email"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-red-500 text-sm">{formik.errors.email}</p>
            )}
          </div>

          {/* Phone */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <PhoneInput
              country={"us"}
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue("phone", phone)}
              inputStyle={{
                width: "100%",
                height: "2.5rem",
                fontSize: "0.875rem",
                borderRadius: "0.375rem",
                paddingLeft: "3rem",
              }}
              containerStyle={{ width: "100%" }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="text-red-500 text-sm">{formik.errors.phone}</p>
            )}
          </div>

          {/* Gender */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Gender
            </label>
            <select
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {formik.touched.gender && formik.errors.gender && (
              <p className="text-red-500 text-sm">{formik.errors.gender}</p>
            )}
          </div>

          {/* Roles */}

          <div className="flex flex-col gap-0">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Roles
            </label>
            <div
              className={`w-full p-2 border border-gray-300 rounded-md cursor-pointer bg-white ${formik.values.role.length === 0 ? "text-gray-400" : "text-gray-900"
                }`}
              onClick={() => setShowRoles((prev) => !prev)} // Toggle dropdown visibility on input click
            >
              {formik.values.role.length > 0
                ? formik.values.role.join(", ")
                : "Select Roles"}
            </div>
            {showRoles && (
              <div
                ref={dropdownRef}
                className="absolute z-10 mt-1 w-auto pr-32 bg-white border border-gray-300 rounded-md shadow-lg"
                // style={{ top: "61%" }} // Ensures dropdown is below the input
              >
                {roles.map((role) => (
                  <div
                    key={role.value}
                    className={`p-2 hover:bg-gray-100 ${formik.values.role.includes(role.value) ? "bg-blue-50" : ""
                      }`}
                  >
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formik.values.role.includes(role.value)}
                        onChange={() =>
                          formik.setFieldValue(
                            "role",
                            formik.values.role.includes(role.value)
                              ? formik.values.role.filter((r) => r !== role.value)
                              : [...formik.values.role, role.value]
                          )
                        }
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span>{role.title}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
            {formik.touched.role && formik.errors.role && (
              <p className="text-red-500 text-sm">{formik.errors.role}</p>
            )}
          </div>




          {/* Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter password"
            />
            {formik.touched.password && formik.errors.password && (
              <p className="text-red-500 text-sm">{formik.errors.password}</p>
            )}
          </div>

          {/* Profile Picture */}
          <div className="">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Profile Picture
            </label>

            <div
              className="w-full p-2 rounded-md text-center cursor-pointer border border-gray-500 bg-gray-300"
              onClick={handleClickUpload}
            >
             
                <div className="flex items-center justify-center gap-2">
                  <FaCloudUploadAlt />
                  <p>Click to select an image</p>
                </div>
              
            </div>

            <input
              type="file"
              name="pic"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />

            {formik.touched.pic && formik.errors.pic && (
              <p className="text-red-500 text-sm">{formik.errors.pic}</p>
            )}
          </div>

          <div>
          {imagePreview && (
              <div className="mt-2 flex justify-start">
                <img
                  src={imagePreview}
                  alt="Profile Preview"
                  className="w-24 h-24 object-cover rounded-full"
                />
              </div>
            )}
          </div>

        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Create Employee
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;