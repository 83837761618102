import axios from "axios";
import { config } from "../../config";

let localData = JSON.parse(localStorage.getItem(config.localToken));
console.log("I am shit", localData?.data?.token);
console.log("I am TOKEN", localData?.token);

let Api;
if (
  (localData && localData?.data?.hasOwnProperty("token")) ||
  (localData && localData?.hasOwnProperty("token"))
) {
  Api = axios.create({
    baseURL: config.endPoint,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localData?.data?.token || localData?.token}`,
    },
  });
} else {
  Api = axios.create({
    baseURL: config.endPoint,
    headers: {
      "Content-type": "application/json",
    },
  });
}

// Log the headers of the created Axios instance
console.log("Axios Headers:", Api.defaults.headers);

export default Api;
