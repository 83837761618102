import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";

export const RatingStars = ({ rating }) => {
    // Calculate the number of filled and empty stars
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    // Generate the JSX for stars
    const stars = [];
    for (let i = 0; i < filledStars; i++) {
      stars.push(<FaStar key={i} />);
    }

    if (hasHalfStar) {
      stars.push(<FaStar key="half" style={{ color: 'orange' }} />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={`empty-${i}`} />);
    }

    return<div className="flex p-1 gap-1 text-center items-center justify-center text-orange-300">{stars}</div>;
  };
