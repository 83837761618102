import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GiCancel } from "react-icons/gi";
import { IoIosStar } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowTrendUp } from 'react-icons/fa6'
import { IoIosArrowBack } from 'react-icons/io'
import userServices from "services/httpService/userAuth/userServices";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import Rating from "react-rating";
import { localStorageData } from 'services/auth/localStorageData'


const HostDetail = ({ setSteps, userDetails }) => {

    const [slider, setSlider] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allProperty, setAllProperty] = useState();
    const [reservation, setReservation] = useState();

    const maxCharacters = 300;

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };


    //API call to get properties
    const getproperty = async () => {
        setLoading(true); // Start loading
        let res = await userServices.commonGetService(
            `/property/getAllPropertyAvailable`
        );
        setAllProperty(res.data.data);
        setLoading(false);
    };

    //API call to get reservation
    const getReservation = async () => {
        setLoading(true); // Start loading
        let res = await userServices.commonGetService(
            `/reservation/reservations/${localStorageData("_id")}?role=admin`
        );
        setReservation(res.data);
        setLoading(false);
    };

    useEffect(() => {
        getproperty();
        getReservation();
    }, []);


    const getUserData = (userId) => {
        if (allProperty && allProperty.length > 0) {
            const userProperties = allProperty.filter(property => property.userId === userId);

            if (userProperties.length > 0) {
                let totalReviews = 0;
                let totalAverageRating = 0;
                let propertiesWithReviewsCount = 0;

                // Combine all reviews from all properties
                let userReviews = [];

                const propertyData = userProperties.map(property => {
                    const propertyReviews = property.reviews || [];

                    // Combine all reviews into a single array
                    userReviews = [...userReviews, ...propertyReviews];

                    const totalRating = propertyReviews.reduce((acc, review) => acc + review.rating, 0);
                    const averageRating = propertyReviews.length > 0 ? (totalRating / propertyReviews.length).toFixed(1) : 0;

                    // Only include properties with reviews in the overall average rating calculation
                    if (propertyReviews.length > 0) {
                        totalAverageRating += parseFloat(averageRating);
                        propertiesWithReviewsCount++;
                    }

                    totalReviews += propertyReviews.length;

                    return {
                        ...property,
                        averageRating: parseFloat(averageRating),
                        totalReviews: propertyReviews.length,
                        reviews: propertyReviews
                    };
                });

                const overallAverageRating = propertiesWithReviewsCount > 0 ? (totalAverageRating / propertiesWithReviewsCount).toFixed(1) : 0;

                return {
                    totalReviews,
                    overallAverageRating: parseFloat(overallAverageRating),
                    userProperties: propertyData,
                    userReviews // Include all user reviews across properties
                };
            }
        }
        return { totalReviews: 0, overallAverageRating: 0, userProperties: [], userReviews: [] };
    };

    const userData = getUserData(userDetails?._id);

    const getUserReservations = (userId) => {
        if (reservation && reservation.length > 0) {
            // Filter reservations for the specific user
            const userReservations = reservation.filter(res => res.userId._id === userId);

            // Get the current date
            const now = new Date();

            // Filter current reservations where check-in has started and checkout has not yet passed
            const currentReservations = userReservations.filter(res => {
                const checkInDate = new Date(res.offerState.checkIn);
                const checkOutDate = new Date(res.offerState.checkOut);

                return checkInDate <= now && checkOutDate > now;
            });

            // Sort current reservations by check-in date in descending order to get the latest one
            const sortedCurrentReservations = currentReservations.sort((a, b) => new Date(b.offerState.checkIn) - new Date(a.offerState.checkIn));

            // Get the latest current reservation or null if none
            const latestCurrentReservation = sortedCurrentReservations.length > 0 ? sortedCurrentReservations[0] : null;

            return {
                allReservations: userReservations,
                latestCurrentReservation: latestCurrentReservation
            };
        }
        return { allReservations: [], latestCurrentReservation: null }; // Return empty arrays if no reservations are found
    };

    const { allReservations, latestCurrentReservation } = getUserReservations(userDetails?._id);


    const formatedDate = (timeStamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(timeStamp);
        return date.toLocaleDateString(undefined, options);
    };

    const formatCheckinDateTime = (checkIn) => {
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        const date = new Date(checkIn);

        const formattedDate = date.toLocaleDateString(undefined, dateOptions);
        const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

        return { formattedDate, formattedTime };
    };

    const formatCheckoutDateTime = (checkOut) => {
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        const date = new Date(checkOut);

        const formattedDate = date.toLocaleDateString(undefined, dateOptions);
        const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

        return { formattedDate, formattedTime };
    };


    const displayText = isExpanded ? userDetails?.about : userDetails?.about?.slice(0, maxCharacters) + (userDetails?.about?.length > maxCharacters ? "..." : "");

    //slider setting and responsivness
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2.5,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-52">
                <span className="text-gray-500 text-lg">Loading...</span>
            </div>
        );
    }

    return (

        <div className="bg-[#f8f7fa]">
            <button
                className="flex justify-center items-center text-blue-500 font-medium mb-4 "
                onClick={() => setSteps("UserManagement")}
            >
                <IoIosArrowBack /> Back
            </button>
            <span>
                <h1 className='font-bold text-[24px] p-5'>User Details</h1>
            </span>
            <section className="">
                <Container maxWidth="xl">
                    <section className="flex md:flex-row flex-col gap-5 bg-white">
                        <div className="flex flex-col md:w-[70%] w-full gap-5">
                            <div className="flex flex-col rounded gap-5 w-[100%] border border-[#C1E1C2] px-5">
                                <span>
                                    <h1 className="text-[24px] font-bold pt-4">About {userDetails?.fname + " " + userDetails?.lname}</h1>
                                </span>
                                <div className="flex flex-wrap gap-5 justify-start items-center">
                                    <div className="flex gap-2 w-full md:w-auto">
                                        <IoIosStar className="text-[#58C0D0] text-xl" />
                                        <h1>{userData.totalReviews} Reviews</h1>
                                    </div>
                                    <div className="flex gap-2 w-full md:w-auto">
                                        {userDetails?.verify === "yes" ? (
                                            <img src={require("assets/img/verfied.png")} alt="Verified" />
                                        ) : (
                                            <GiCancel className="text-[#58C0D0] text-xl" />
                                        )}
                                        <p className="text-[18px] leading-6 font-normal text-[#000000]">
                                            {userDetails?.verify === "yes" ? "Identity Verified" : "Identity Unverified"}
                                        </p>
                                    </div>
                                    <div className="flex gap-2 w-full md:w-auto">
                                        <IoIosStar className="text-[#58C0D0] text-xl" />
                                        <h1>{userData.overallAverageRating} Rating</h1>
                                    </div>
                                </div>

                                <div>
                                    {userDetails?.about ? (
                                        <>
                                            <span>
                                                <p className="text-[16px] lg:w-full pr-5 flex flex-wrap w-full text-[#4B4B4B]">
                                                    {displayText}
                                                </p>
                                            </span>
                                            {userDetails?.about?.length > maxCharacters && (
                                                <span>
                                                    <button
                                                        className="underline text-[16px] text-blue-500"
                                                        onClick={handleToggle}
                                                    >
                                                        {isExpanded ? "Read Less" : "Read More"}
                                                    </button>
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <p className="text-[16px] text-gray-500">No information available.</p>
                                    )}
                                </div>

                                <div>
                                    <Divider className="text-[#C1E1C2]" />
                                </div>

                                <section>
                                    <Container className="w-[90%]">
                                        <div className="justify-between md:flex my-4">
                                            <div>
                                                <div>
                                                    <h4 className="text-[#000000] text-2xl font-normal">
                                                        Reviews on {userDetails?.fname} properties
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>

                                        {userData?.userReviews && userData?.userReviews.length > 0 ? (
                                            <>
                                                <Slider ref={(c) => setSlider(c)} {...settings}>
                                                    {userData?.userReviews.map((item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="px-2 py-4 w-[352px] h-[300px] mt-3"
                                                                style={{ marginRight: '16px' }} // Adds spacing between slides
                                                            >
                                                                <div className="w-full h-full p-6 rounded-xl bg-gray-100 shadow-lg flex flex-col justify-start">
                                                                    <div>
                                                                        <div className="flex items-center gap-4">
                                                                            <div className="w-12 h-12">
                                                                                <img
                                                                                    src={item?.user?.pic}
                                                                                    alt="User Pic"
                                                                                    className="w-full h-full rounded-full object-cover"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <h4 className="text-lg text-black font-semibold">
                                                                                    {item?.user?.fname + " " + item?.user?.lname}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-4">
                                                                            <Rating
                                                                                initialRating={item.rating}
                                                                                readonly
                                                                                emptySymbol={<FaRegStar color="#d3d3d3" />}
                                                                                halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                                                                fullSymbol={<FaStar color="#ffd700" />}
                                                                                fractions={2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2 xl:mt-4">
                                                                        <p className="text-sm xl:text-base text-gray-700 font-normal">
                                                                            {item.comment?.length > 100
                                                                                ? `${item?.comment.substring(0, 100)}...`
                                                                                : item?.comment}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Slider>


                                                <div className="flex items-center justify-end gap-2 cursor-pointer py-2">
                                                    <div
                                                        onClick={() => slider && slider.slickPrev()}
                                                        className="w-[36.92px] flex items-center justify-center h-[36.92px] border-2 border-[#AFB5C1] rounded-full text-[#AFB5C1]"
                                                    >
                                                        <i class="fa-solid fa-angle-left text-xl"></i>
                                                    </div>
                                                    <div
                                                        onClick={() => slider && slider.slickNext()}
                                                        className="w-[36.92px] flex items-center justify-center h-[36.92px] border-2 border-[#AFB5C1] rounded-full text-[#AFB5C1]"
                                                    >
                                                        <i class="fa-solid fa-angle-right text-xl"></i>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex justify-center items-center h-52">
                                                <span className="text-[#4B4B4B] text-lg">No Reviews Yet</span>
                                            </div>
                                        )}
                                    </Container>
                                </section>
                            </div>

                            <div className="rounded-xl gap-5 w-[100%] overflow-auto border border-[#C1E1C2] mb-2">
                                <div className="p-4"><span className="text-lg font-medium">{userDetails?.fname}'s Properties</span></div>

                                {userData.userProperties.length === 0 ? (
                                    <span className="font-bold text-[#4B4B4B] text-xl flex items-center justify-center pb-5">No Listed Properties.</span>
                                ) : (
                                    <table className="table-auto border-collapse w-full bg-white">
                                        <thead>
                                            <tr className='bg-[#fafbff]'>
                                                <th className="px-4 py-2 border-t border-b text-left">
                                                    Properties
                                                </th>
                                                <th className="px-4 py-2 border-t text-left border-b">Location</th>
                                                <th className="px-4 py-2 border-t text-left border-b">price</th>
                                                <th className="px-4 py-2 border-t  text-left border-b">Listing Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {userData.userProperties.reverse().map((item, index) => {

                                                const minPoint = Array.isArray(item?.points) && item.points?.length > 0
                                                    ? item.points.reduce((min, p) => (p.point < min ? p.point : min), item.points[0].point)
                                                    : null;

                                                return (
                                                    <tr key={index}>
                                                        <td className="w-72 px-4 py-2 border-t border-b flex flex-col items-start">
                                                            <div className="flex items-center">
                                                                <div className=' w-24 h-20  flex-shrink-0'>
                                                                    <img src={item.pics[0]} className="w-full h-full rounded-md object-cover" />
                                                                </div>
                                                                <div className='flex flex-col ml-2'>
                                                                    <span className="font-medium text-sm leading-5 text-black">{item.title}</span>
                                                                    {item?.averageRating === 0 ? (
                                                                        <span className="text-[#4B4B4B] text-sm"> Not Rated Yet</span>
                                                                    ) : (
                                                                        <div className="flex items-center">
                                                                            <Rating
                                                                                initialRating={item?.averageRating}
                                                                                readonly
                                                                                emptySymbol={<FaRegStar color="#d3d3d3" />}
                                                                                halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                                                                fullSymbol={<FaStar color="#ffd700" />}
                                                                                fractions={2}
                                                                            />
                                                                            <span>({item?.averageRating})</span>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            </div>

                                                        </td>


                                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                                                            {item.address}
                                                        </td>

                                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                                            {minPoint}
                                                        </td>

                                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                                            <div className="flex flex-col gap-3">
                                                                <div
                                                                    className={`w-[56px] h-[30px] rounded-full flex justify-center items-center ${item.status === "Active"
                                                                        ? "bg-green-500 bg-opacity-10"
                                                                        : item.status === "Rejected"
                                                                            ? "bg-red-500 bg-opacity-10"
                                                                            : "bg-yellow-500 bg-opacity-10"
                                                                        }`}
                                                                >
                                                                    <button
                                                                        className={`font-extrabold text-xs leading-4 text-center ${item.status === "Active"
                                                                            ? "text-green-500"
                                                                            : item.status === "Rejected"
                                                                                ? "text-red-500"
                                                                                : "text-yellow-500"
                                                                            }`}
                                                                    >
                                                                        {item.status}
                                                                    </button>
                                                                </div>

                                                                <div>
                                                                    <span className="text-sm text-[#AFB5C1] ">{formatedDate(item.timeStamp)}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                )}
                            </div>

                            {/* guest Detail section */}
                            <div className='l flex flex-col w-[100%] gap-4 pt-2 '>
                                <div className='flex flex-col  p-4  space-y-2 border border-[#C1E1C2] rounded-xl'>
                                    <span className='text-lg font-medium'>Current Booking</span>
                                    <div className="w-[95%]">
                                        {latestCurrentReservation === null ? (
                                            <span className="font-bold text-xl text-[#4B4B4B] flex items-center justify-center pt-5">No Current Reservation</span>
                                        ) : (
                                            <Slider {...settings}>
                                                {latestCurrentReservation?.property_id?.pics.map((pic, index) => (
                                                    <div key={index} className="w-44 h-40 px-1">
                                                        <img src={pic} alt={`Property Image ${index + 1}`} className="w-full h-full object-cover rounded-xl" />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                    <p className="md:text-xl text-lg font-medium py-5">{latestCurrentReservation?.property_id?.title}</p>
                                </div>


                                <div className="flex flex-col border border-[#C1E1C2] rounded-xl ">
                                    <div className="p-4"><span className="text-lg font-medium">{userDetails?.fname}'s Past Trips</span></div>
                                    {allReservations.length === 0 ? (
                                        <span className="font-bold text-xl text-[#4B4B4B] flex items-center justify-center pb-5">No Past Trips</span>
                                    ) : (
                                        <div className="overflow-x-auto">
                                            <table className="table-auto border-collapse w-full bg-white rounded-xl">
                                                <thead>
                                                    <tr className='bg-[#fafbff]'>
                                                        <th className="px-4 py-2 border-t border-b text-left">
                                                            Properties
                                                        </th>
                                                        <th className="px-4 py-2 border-t text-left border-b">Check-In</th>
                                                        <th className="px-4 py-2 border-t text-left border-b">Check-Out</th>
                                                        <th className="px-4 py-2 border-t text-left border-b">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allReservations?.map((data, index) => {

                                                        console.log("reservationitem......", data)

                                                        const { formattedDate: checkInDate, formattedTime: checkInTime } = formatCheckinDateTime(data?.offerState?.checkIn);
                                                        const { formattedDate: checkOutDate, formattedTime: checkOutTime } = formatCheckoutDateTime(data?.offerState?.checkOut);

                                                        const calculateAverageRating = (reviews) => {
                                                            if (!reviews || reviews.length === 0) {
                                                                return null;
                                                            }
                                                            const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
                                                            const averageRating = totalRating / reviews.length;
                                                            return parseFloat(averageRating.toFixed(1)); // Round to one decimal place
                                                        };

                                                        return (
                                                            <tr key={index}>
                                                                <td className="w-80 md:w-auto px-4 py-2 border-t border-b flex flex-col items-start">
                                                                    <div className="flex items-center">
                                                                        <div className=' w-24 h-20  flex-shrink-0'>
                                                                            <img src={data?.property_id?.pics[0]} className="w-full h-full rounded-md object-cover" />
                                                                        </div>
                                                                        <div className='flex flex-col  ml-3'>
                                                                            <span className="font-medium text-sm leading-5 text-black">{data?.property_id?.title}</span>
                                                                            {calculateAverageRating(data?.property_id?.reviews) === null ? (
                                                                                <span className="text-[#4B4B4B] text-sm"> Not Rated Yet</span>
                                                                            ) : (
                                                                                <div className="flex items-center">
                                                                                    <Rating
                                                                                        initialRating={calculateAverageRating(data?.property_id?.reviews)}
                                                                                        readonly
                                                                                        emptySymbol={<FaRegStar color="#d3d3d3" />}
                                                                                        halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                                                                        fullSymbol={<FaStar color="#ffd700" />}
                                                                                        fractions={2}
                                                                                    />
                                                                                    <span>({calculateAverageRating(data?.property_id?.reviews)})</span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 ">
                                                                    {checkInDate}<br />
                                                                    <span className="text-sm font-normal text-[#AFB5C1]">{checkInTime}</span>
                                                                </td>
                                                                <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                                                    {checkOutDate}<br />
                                                                    <span className="text-sm font-normal text-[#AFB5C1]">{checkOutTime}</span>
                                                                </td>
                                                                <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                                                    {data?.offerState?.points}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="md:w-[30%] w-full">

                            <div className="w-full h-[82px] border border-green-200 rounded-2xl mb-3 p-5 bg-white">
                                <div>
                                    <p className="font-normal text-sm leading-4 text-gray-700">Total Points</p>
                                </div>
                                <div className="justify-between flex">
                                    <div>
                                        <h2 className="font-bold text-2xl leading-7">{userDetails?.points}</h2>
                                    </div>
                                    <div className="flex gap-3">
                                        <FaArrowTrendUp className="text-green-500" />
                                        <div>
                                            <p className="font-normal text-xs leading-4 text-green-500">12.00%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col bg-white   border border-[#C1E1C2] p-6 rounded-xl'>
                                <div className='flex flex-col items-center justify-center'>
                                    <img
                                        src={userDetails?.pic}
                                        alt=""
                                        className="w-24 h-24 rounded-full object-cover"
                                    />
                                </div>
                                <div className='flex flex-col space-y-2 items-center justify-center pt-6 border-b border-b-gray-200 p-4'>
                                    <p className='text-2xl font-medium text-center'> {userDetails?.fname + " " + userDetails?.lname}</p>
                                    <p className='text-base font-normal text-[#929293]'>{userDetails?.roles?.join(' | ')}</p>
                                    <div className='flex items-center'>
                                        <FaLocationDot className="text-[#929293]" />
                                        <span className='pl-2'> {userDetails?.address}</span>
                                    </div>
                                </div>

                                <div className='flex flex-col items-center justify-center'>

                                    <div className="px-3 py-5 flex space-x-1">
                                        {userDetails?.verify == "yes" ? (
                                            <img src={require("assets/img/verfied.png")} />
                                        ) : (
                                            <GiCancel className="text-[#58C0D0] text-xl" />
                                        )}
                                        <p className="text-base font-normal text-[#929293]">
                                            {userDetails?.verify == "yes"
                                                ? "Identity Verfied"
                                                : "Identity Unverfied"}
                                        </p>
                                    </div>
                                    {/* <div className=' flex space-x-1'>
                                        <RiUserFill className="text-[#58C0D0] text-xl" />
                                        <span className='text-base font-normal text-[#929293]'>Superhost</span>
                                    </div> */}

                                    <div>
                                        <button className='bg-transparent text-[#E8AD21] border-[#E8AD21] p-2 my-5 xl:px-20 lg:px-14 px-10 rounded-full border-2 text-base font-bold'>CONTACT</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </Container>
            </section>
        </div >
    )
}

export default HostDetail
