import React, { useEffect, useState } from 'react';
import ReservationManagement from './ReservationManagement'
import { localStorageData } from 'services/auth/localStorageData'
import userServices from 'services/httpService/userAuth/userServices';
import AdminReservationDetail from '../ReservationDetails/AdminReservationDetail'

const Reservation = () => {

  const [reservation, setReservation] = useState([]);
  const [ReservationDetail, setReservationDetail] = useState([]);
  const [steps, setSteps] = useState('ReservationManagement');

  // Fetches reservation data for the admin role and updates the state
  const getReservation = async () => {
    let res = await userServices.commonGetService(
      `/reservation/reservations/${localStorageData("_id")}?role=admin`
    );
    setReservation(res.data);
  };

  useEffect(() => {
    getReservation();
  }, []);

  // Finds and sets detailed reservation information
  const handleSetStepsAndDetail = (_id) => {
    console.log('I am ID Admin', _id)
    const detail = reservation.find(reservation => reservation._id === _id);
    // setSteps('ReservationDetail')
    setSteps('ReservationDetail');
    setReservationDetail(detail);
  };

  return (
    <>
      {steps === "ReservationManagement" &&
        <ReservationManagement setStepsAndDetail={handleSetStepsAndDetail} reservation={reservation} />
      }
      {steps === "ReservationDetail" &&
        <AdminReservationDetail ReservationDetail={ReservationDetail} setSteps={setSteps} onBack={() => setSteps("ReservationManagement")} />
      }
    </>
  )
}

export default Reservation