import React, { useState } from 'react';
import { Dashboard } from './Dashboard';
import { Inbox } from './Inbox';
import { Calendarcomponent } from './Calender';
import { Insights } from './Insights';
import { Menu } from './Menu';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import OutsideClickHandler from 'react-outside-click-handler';

export const Host = () => {
    const [viewChat, setViewChat] = useState(false);
    const [showInsightsDropdown, setShowInsightsDropdown] = useState(false);
    const [selectButton, setSelectButton] = useState(true)
    const [selectedTab, setSelectedTab] = useState('dashboard');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    const closeOptions = () => {
        setShowInsightsDropdown(false);
    };

    return (
        <div>
            {
                !viewChat ? (
                    <div className="md:my-10 "
                    >
                        <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
                            <ul className="flex flex-wrap -mb-px cursor-pointer text-[#AFB5C1]">
                                <li className="ml-3 me-2 sm:ml-6 md:ml-12 lg:ml-28">
                                    <p
                                        onClick={() => handleTabClick('dashboard')}
                                        className={`inline-block p-4 border-b-2 ${selectedTab === 'dashboard'
                                            ? 'border-yellow-500 rounded-t-lg text-black'
                                            : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                            }`}
                                    >
                                        Dashboard
                                    </p>
                                </li>
                                <li className="me-2">
                                    <p
                                        onClick={() => { handleTabClick('inbox'); setViewChat(true) }}
                                        className={`inline-block p-4 border-b-2 ${selectedTab === 'inbox'
                                            ? 'border-yellow-500 rounded-t-lg text-black'
                                            : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                            }`}
                                    >
                                        Inbox
                                    </p>
                                </li>
                                <li className="me-2">
                                    <p
                                        onClick={() => handleTabClick('calendar')}
                                        className={`inline-block p-4 border-b-2 ${selectedTab === 'calendar'
                                            ? 'border-yellow-500 rounded-t-lg text-black'
                                            : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                            }`}
                                    >
                                        Calendar
                                    </p>
                                </li>
                                <li className="relative me-2">
                                    <p
                                        onClick={() => handleTabClick('insights')}
                                        className={`p-4 border-b-2 flex justify-center items-center ${selectedTab === 'insights'
                                            ? 'border-yellow-500 rounded-t-lg text-black'
                                            : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                            }`}
                                    >
                                        <span>Insights</span> {showInsightsDropdown ?
                                            <IoIosArrowUp onClick={() => setShowInsightsDropdown(false)} />
                                            : <IoIosArrowDown onClick={() => setShowInsightsDropdown(true)} />}

                                    </p>
                                    {
                                        showInsightsDropdown ? (
                                            <OutsideClickHandler onOutsideClick={() => closeOptions()}>
                                                <div className="absolute -bottom-25 shadow-xl !z-30 bg-white border  flex flex-col text-start w-[125px] rounded-b-lg" style={{ zIndex: "50px" }}>
                                                    <span onClick={() => {
                                                        setSelectButton(true);
                                                        closeOptions();
                                                    }

                                                    } className={`py-3 px-5 text-base  font-normal ${selectButton ? 'text-color-primary bg-color-lightred' : 'text-black'}`}>
                                                        Earnings
                                                    </span>
                                                    <span onClick={() => {
                                                        setSelectButton(false);
                                                        closeOptions();
                                                    }} className={`py-3 px-5  text-base font-normal ${!selectButton ? 'text-color-primary bg-color-lightred' : 'text-black'}`}>
                                                        Reviews
                                                    </span>
                                                </div>
                                            </OutsideClickHandler>
                                        ) : null
                                    }

                                </li>
                                <li className="me-2">
                                    <p
                                        onClick={() => handleTabClick('menu')}
                                        className={`inline-block p-4 border-b-2 ${selectedTab === 'menu'
                                            ? 'border-yellow-500 rounded-t-lg text-black'
                                            : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                            }`}
                                    >
                                        Menu
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                ) : null
            }
            {/* Render content based on the selected tab */}
            <div className={!viewChat && "mx-3 sm:mx-6 md:mx-12 lg:mx-28"}>
                {selectedTab === 'dashboard' && <Dashboard />}
                {selectedTab === 'inbox' && <Inbox viewChat={viewChat} setViewChat={setViewChat} setSelectedTab={setSelectedTab} />}
                {selectedTab === 'calendar' && <Calendarcomponent />}
                {selectedTab === 'insights' && <Insights selectButton={selectButton} />}
                {selectedTab === 'menu' && <Menu />}
            </div>
        </div>
    );
}
