import React, { useEffect, useState } from 'react';
import { FaDesktop, FaMobileAlt, FaTabletAlt } from 'react-icons/fa';
import userServices from 'services/httpService/userAuth/userServices';
import { localStorageData } from 'services/auth/localStorageData';

export const DeviceHistory = () => {
    const [deviceInfo, setDeviceInfo] = useState([]);

    // Fetches user Devices info
    const getDeviceInfo = async () => {
        try {
            let res = await userServices.getUserDeviceInfo(`/userAuth/users/${localStorageData('_id')}/devices`);
            setDeviceInfo(res.data?.devices || []); 
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getDeviceInfo();
    }, []);

    console.log('deviceInfo@@@@@@@', deviceInfo);

    // Function to get the correct icon based on device type
    const getDeviceIcon = (deviceType) => {
        switch (deviceType?.toLowerCase()) {
            case 'pc':
            case 'laptop':
                return <FaDesktop className="text-3xl text-gray-600" />;
            case 'mobile':
                return <FaMobileAlt className="text-3xl text-gray-600" />;
            case 'tablet':
            case 'ipad':
                return <FaTabletAlt className="text-3xl text-gray-600" />;
            default:
                return <FaDesktop className="text-3xl text-gray-600" />; 
        }
    };

    return (
        <div className="pt-12 pb-48">
            {deviceInfo.map((device, index) => (
                <div key={index} className="flex justify-between items-center md:w-1/2 mb-4">
                    <div className="flex items-center gap-5 justify-between w-[43%]">
                        <div>
                            {getDeviceIcon(device.deviceType)}
                        </div>
                        <div className="flex flex-col">
                            <p className="text-lg font-medium">{device.deviceType || 'Unknown Device'}</p>
                            <p className="text-base font-normal text-[#4B4B4B]">
                                {new Date(device.loginTimestamp).toLocaleString()}
                            </p>
                        </div>
                    </div>
                    <p className="text-base font-normal underline w-[30%] cursor-pointer">
                        Log out device
                    </p>
                </div>
            ))}
        </div>
    );
};
