import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/App.css';
import 'assets/fonts/Poppins-Regular.ttf';
import 'react-multi-carousel/lib/styles.css';
import 'react-slideshow-image/dist/styles.css'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { composeWithDevTools } from 'redux-devtools-extension';
import Root from 'routes';
import { master } from 'redux/store/reducers/combineReducer';
import { ToastContainer } from 'react-toastify';
import LanguageContextProvider from 'common/contexts/LanguageContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const PUBLIC_KEY = "pk_live_51MW0sqA2UHtqGNuRYVtB7q5WNzC6ABYK63roddSIb4X7bAzh2Ha2HR8tx7vzxcThmdwLFUcsrW5wPSNXh5CEXToJ00YEpUQXiE";

const stripePromise = loadStripe(PUBLIC_KEY);

const queryClient = new QueryClient();
const store = createStore(master, composeWithDevTools(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LanguageContextProvider>
        <Elements stripe={stripePromise}>
          <Root />
        </Elements>
      </LanguageContextProvider>
      <ToastContainer limit={1} />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
