import React from "react";
import Slider from "react-slick";
import img1 from "assets/img/guestd1.png";
import img3 from "assets/img/3group.png"
import img6 from "assets/img/3group.png"
import img8 from "assets/img/Subtract.png"
import img9 from "assets/img/gcheck.png"
import Rating from "@mui/material/Rating";
import { IoIosArrowBack } from 'react-icons/io'
import { Container } from "@mui/material";

export default function InfluencerDetails({ setSteps, user, influencerDetails, }) {
  const dataArray = [
    {
      id: 1234,
      img1: img1,
      checkin: "Feb 24 2023 ",
      checkout: "Feb 24 2023 ",
    },
    {
      id: 1234,
      img1: img1,
      checkin: "Feb 24 2023",
      checkout: "Feb 24 2023 ",
    },

  ];

  //slider setting and responsivness
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <Container maxWidth='xl '>
        <button
          className="flex justify-center items-center text-blue-500 font-medium mb-4 "
          onClick={() => setSteps("UserManagement")}
        >
          <IoIosArrowBack /> Back
        </button>
        <div>
          <h1 className='text-2xl font-extrabold'>Influencer Details</h1>
        </div>
        <div className="w-full flex md:flex-row flex-col gap-3">
          {/* <div className="w-[70%]"> */}
          <div className='l flex flex-col md:w-[75%] w-full gap-4 pt-2 '>
            <div className='flex flex-col  p-4  space-y-2 border border-[#C1E1C2] rounded-xl'>
              <span className='text-lg font-medium'>Current Booking</span>
              <div className="w-[95%]">
                <Slider {...settings}>
                  <div className="">
                    <img src={img1} alt="" />
                  </div>
                  <div className="">
                    <img src={img1} alt="" />
                  </div>
                  <div className="">
                    <img src={img1} alt="" />
                  </div>
                  <div className="">
                    <img src={img1} alt="" />
                  </div>
                  {/* <div className="">
                    <img src={img1} alt="" />
                  </div> */}
                </Slider>
              </div>
              <p className="md:text-xl text-lg font-medium">Harbourview, Dartmouth, Nova Scotia, Canada</p>
              <p className="text-sm font-normal text-[#4B4B4B]">Meet Antonio, your warm and welcoming host with a passion for creating... <a href="" className="underline text-black">Read More</a></p>

            </div>


            <div className="flex flex-col border border-[#C1E1C2] ">
              <div className="p-4"><span className="text-lg font-medium">Your Past Trips</span></div>
              <div className="overflow-x-auto">
                <table className="table-auto border-collapse w-full bg-white rounded-xl">
                  <thead>
                    <tr className='bg-[#fafbff]'>
                      <th className="px-4 py-2 border-t border-b text-left">
                        Properties
                      </th>
                      <th className="px-4 py-2 border-t text-left border-b">Check-In</th>
                      <th className="px-4 py-2 border-t text-left border-b">Check-Out</th>
                      <th className="px-4 py-2 border-t text-left border-b">Price</th>
                      <th className="px-4 py-2 border-t text-left border-b"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray.map((data, index) => (
                      <tr key={index}>
                        <td className="w-80 md:w-auto px-4 py-2 border-t border-b flex flex-col items-start">
                          <div className="flex items-center flex-row">
                            <img src={data.img1} className="w-24 h-20 rounded-md mr-2" />
                            <div className='flex flex-col'>
                              <span className="font-medium text-xs leading-4 text-gray-400">#{data.id}</span>
                              <span className="font-medium text-sm leading-5 text-black">Mahone Bay, Nova Scotia, Canada{index + 1}</span>
                              <div className="flex md:flex-row flex-col">
                                <Rating
                                  initialRating={5}
                                  readonly
                                  emptySymbol={<i className="far fa-star text-yellow-500 md:text-base text-[12px]"></i>}
                                  fullSymbol={<i className="fas fa-star text-yellow-500 md:text-base text-xs"></i>}
                                  fractions={2}
                                  onChange={newRating => console.log('New rating:', newRating)}
                                />
                                <span className="text-sm ml-2">(23)</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 ">
                          {data.checkin}<br />
                          <span className="text-sm font-normal text-[#AFB5C1]">03:00 AM</span>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          {data.checkout}<br />
                          <span className="text-sm font-normal text-[#AFB5C1]">03:00 AM</span>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          $244
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black"><img src={img3} alt="" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


            </div>
          </div>
          {/* </div> */}
          <div className="flex flex-col md:w-[25%] w-full">
            <div className='flex flex-col bg-white   border border-green-200 sm:p-4 p-2 rounded-xl'>

              <div className='flex justify-end'>
                <img src={img6} />
              </div>

              <div className='flex flex-col items-center justify-center '>
                <img src={influencerDetails?.pic} className='w-20' />
              </div>

              <div className='flex flex-col sm:space-y-2 space-y-1 items-center justify-center sm:pt-6 pt-4  border-b border-b-gray-200 sm:p-4 p-2'>
                <p className='sm:text-2xl text-xl font-medium'>{influencerDetails?.fname} {influencerDetails?.lname}</p>
                <p className='text-base font-normal text-[#929293]'>{influencerDetails?.loginAs}</p>
                <div className='flex items-center'>
                  <img src={img8} className='w-4 h-4' />
                  <span className='pl-2 sm:text-base text-sm'>{influencerDetails?.address}</span>
                </div>
              </div>

              <div className='flex flex-col items-center justify-center'>
                <div className=' sm:p-6 p-2 flex space-x-1'>
                  <img src={img9} />
                  <span className='lg:text-base text-sm font-normal text-[#929293]'>{influencerDetails?.email}</span>
                </div>

                <div>
                <button className='bg-transparent text-[#E8AD21] border-[#E8AD21] p-2 my-5 xl:px-20 lg:px-14 px-10 rounded-full border-2 text-base font-bold'>CONTACT</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Container>




    </>
  );
}
