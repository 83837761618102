import React from 'react'
import { FaArrowTrendUp } from 'react-icons/fa6'
import australiaFlag from '../../../assets/img/australiaFlag.png'
import percentage from '../../../assets/img/percentage.png'
import PiGraph from '../../../components/Graphs/PiGraph';
import { BsThreeDotsVertical } from "react-icons/bs";
import searchIcon from '../../../assets/img/searchIcon.png'
import dp from '../../../assets/img/dp.png'
import { VscSettings } from "react-icons/vsc";
import { MdOutlineFileDownload } from "react-icons/md";
import Dropdown from '../UserManagement/Dropdown';
import TopCard from '../UserManagement/TopCard';
import { Container } from "@mui/material";
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"

const SupportTicketing = () => {

    const cardsData = [
        { title: 'Total Tickets', count: 546, percentage: '12.00%' },
        { title: 'Today Tickets', count: 665, percentage: '12.00%' },
        { title: 'Response Tickets', count: 98, percentage: '12.00%' },
        { title: 'Pending Tickets', count: 652, percentage: '12.00%' }
    ];

    return (
        <section className="bg-[#f8f7fa]">
            <Container maxWidth="xl">
                <div className='flex md:flex-row flex-col justify-between items-center   py-3 '>
                    <div>
                        <span>
                            <h1 className='font-bold text-[24px]'>Supports / Ticketing</h1>
                        </span>
                    </div>
                    <div className='flex gap-2'>

                        <span>
                            <button className='bg-blue-700 rounded-[50px] p-[12px] W-[170px] text-white'>+ CREATE TICKET</button>
                        </span>
                    </div>
                </div>

                <div className="flex flex-wrap gap-2 mt-4 w-full justify-between">
                    {cardsData.map((card, index) => (
                        <div key={index} className="w-[24%] h-[82px] border border-green-200 rounded-2xl p-5 bg-white">
                            <div>
                                <p className="font-normal text-lg leading-3 text-gray-700 pb-3">{card.title}</p>
                            </div>
                            <div className="justify-between flex">
                                <div>
                                    <h2 className="font-semibold text-2xl leading-7">{card.count}</h2>
                                </div>
                                <div className="flex gap-3">
                                    <FaArrowTrendUp className="text-green-500" />
                                    <div>
                                        <p className="font-normal text-xs leading-4 text-green-500">{card.percentage}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 my-5'>

                    <div className='flex flex-col w-full border-2 border-[#DFF7E9] rounded-xl '>
                        <div className='flex items-center justify-between p-2'>
                            <div>
                                <h1 className='font-bold'>Tickets by Countries</h1>
                            </div>
                            <div className='flex items-center gap-4'>
                                <Dropdown />
                                <BsThreeDotsVertical />
                            </div>
                        </div>
                        <hr />
                        <div>
                            {[1, 2, 3, 4].map((data, index) => (
                                <div className='flex justify-between p-3 border-b'>
                                    <div className='flex items-center gap-3'>
                                        <div>
                                            <img src={australiaFlag}></img>
                                        </div>
                                        <div className='flex flex-col'>
                                            <h1 className='font-bold'>25</h1>
                                            <p className='text-gray-400'>Australia</p>
                                        </div>

                                    </div>
                                    <div className='flex items-center justify-center gap-2'>
                                        <FaArrowTrendUp className='text-[#40CA5E]' />
                                        <h1 className='text-[#40CA5E]'>15.00%</h1>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className='border-2 border-[#DFF7E9] rounded-xl'>
                        <div className='flex justify-between items-center px-5 py-2'>
                            <div>
                                <h1 className='font-bold'>States</h1>
                            </div>
                            <div>
                                <Dropdown />
                            </div>
                        </div>
                        <hr />

                        <div>
                            <PiGraph />
                        </div>
                    </div>

                    <div className='flex flex-col border-2 border-[#DFF7E9] rounded-xl'>
                        <div className='flex justify-between items-center px-5 py-2'>
                            <div>
                                <h1 className='font-bold'>Support Tickets</h1>
                            </div>
                            <div>
                                <BsThreeDotsVertical />
                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-between items-center p-4 gap-3'>
                            <h1 className='font-bold'>Admin</h1>
                            <p className='text-gray-400 text-sm'>646</p>
                        </div>
                        <hr />
                        <div className='flex justify-between items-center p-4 gap-3'>
                            <h1 className='font-bold'>Guest</h1>
                            <p className='text-gray-400 text-sm'>646</p>
                        </div>
                        <hr />
                        <div className='flex justify-between items-center p-4 gap-3'>
                            <h1 className='font-bold'>Host</h1>
                            <p className='text-gray-400 text-sm'>646</p>
                        </div>
                        <hr />
                        <div className='flex justify-between px-5 py-7 items-center'>
                            <div className='flex items-center gap-3'>
                                <div className='flex flex-col'>
                                    <h1 className='text-gray-400'>On-time Completion Rate</h1>
                                    <p className='font-bold text-[32px]'>50%</p>
                                </div>
                            </div>
                            <div>
                                <img src={percentage}></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' mx-5 my-3 flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white  '>
                    <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
                        <div className=''>
                            <p className='font-medium sm:text-lg text-sm'>All Tickets</p>
                        </div>
                        <div className='flex  space-x-2'>
                            <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                                <div>
                                    <img src={icon3} />
                                </div>
                                <div>
                                    <p className='font-normal text-xs text-gray-600'>Fillter</p>
                                </div>
                            </div>
                            <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px] border border-gray-300 rounded-md'>
                                <div>
                                    <img src={icon5} />
                                </div>
                                <div>
                                    <p className='font-normal text-xs text-gray-600'>Export</p>
                                </div>
                            </div>

                            <div className="relative md:w-64  ">
                                <input
                                    type="text"
                                    placeholder='Search'
                                    className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                                />
                                <img
                                    src={icon4}
                                    alt="search icon"
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='overflow-auto '>
                        <table className="table-auto w-[900px]  md:w-full bg-white ">
                            <thead>
                                <tr className='bg-[#fafbff]'>
                                    <th className="px-4 py-2 border-t border-b text-left">
                                        <input type="checkbox" class="mr-2 border border-red-500" />
                                        Ticket Type
                                    </th>
                                    <th className="px-4 py-2 text-left border-t border-b">Customer</th>
                                    <th className="px-4 py-2 border-t text-left border-b">Ticket Date</th>
                                    <th className="px-4 py-2 border-t  text-left border-b">Daily Report</th>
                                    <th className="px-4 py-2 border-t text-left border-b"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {[1, 2, 3, 4, 5].map((_, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className="flex items-center">
                                                <input type="checkbox" className="mr-2" />
                                                <img src={australiaFlag} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                                                <span className="font-bold text-sm leading-5 text-black">Refund Request</span>
                                            </div>

                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className="flex items-center">
                                                <img src={dp} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                                                <div className='flex flex-col'>
                                                    <span className="font-bold text-sm leading-5 text-black">Roger Press{index + 1}</span>
                                                    <span className="font-medium text-xs leading-4 text-gray-400">example@gmail.com</span>

                                                </div>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>Feb 29, 2024</span>
                                                <span className="font-medium text-xs leading-4 text-gray-400">03:00 AM</span>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>Refund Request for Booking ID #51856</span>
                                                <span className='text-blue-500'>View Details</span>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <BsThreeDotsVertical />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>



                </div>
            </Container>
        </section>
    )
}

export default SupportTicketing
