import { Link } from "react-router-dom";
import info from "../../assets/img/info.png";
import logo3 from "../../assets/img/logo3.png";
import BnbNav from "components/NavBar/BnbNav";
import BnbNavMob from "components/NavBar/BnbNavMob";

const PartnerResource = () => {
  return (
    <>
    <BnbNav />
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative md:h-[400px] h-[200px] w-full mb-6">
        <img
          src={info}
          alt="Beach scene with hat and sunglasses"
          className="object-cover w-full h-full"
        />
        <Link to="/">
          <img
            src={logo3}
            alt="Logo"
            className="absolute bottom-8 md:left-20 left-10"
          />
        </Link>
      </div>

      {/* Content Section */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6">
          Important Resources
        </h1>
        <p className="text-gray-600 mb-8">
          Want the perfect vacation? That's what we want for you. To make sure
          that's exactly what's in front of you, we've taken the liberty of
          providing a few links to what we think are pretty good resources to
          check out before you go.
        </p>

        <div className="grid gap-4 lg:gap-x-16 md:grid-cols-2 lg:grid-cols-2">
          {/* Travel Warnings Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Travel Warnings and Foreign Entry Requirements
            </h2>
            <ul className="space-y-2">
              <li>
                <Link
                  to="https://travel.state.gov/content/travel.html"
                  className="text-gray-400 hover:underline"
                >
                  US Dept of State Travel Advisory Site -
                  https://travel.state.gov/content/travel.html
                </Link>
              </li>
              <li>
                <Link
                  to="https://travel.gc.ca/"
                  className="text-gray-400 hover:underline"
                >
                  Canadian Ministry of Travel and Tourism -
                  https://travel.gc.ca/
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.gov.uk/foreign-travel-advice"
                  className="text-gray-400 hover:underline"
                >
                  UK Travel and Tourism -
                  https://www.gov.uk/foreign-travel-advice
                </Link>
              </li>
            </ul>
          </section>

          {/* Vaccinations Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Vaccinations for International Travel
            </h2>
            <Link
              to="https://www.who.int/travel-advice/vaccine/"
              className="text-blue-600 hover:underline"
            >
              https://www.who.int/travel-advice/vaccine/
            </Link>
          </section>

          {/* Pack right for the weather */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Pack right for the weather!
            </h2>
            <ul className="space-y-2">
              <li>
                <Link
                  to="https://www.accuweather.com/en/world-weather"
                  className="text-gray-400 hover:underline"
                >
                  https://www.accuweather.com/en/world-weather
                </Link>
              </li>
            </ul>
          </section>

          {/* Get Organized Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Get Organized!
            </h2>
            <p className="text-gray-400">
              TripIt is one of several platforms that we think are pretty neat.
              In their words: "Unlike other travel apps, TripIt can organize
              your travel plans no matter where you book." Simply forward your
              confirmation emails to plans@tripit.com and it'll automagically
              create an organized itinerary for you.
            </p>
            <Link
              to="https://www.tripit.com/web"
              className="text-blue-600 hover:underline"
            >
              Visit TripIt
            </Link>
          </section>

          {/* Insurance Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Are you Insured?
            </h2>
            <p className="text-gray-400">
              If you've never recommended a specific travel insurer but we do
              offer you experienced advice about what to look for in a policy
              and where to start. This brings us to the next part of explaining
              the importance.
            </p>
            <Link to="#" className="text-blue-600 hover:underline">
              Learn about travel insurance
            </Link>
          </section>

          {/* Plan Route Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Plan your route!
            </h2>
            <Link
              to="https://www.viamichelin.com/"
              className="text-blue-600 hover:underline"
            >
              https://www.viamichelin.com/
            </Link>
          </section>

          {/* Avoiding a visit while you're gone */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Avoiding a visit while you're gone
            </h2>
            <p className="text-gray-400">
              It seems like a given to some of us but it's amazing how many
              don't actually take a few steps to avoid a robbery while you're
              gone.
            </p>
            <p className="text-gray-400">
              Pause your newspaper or periodicals subscription.
            </p>
            <p className="text-gray-400">
              Don't post on social media that you'll be gone - fine if you have
              your posts set properly to only those friends or followers you
              trust (and perhaps you know address)
            </p>
          </section>

          {/* Download Google Maps */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Download your Google Map ahead of time!
            </h2>
            <p className="text-gray-400">
              Did you know that you can download in advance your Google Map for
              the area through which you will be travelling? This means you can
              use your phone for GPS in almost any nation or region without
              needing to rely on roaming charges. Your phone just uses satellite
              instead. You may also want to call your provider and join an
              international wireless plan.
            </p>
            <Link
              to="https://blog.google/products/maps/google-maps-offline/"
              className="text-blue-600 hover:underline"
            >
              https://blog.google/products/maps/google-maps-offline/
            </Link>
          </section>

          {/* General Tips Section */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              General Things to Consider
            </h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-400">
              <li>Check your reservations and reconfirm if necessary</li>
              <li>Have your mail held by your post office</li>
              <li>
                Pay your bills that will come due while you're gone (or set up
                auto-pay)
              </li>
              <li>
                Pay your "spending on 'hold/away' activity" and secure your
                account
              </li>
              <li>Tell your bank where you'll be traveling</li>
            </ul>
          </section>

          {/* Check Passport */}
          <section className="space-y-4">
            <h2 className="text-base md:text-xl font-semibold text-blue-400">
              Double-check your passport!
            </h2>
            <p className="text-gray-400">
              Different countries have different rules regarding their passport.
              Travelling internationally? You would be wise to quickly review
              these rules and regulations and to renew your passport and those
              of your family if advised.
            </p>
            <Link
              to="https://visaguide.world/tips/passport-6-month-rule"
              className="text-blue-600 hover:underline"
            >
              https://visaguide.world/tips/passport-6-month-rule
            </Link>
          </section>
        </div>
      </main>
    </div>
    </>
  );
};

export default PartnerResource;
