import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import logo from "../../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { useApplyFotForgetPass } from "hooks";

function ForgotPassword() {
  let navigate = useNavigate();

  //hook for handling forget password api call
  const { mutateAsync: applyFotForgetPass, isLoading } =
    useApplyFotForgetPass();

  //formik for managing form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values) => {
      const response = await applyFotForgetPass(values); // API call to submit form data
      toast.success("Password reset link, sent to your Email.");
      navigate('/');
    },
  });

  return (
    <div className="flex justify-center items-center h-screen bg-white">
      <div className="w-full max-w-md p-6 bg-white rounded-md  shadow-2xl">
        <div className="flex flex-col items justify-center mb-6">
          <img src={logo} alt="Logo" className="w-[93px] h-[49.06px] mx-auto mb-8" />
          <h2 className="text-2xl text-center font-semibold mb-4">Forgot Your Password?</h2>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-400 font-bold mb-2">
              Email Address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter your email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.email}
              </div>
            )}
          </div>

          <div className="text-center">
            {isLoading ? (
              <CircularProgress className="text-blue-500" />
            ) : (
              <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                Reset Password
              </button>
            )}
          </div>

        </form>

        {/* Decorative elements for empty space */}
        <div className="mt-8 p-4 border-t border-gray-200 text-center text-gray-500">
          <p className="text-gray-600 mt-4">
            Remembered your password? <a href="/auth/signin" className="text-blue-500 hover:underline">Sign In</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
