import { useEffect, useState } from "react";
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const HistoryCard = ({ history, userId }) => {

  let navigate = useNavigate();

  //format checkin date into readable form
  const handleCheckIn = (value) => {
    const checkInDate = new Date(value);

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedCheckInDate = checkInDate.toLocaleDateString(
      "en-US",
      options
    );
    return formattedCheckInDate;
  };

  // Checks if the review date falls within the last 10 days from the check-out date
  const checkReviewDate = (offerState) => {
    const checkOutDate = new Date(offerState.checkOut);
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the check-out date
    const timeDifference = currentDate.getTime() - checkOutDate.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);
    return dayDifference >= 0 && dayDifference <= 10;
  };

  const handleButtonClick = (propertyId, itemId) => {
    // Navigate to propertydetails page with property ID and review query parameter
    navigate(`/propertydetails/${propertyId}?reservationId=${itemId}`);
  };

  const hasUserReviewed = (reviews, userId) => {
    return reviews.some(review => review.userId === userId);
  };

  //calculate average rating and reviews
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  return (
    <>
      <div className="md:flex flex-wrap gap-2">
        {history.map((items, index) => {
          const averageRating = calculateAverageRating(items.property_id?.reviews || []);
          const userHasReviewed = hasUserReviewed(items.property_id?.reviews || [], userId);
          //finding minimum points from array of points
          const minPoint = Array.isArray(items?.property_id?.points) && items?.property_id?.points.length > 0
            ? items?.property_id?.points.reduce((min, p) => (p.point < min ? p.point : min), items?.property_id?.points[0].point)
            : null;

          return (
            <div key={items._id || index} // Use a unique identifier
              className="md:flex gap-2 md:w-[49%] my-4 border lg:h-[270px] xl:h-64 border-color-grey rounded-md overflow-hidden">
              <div className="w-full  p-2 ">
                <div>
                  {averageRating > 0 ? (
                    <div className="flex items-center ">
                      <Rating
                        initialRating={averageRating}
                        readonly
                        emptySymbol={<FaRegStar color="#d3d3d3" />}
                        halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                        fullSymbol={<FaStar color="#ffd700" />}
                        fractions={2}
                      />
                      <span>({averageRating})</span>
                    </div>
                  ) : (
                    <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                  )}
                  <p className="text-xl font-semibold md:h-28 lg:h-20 xl:h-16">
                    {items?.property_id?.title.length > 45
                      ? items?.property_id?.title.slice(0, 45) + '...'
                      : items?.property_id?.title}
                  </p>
                  <div className='text-gray-600 lg:w-52 '>
                    <span className="text-base font-normal">
                      {items.property_id &&
                        items.property_id.spaceTypeDetail &&
                        items.property_id.spaceTypeDetail.bathrooms &&
                        items.property_id.spaceTypeDetail.bedrooms &&
                        items.property_id.spaceTypeDetail.guests && (
                          <>
                            Guests:{" "}
                            {items.property_id.spaceTypeDetail.guests || 0}{" | "}
                            Bedrooms:{" "}
                            {items.property_id.spaceTypeDetail.bedrooms || 0}{" "}
                            {/* Kitchens:{" "}
                            {items.property_id.spaceTypeDetail.kitchen || 0}{" | "} */}
                            Bathrooms:{" "}
                            {items.property_id.spaceTypeDetail.bathrooms || 0}
                          </>
                        )}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">
                    {handleCheckIn(items.offerState.checkIn)}
                  </p>
                </div>
                <hr className="m-2" />
                <div>
                  {!userHasReviewed && checkReviewDate(items?.offerState) ? (
                    <div>
                      <button onClick={() => handleButtonClick(items?.property_id?._id, items._id)}
                        className="px-5 py-2 rounded-full bg-blue-800 text-white"
                      > Review</button>
                    </div>
                  ) : (
                    <div className="h-10"></div> // Empty space for alignment 
                  )}
                  <div className=" my-1 flex justify-between items-center">
                    <p className="text-sm">From</p>
                    <p>
                      <span className="text-color-grey text-xs line-through">
                        {minPoint}
                      </span>{" "}
                      <span className="text-color-red">
                        {minPoint} Points
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-96 lg:w-80 min-h-fit  relative">
                <img
                  src={items?.property_id?.pics[0]}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          );
        })
        }
      </div >
    </>
  );
};

export default HistoryCard;


