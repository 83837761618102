import React from 'react'
import { FaSchoolCircleExclamation } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom'
import failed from '../../../src/assets/img/failed.png'

const ErrorStripe = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='flex flex-col justify-center items-center min-h-screen bg-gray-100 pb-28 pt-5 px-3'>
        <div className='bg-white px-8 py-5 md:py-3 xl:py-16 rounded-lg shadow-md flex flex-col items-center w-full max-w-lg'>
          <div className='mb-6'>
            <img src={failed} className='w-40 h-40' alt='Payment Failed' />
          </div>
          <div className='text-center mb-6'>
            <span className='font-bold text-red-500 text-3xl'>OOPS...!</span>
            <span className='block font-bold text-xl mt-2'>Payment Failed</span>
            <p className='text-gray-500 text-lg mt-4'>Something went wrong!</p>
          </div>
          <button
            className='py-3 px-16 rounded-full bg-red-500 text-white text-lg shadow-lg transition-transform transform hover:scale-105'
            onClick={() => navigate('/')}
          >
            Back
          </button>
        </div>
      </div>
    </>

  )
}

export default ErrorStripe
