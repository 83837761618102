import React, { useEffect, useState, useRef } from "react";
import dp2 from "../../assets/img/profile.png";
import menuSvg from "../../assets/img/menu.svg";
import { RxCross2 } from "react-icons/rx";
import { ButtonFilled } from "common/buttons/buttonFilled";
import { IoIosArrowBack } from "react-icons/io";
import fileIcon from "../../assets/img/fileIcon.svg";
import shareIcon from "../../assets/img/shareIcon.svg";
import userServices from "services/httpService/userAuth/userServices";
import { localStorageData, getLocalUserdata } from "services/auth/localStorageData";
import Modal from "components/Modal/Modal";
import ChatProfileDetails from "./ChatProfileDetails";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useChat } from "common/contexts/chatConversationsContext";

const Chat = ({ viewButton, handleClick }) => {
  const role = "host"
  const messageRef = useRef(null);
  const userId = localStorageData("_id")
  const user = getLocalUserdata()
  const [chatUsers, setChatUsers] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedProfile1, setSelectedProfile1] = useState(null);
  const [archivedMessages, setArchivedMessages] = useState([]);
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [selectedChatData, setSelectedChatData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState();
  const [selectedChat, setSelectedChat] = useState("")

  // Extracted data from chat context or service
  const { conversationData2, loading2, setLoading2, onlineUsers2, error, socket, fetchConversations, fetchMessagesData2, messagesData2, setMessagesData2 } = useChat();
  const receiverIds = conversationData2?.length > 0 ? conversationData2.map(conversation => conversation?.user?.receiverId).filter(Boolean) : null;
  const receiverStatus = receiverIds ? receiverIds.map(receiverId => {
    const isOnline = onlineUsers2?.some(user => user?.userId === receiverId);
    return { receiverId, status: isOnline ? 'online' : 'offline' };
  }) : null;

  // Handles file input change and updates the state with selected files
  const handleFileChange = (event) => {
    const files = event.target.files; // Get the selected files
    const filesArray = Array.from(files); // Convert FileList to array
    setSelectedFiles(filesArray); // Update the state with the selected files
  };

  const removeFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1); // Remove the item at the specified index
    setSelectedFiles(newFiles);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Sending message with optional file attachments
  const sendMessage = async () => {
    try {
      setLoading2(true)
      if (!messagesData2?.conversationId) {
        console.error("ConversationId is missing.");
        return;
      }

      const fileFormData = new FormData();
      if (selectedFiles?.length > 0) {

        if (selectedFiles?.length > 5) {
          toast.error('You can only select up to 5 files.');
        }
        selectedFiles?.forEach(file => {
          fileFormData.append('fileUrl', file);
        });
      }
      const formData = new FormData();
      if (message) {
        formData.append('message', message);
      }
      formData.append('senderId', userId);
      formData.append('receiverId', messagesData2?.receiver?.receiverId);
      formData.append('conversationId', messagesData2?.conversationId);
      if (selectedFiles?.length > 0) {
        let resultPic = await userServices.sendFileApi('/chat/upload-image', fileFormData)
        if (resultPic) {
          // If there's a message, send it
          // Emit the message through socket

          if (resultPic && resultPic.data && resultPic.data.fileUrls) {
            await socket.emit('sendMessage', {
              message,
              senderId: userId,
              fileUrl: resultPic?.data?.fileUrls,
              receiverId: messagesData2?.receiver?.receiverId,
              conversationId: messagesData2?.conversationId,
            });
          }
          await userServices.sendMessageApi('/chat/message', { message, senderId: userId, receiverId: messagesData2?.receiver?.receiverId, conversationId: messagesData2?.conversationId, fileUrl: resultPic?.data?.fileUrls });
        }
      } else {
        if (message) {
          // Emit the message through socket
          socket.emit('sendMessage', {
            message,
            senderId: userId,
            receiverId: messagesData2?.receiver?.receiverId,
            conversationId: messagesData2?.conversationId,
          });
          setMessage("");
        }
        await userServices.sendMessageApi('/chat/message', { message, senderId: userId, receiverId: messagesData2?.receiver?.receiverId, conversationId: messagesData2?.conversationId });
      }

      // Clear the selected files after sending
      setMessage('')
      setSelectedFiles([]);
    } catch (err) {
      console.error("Error sending message:", err);
    } finally {
      setLoading2(false)
      messageRef?.current?.scrollIntoView({ behavior: 'smooth' }) // Scroll to the latest message
    }
  };

  // archived messages implementation
  const filterArchivedMessages = () => {
    setArchivedMessages(
      // set message 
    );
  };
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  const closeOptions = () => {
    setShowOptions(false);
  };
  const toggleArchived = () => {
    setShowArchived(!showArchived);
  };

  // Handles interactions with a chat (e.g., selecting a chat or fetching messages)
  const handleInterChat = (index, conversationId, user, status) => {
    setSelectedChatData({ user, status })
    setSelectedChat(conversationId)
    fetchMessagesData2(conversationId, user)
    setSelectedProfile(user);
    setSelectedChatIndex(index);
    setSelectedProfile1(user);
  };

  // Creates a conversation with a specified sender and receiver
  const createConversationApi = async ({ senderId, receiverId }) => {
    try {
      const res = await userServices.createConversation("/chat/conversation", { senderId, receiverId })
      closeModal()
      fetchConversations()
    } catch (err) {
    }
  }

  // Fetches users for chat based on the role
  const fetchUsers = async () => {
    try {
      let res = await userServices.getChatUsers(`reservation/${role === "host" ? "guests" : "hosts"}/${userId}`);
      setChatUsers(res.data);
    } catch (err) {
    }
  };

  // Filters users who do not have existing conversations
  const usersWithoutConversations = chatUsers.filter((chatUser) => {
    return (
      chatUser &&
      chatUser._id !== userId &&
      (!conversationData2 ||
        conversationData2.every((conversation) =>
          conversation.user
            ? conversation.user.receiverId !== chatUser?._id
            : true
        ))
    );
  });

  // Clears selected chat and related states
  const handleClose = () => {
    setMessagesData2(null);
    setSelectedChatData(null)
    setSelectedProfile(null)
    setSelectedProfile1(null)
    setSelectedChat('')
  }
  useEffect(() => {
    fetchUsers()
    fetchConversations()
  }, [userId])

  // Handles incoming socket messages and updates chat data
  useEffect(() => {
    const handleSocketMessage = (data) => {
      if (data?.conversationId === selectedChat) {
        setMessagesData2(prev => ({
          ...prev,
          messages: [...(prev?.messages || []), { user: data.user, message: data.message, fileUrl: data.fileUrl }] // Ensure prev.messages is an array
        }))
      }
    };

    socket?.on('getMessage', handleSocketMessage);

    return () => {
      socket?.off('getMessage', handleSocketMessage);
    };
  }, [socket, selectedChat]);

  // Scroll to the latest message when component mounts
  useEffect(() => {
    messageRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        {
          usersWithoutConversations.length > 0 ?
            usersWithoutConversations.map((chatUser, index) => (
              <div
                key={index}
                onClick={() => createConversationApi({ receiverId: chatUser?._id, senderId: userId })}
                className={`flex  w-full cursor-pointer flex-row py-2 gap-2 px-2 justify-center items-center ${selectedChatIndex === index ? "bg-blue-200 border-2 " : ""
                  }`}
              >
                <div className="relative w-1/4">
                  <img
                    src={chatUser.pic}
                    className="object-cover w-12 h-12 rounded-full"
                    alt=""
                  />
                </div>
                <div className="w-full" >
                  <div className="flex items-start justify-between capitalize">
                    <span className="text-lg font-semibold">{chatUser.fname + " " + chatUser.lname}</span>
                    <span className="text-xs font-semibold text-start">
                      2:00 Pm
                    </span>
                  </div>
                </div>
              </div>
            )) : "no users"
        }
      </Modal>
      <div className="p-2 rounded-lg shadow-lg md:container md:p-0 md:mx-auto">
        {/* Details Section */}
        <div className="justify-between bg-white md:flex h-[100vh]">
          <div className="flex flex-col w-full h-[100vh] overflow-y-auto md:border-r-2 md:border-color-green md:w-2/5">
            <div className="flex flex-col gap-10 px-4 py-4">
              <div className="flex justify-between items-center text-black text-xl font-medium leading-[1.166]">
                <p
                  className={
                    viewButton && "flex justify-center items-center gap-3"
                  }
                >
                  {viewButton && (
                    <span className="cursor-pointer" onClick={handleClick}>
                      <IoIosArrowBack />
                    </span>
                  )}{" "}
                  {showArchived ? "Archived Messages" : "Messages"}
                </p>
                <div>
                  <img
                    src={menuSvg}
                    alt=""
                    onClick={toggleOptions}
                    className="cursor-pointer"
                  />
                  {showOptions && (
                    <div
                      onClick={() => {
                        toggleArchived();
                        filterArchivedMessages();
                        closeOptions();
                      }}
                      className="absolute z-50 p-3 mt-4 bg-white rounded shadow-md left-28"
                    >
                      <p className="py-2 px-4 cursor-pointer text-[16px] text-[#000] leading-trim text-edge-cap font-Avenir font-normal leading-3">
                        All Messages
                      </p>
                      <p
                        className="py-2 px-4 cursor-pointer text-[16px] text-[#E8AD21] leading-trim text-edge-cap font-Avenir font-normal leading-3"
                        onClick={() => {
                          toggleArchived();
                          filterArchivedMessages();
                          closeOptions();
                        }}
                      >
                        Archive Messages
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search Messages"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-2 py-2 border-2 border-gray-200 rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none ps-3">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {usersWithoutConversations.length > 0 &&
                <div className=' w-52'>
                  <ButtonFilled onClick={openModal} text='New Conversation' />
                </div>
              }
            </div>
            <div className="">
              {conversationData2 === '' ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                conversationData2?.length > 0 ? (
                  conversationData2.map(({ conversationId, user, lastMessage, unreadMessageCount }, index) => (
                    <div
                      key={index}
                      onClick={() => handleInterChat(
                        index,
                        conversationId,
                        user,
                        receiverStatus?.some(status => status?.receiverId === user?.receiverId && status?.status === 'online')
                      )}
                      className={`flex flex-row group cursor-pointer py-2 gap-2 px-2 justify-center items-center ${selectedChatIndex === index ? "bg-color-secondry border-2 bg-opacity-10" : ""}`}
                    >
                      <div className="relative w-[50px]">
                        <img
                          src={user?.pic}
                          className="object-cover w-[40px] h-[40px] rounded-full"
                          alt=""
                        />
                        {/* online status */}
                        {receiverStatus?.some(status => status?.receiverId === user?.receiverId && status?.status === 'online') && (
                          <span className="absolute top-0 xs:-right-2 sm:right-0 md:-right-2  rounded-full my-auto bg-yellow-500 h-[16px] border-2 border-white w-[16px]"></span>
                        )}
                      </div>
                      <div className="w-full">
                        <div className="flex items-start justify-between capitalize">
                          <span className="text-lg font-semibold">{user.fullName}</span>
                          <span className="text-xs font-semibold text-start">
                            {user.time || "23 h"}
                          </span>
                        </div>
                        <div className="flex items-start justify-between capitalize">
                          <span className="text-gray-500">{lastMessage ? lastMessage : ''}</span>
                          {
                            unreadMessageCount > 0 ?
                              <div className="text-xs text-white font-semibold h-8 w-8 rounded-full bg-yellow-500 flex items-center justify-center">
                                <span>{unreadMessageCount}</span>
                              </div>
                              : ''}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center">No conversations</p>
                )
              )}
            </div>

          </div>

          <div className="flex flex-col justify-between w-full ">
            {selectedProfile !== null && (
              <>
                <div className="fixed top-0 left-0 flex flex-col justify-between w-full bg-white md:relative md:bg-none ">
                  <div className="flex items-center justify-between px-5 py-5 bg-white border-b-2 border-color-green">
                    <div className="flex items-center justify-center font-semibold cursor-pointer ">
                      <img
                        src={selectedChatData?.user?.pic || dp2}
                        className="object-cover w-12 h-12 rounded-full pointer "
                        alt="not found"
                      />
                      <div className="mx-3">
                        <p className="  text-black text-[18px] font-medium leading-normal tracking-wide capitalize">
                          {selectedChatData?.user?.fullName || "User"}
                        </p>
                        <p className="text-black font-lato text-xs font-normal leading-[124.5%] capitalize">
                        </p>
                      </div>
                    </div>
                    <div className="cursor-pointer" onClick={handleClose}>
                      Close
                    </div>
                  </div>
                </div>

                <div className="relative flex flex-col w-full overflow-y-scroll bg-color-lightgrey">
                  <div className="relative h-[90vh] flex flex-col px-5 mt-5 overflow-y-scroll">
                    {loading2 ? (
                      <div className="flex items-center justify-center h-[90vh]">
                        <CircularProgress />
                      </div>
                    ) : (
                      messagesData2?.messages?.length > 0 ? (
                        messagesData2?.messages?.map((messageData, index) => {

                          return (
                            <div key={index} className={`w-[300px] block  rounded-b-xl  p-4 ${messageData?.user?.id !== userId ? "rounded-tl-xl mr-auto   flex justify-start mb-4 " : "flex justify-end mb-4 rounded-tr-xl ml-auto"}`}>
                              <img
                                src={messageData?.user?.pic}
                                className="object-cover w-8 h-8 rounded-full cursor-pointer"
                                alt
                              />
                              <div className={`l-2 py-3 mx-4 px-4 ${messageData?.user?.id !== userId ? "bg-color-secondry" : "bg-color-darkgrey"} rounded-lg text-color-black bg-opacity-10`}>
                                <p className="   text-black font-Asap text-[14px] font-medium leading-normal tracking-wide capitalize">
                                  {messageData?.message ? messageData?.message : ""}
                                </p>
                                {messageData.fileUrl && messageData.fileUrl.map((fileUrl, index) => (
                                  <div className="mt-2" key={index}>
                                    {fileUrl.endsWith('.pdf') ? (
                                      <a href={fileUrl} download>
                                        <img src={require('assets/img/pdf.jpg')} className="h-auto max-w-full" alt="PDF" />
                                        <span className="pt-4">{fileUrl.split('/').pop()}</span>
                                      </a>
                                    ) : fileUrl.endsWith('.docx') ? (
                                      <a href={fileUrl} download>
                                        <img src={require('assets/img/docx.webp')} className="h-auto max-w-full" alt="DOCX" />
                                        {fileUrl.split('/').pop()}
                                      </a>
                                    ) : fileUrl.match(/\.(png|jpg|gif|jpeg)$/) ? (
                                      <a href={fileUrl} download>
                                        <img src={fileUrl} className="h-auto max-w-full" alt="Image" />
                                      </a>
                                    ) : (
                                      <a href={fileUrl} download className="flex items-center gap-2">
                                        <img src={fileIcon} className="w-6 h-6" alt="" />
                                        {fileUrl.split('/').pop()}
                                      </a>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div ref={messageRef}></div>
                            </div>
                          )
                        }
                        )
                      ) : (
                        <p className="text-lg font-bold text-center">No messages yet</p>
                      )
                    )}
                  </div>

                </div>
                <div className="w-full ">
                  {selectedFiles?.length > 0 && (
                    <div className="flex flex-wrap gap-1 px-10 selected-files">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="file-name w-auto h-[50px] relative text-center bg-color-secondry  p-4 rounded-lg">
                          <RxCross2 className="absolute text-sm cursor-pointer top-1 right-1" onClick={() => removeFile(index)} />
                          {file.name.split(/[-_. ]/)[0]}.{file.type.split('/')[1]}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex w-full px-5 py-5 justify-evenly ">
                    <div className="m-auto">
                      <label htmlFor="fileInput">
                        <img
                          src={fileIcon}
                          className="h-[25px] w-[21px] cursor-pointer"
                          alt=""
                        />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>
                    <div className="relative w-full pl-4">
                      <input
                        type="text"
                        className="block w-full p-4 text-sm text-gray-900 border-2 border-gray-300 rounded-lg outline-none ps-10 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Type messages Here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        onKeyPress={(e) => e.key === "Enter" ? sendMessage() : ""}
                      />
                      <div className={`absolute inset-y-0 flex items-center right-3 start-0 ps-3 `}>
                        <img
                          src={shareIcon}
                          className={`h-[22px] w-[22px] ${loading2 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                          onClick={() => {
                            if (!loading2 && (message || selectedFiles.length > 0)) {
                              sendMessage();
                            }
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {
            selectedProfile1 !== null ?
              <ChatProfileDetails
                fullName={selectedChatData?.user?.fullName}
                image={selectedChatData?.user?.pic}
                status={selectedChatData?.status}
                setSelectedProfile1={setSelectedProfile1}
              />
              : ""
          }
        </div>
      </div>
    </>
  );
};

export default Chat;
