import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import ListingColor from "components/Cards/ListingColor";
import Modal from "components/Modal/Modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaExclamationTriangle } from "react-icons/fa";
import { updateFormData } from "redux/store/actions/userAction/formAction";

export default function SetPricing() {
  const dispatch = useDispatch();
  const { points, userCurrency } = useSelector((state) => state.form.formData);
  console.log("I am points", points);
  const [selectedDate, setSelectedDate] = useState(null);
  const [priceInput, setPriceInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (userCurrency) {
      fetchCurrencyRates(userCurrency);
    }
  }, [userCurrency]);

  // Function to fetch exchange rates for the selected currency
  const fetchCurrencyRates = (value) => {
    dispatch(
      updateFormData({
        userCurrency: value,
      })
    );
    // Use an API to fetch rates if needed
  };

  const openModal = (date) => {
    setSelectedDate(date);
    const existingPoint = Array.isArray(points)
      ? points.find((item) => item.date === getDateKey(date))
      : null;
    setPriceInput(existingPoint ? existingPoint.points : "");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getDateKey = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString().split("T")[0] + "T00:00:00.000Z";
  };

  const handleSave = () => {
    const dateKey = getDateKey(selectedDate);

    const updatedPoints = Array.isArray(points)
      ? points.some((item) => item.date === dateKey)
        ? points.map((item) =>
            item.date === dateKey
              ? { date: dateKey, point: Number(priceInput) }
              : item
          )
          
        : [...points, { date: dateKey, point: Number(priceInput) }]
      : [{ date: dateKey, point: Number(priceInput) }];
    dispatch(updateFormData({ points: updatedPoints }));
    setIsModalOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          lg={5}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "82vh", marginTop: "10vh" }}
        >
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Set your Pricing"
            color="text-[#FFFFFF]"
            btn="step 8"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            marginTop: "7vh",
            paddingTop: "25vh",
            height: "82vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0">
            <h1 className="my-2 text-[13px] md:text-xl font-bold text-center">
              Please set the pricing for each date, <br /> ensuring coverage for
              at least the next 6 months:
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* Calendar for pricing */}
              <div style={{ width: "80%", maxWidth: "400px" }}>
                <Calendar
                  onClickDay={openModal}
                  tileContent={({ date }) => {
                    const dateKey = getDateKey(date);
                    const dayPoint = Array.isArray(points)
                      ? points.find((item) => item.date === dateKey)
                      : null;
                    return dayPoint ? (
                      <div
                        style={{
                          textAlign: "center",
                          color: "green",
                          fontWeight: "bold",
                          lineHeight: "10px",
                          fontSize: "10px",
                        }}
                      >
                        {dayPoint.points || dayPoint.point} Points
                      </div>
                    ) : null;
                  }}
                  tileDisabled={({ date }) => date < new Date()}
                />
              </div>
            </div>

            <div className="flex items-center justify-center text-yellow-400 w-[80%] md:w-[70%] lg:w-[80%] xl:w-[60%] mx-auto my-4">
              <FaExclamationTriangle className="text-3xl mr-2" />
              <span className="text-center font-bold">
                Point prices must match dollar rates on Airbnb and Vrbo to
                maintain pricing accuracy.
              </span>
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <h3 className="font-bold text-2xl mt-20">
            Set Price for {selectedDate && selectedDate.toDateString()}
          </h3>
          <input
            type="number"
            placeholder="Enter points/price"
            value={priceInput}
            onChange={(e) => setPriceInput(e.target.value)}
            style={{
              padding: "10px",
              background: "#D3D3D3",
              borderRadius: "20px",
              fontSize: "16px",
              width: "70%",
              marginTop: "30px",
            }}
          />
          <div style={{ marginTop: "30px" }}>
            <button
              className="bg-blue-500 text-white font-bold rounded-full px-5 py-2"
              onClick={handleSave}
              style={{ marginRight: "10px" }}
            >
              Save
            </button>
            <button
              className="bg-blue-500 text-white font-bold rounded-full px-5 py-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
