import { CircularProgress, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import userServices from "services/httpService/userAuth/userServices";

const OurBlogs = () => {
  const [slider, setSlider] = useState(null);
  const [allBlog, setAllBlog] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //API call to get blogs
  const getBlog = async () => {
    try {
      let res = await userServices.commonGetService(`post/blogs`);
      setIsLoading(false);
      setAllBlog(res.data.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBlog();
  }, []);

  // slider responsiveness
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //formating date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <section className=" flex items-center justify-center pt-3  mx-4 md:mx-10 lg:mx-0 ">
        <div className=" w-full lg:w-4/5">
          <div>
            <div className="flex items-center justify-between">
              <div>
                <h4 className="text-[#000000] text-3xl font-medium">
                  Our Blogs
                </h4>
              </div>
              <div className="flex items-center gap-2 cursor-pointer">
                <div
                  onClick={() => slider && slider.slickPrev()}
                  className="w-[46px] flex items-center justify-center h-[46px] border-2 border-[#AFB5C1] rounded-full text-[#AFB5C1]"
                >
                  <i class="fa-solid fa-angle-left text-xl"></i>
                </div>
                <div
                  onClick={() => slider && slider.slickNext()}
                  className="w-[46px] flex items-center justify-center h-[46px] border-2 border-[#AFB5C1] rounded-full text-[#AFB5C1]"
                >
                  <i class="fa-solid fa-angle-right text-xl"></i>
                </div>
              </div>
            </div>
            <div>
              {isLoading ? (
                <div className="flex justify-center items-center h-52">
                  <CircularProgress />
                </div>
              ) : (
                <Slider ref={(c) => setSlider(c)} {...settings}>
                  {allBlog?.length > 0 &&
                    allBlog.map((item, index) => {
                      return (
                        <div className="relative" key={index}>
                          <div
                            className="w-full    xl:w-[270px] mt-5 h-[200px] bg-center bg-cover relative overflow-hidden cursor-pointer"
                            style={{
                              backgroundImage: `url(${item?.pic})`,
                            }}
                            onClick={() => navigate(`/blog/${item?._id}`)}
                          >
                            <div className="absolute inset-0 transition-opacity bg-[#58C0D0] opacity-0 overlay hover:opacity-90"></div>
                          </div>
                          <div className="w-full  xl:w-[270px] py-3 h-[160px] ">
                            <div className="">
                              <div className="flex items-center gap-2 text-[#AEAFB0]">
                                <i class="fa-solid fa-calendar-days"></i>
                                <span>{formatDate(item?.timeStamp)}</span>
                              </div>
                              <div className="w-[292px]">
                                <h4
                                  className="text-[#000000] hover:text-[#58C0D0] text-xl font-medium cursor-pointer"
                                  onClick={() => navigate(`/blog/${item?._id}`)}
                                >
                                  {item?.title?.length > 20
                                    ? item?.title.substring(0, 20) + "..."
                                    : item?.title}
                                </h4>
                              </div>
                              <div className="pt-2">
                                <p
                                  className="overflow-hidden font-normal text-md line-clamp-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.description?.length > 50
                                        ? `${item?.description.substring(
                                            0,
                                            60
                                          )}...`
                                        : item?.description,
                                  }}
                                ></p>
                              </div>
                              <div className="text-[#C0392D]">
                                <span
                                  className="text-base font-medium cursor-pointer"
                                  onClick={() => navigate(`/blog/${item._id}`)}
                                >
                                  READ MORE
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBlogs;
