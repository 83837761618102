import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import { GrCircleAlert } from "react-icons/gr";
import Modal from "components/Modal/Modal";
import msgImg from "../../../assets/img/modalimg.png";
import dayjs from 'dayjs';
import GuestAndRoomSelector from "components/GuestAndRoomSelector/GuestAndRoomSelector";

const currentDate = dayjs();
const ChangeReservation = () => {
  const [favourite, setFavourite] = useState(false);
  const [alert, setalert] = useState(false);

  const location = useLocation();
  const { state } = location;
  let [checkIn, setCheckIn] = useState(state?.state.offerState.checkIn.$d);
  let [checkOut, setCheckOut] = useState(state?.state.offerState.checkOut.$d);

  const [selectoption, setSelectOption] = useState({ guest: state.offerState.guest });

  const [guests, setGuests] = useState(state.offerState.guest);
  const [rooms, setRooms] = useState(state.offerState.room);

  // Function to handle changes in the number of guests
  const handleGuestChange = (action) => {
    if (action === 'increment' && guests < state?.state?.propertyDetail?.spaceTypeDetail?.guests) {
      setGuests(prevGuests => prevGuests + 1);
    } else if (action === 'decrement' && guests > 1) {
      setGuests(prevGuests => prevGuests - 1);
    }
  };

  // Function to handle changes in the number of rooms
  const handleRoomChange = (action) => {
    if (action === 'increment' && rooms < state?.state?.propertyDetail?.spaceTypeDetail?.bedrooms) {
      setRooms(prevRooms => prevRooms + 1);
    } else if (action === 'decrement' && rooms > 1) {
      setRooms(prevRooms => prevRooms - 1);
    }
  };


  const handleChange = () => {
    setFavourite(false);
  };

  const handleCheckInChange = (newValue) => {
    setCheckIn(newValue);
  };
  const handleCheckOutChange = (newValue) => {
    setCheckOut(newValue);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const guestCount = parseInt(selectedValue, 10);
    setSelectOption((prevState) => ({ ...prevState, guest: guestCount }));
  };

  function formatDate(date) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  // Function to format time
  function formatTime(date) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert midnight (0) to 12
    const formattedMinute = `${minute < 10 ? '0' : ''}${minute}`; // Ensure two-digit minute representation
    return `${formattedHour}:${formattedMinute}${ampm}`;
  }

  // Check-in date and time
  const checkInDate = new Date(state?.state?.offerState?.checkIn.$d);
  const checkInFormattedDate = formatDate(checkInDate);
  const checkInFormattedTime = formatTime(checkInDate);

  // Check-out date and time
  const checkOutDate = new Date(state?.state?.offerState?.checkOut.$d);
  const checkOutFormattedDate = formatDate(checkOutDate);
  const checkOutFormattedTime = formatTime(checkOutDate);


  return (
    <>
      {!alert ? (
        <section className="bg-[#FAEFD3]">
          <Container maxWidth="xl">
            <div className="flex items-center gap-2 p-3">
              <GrCircleAlert className="text-[#E8AD21]" />
              <p>
                You are going to be charged{" "}
                <span className="font-bold">$50 </span> for changing reservation
              </p>
            </div>
          </Container>
        </section>
      ) : (
        <section className="bg-[#ECC4C0]">
          <Container maxWidth="xl">
            <div className="flex items-center gap-2 p-3">
              <GrCircleAlert className="text-[#C0392D]" />
              <p>
                You can not change reservation as the date limit is exceeded.
              </p>
            </div>
          </Container>
        </section>
      )}

      <Container maxWidth="xl">
        <Container maxWidth="xl">
          <div className="mt-2 reserveDetailWrapper md:flex gap-6">
            <div className=" md:w-6/12">
              <div className="my-6 flex items-center ">
                <i class="fas fa-angle-left"></i>
                <h4 className="text-2xl font-semibold ml-2 ">
                  Change your reservation
                </h4>
              </div>

              <div className="flex flex-col xl:flex-row gap-2 xl:gap-5  w-full">
                <div className="flex justify-between xl:w-[70%] w-full rounded-md border border-gray-500 text-[#AEAFB0]">
                  <div className="p-2  ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Basic example"
                        value={checkIn}
                        dateFormat="LLL"
                        onChange={handleCheckInChange}
                        minDate={currentDate.toDate()}
                        shouldDisableDate={(date) => state?.state?.propertyDetail?.bookDates.includes(date.toISOString())}
                        renderInput={({
                          inputRef,
                          inputProps,
                          InputProps,
                          params,
                        }) => (
                          <div className="flex items-center justify-between ">
                            <input
                              className="w-24 text-black outline-0"
                              ref={inputRef}
                              {...inputProps}
                              placeholder="Check-in"
                              {...params}
                            />
                            {InputProps?.endAdornment}

                          </div>
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div class="border-l border-[#AEAFB0] my-2 lg:mx-4"></div>

                  <div className="p-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Basic example"
                        value={checkOut}
                        dateFormat="LLL"
                        onChange={handleCheckOutChange}
                        minDate={currentDate.toDate()}
                        shouldDisableDate={(date) => state?.state?.propertyDetail?.bookDates.includes(date.toISOString())}
                        renderInput={({
                          inputRef,
                          inputProps,
                          InputProps,
                          params,
                        }) => (
                          <div className="flex items-center">
                            <input
                              className="w-24 text-black outline-0"
                              ref={inputRef}
                              {...inputProps}
                              placeholder="Check-out"
                              {...params}
                            />
                            {InputProps?.endAdornment}

                          </div>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div class=" xl:w-[30%]  w-full">

                  <GuestAndRoomSelector
                    handleGuestChange={handleGuestChange}
                    handleRoomChange={handleRoomChange}
                    guests={guests}
                    rooms={rooms}
                  />

                </div>
              </div>

              <div className="my-4">
                <img
                  src={state?.state?.propertyDetail?.pics[0]}
                  alt=""
                  className="rounded-2xl w-full md:h-[400px]"
                />
                <div className="flex items-center justify-between mt-2">
                  <div>
                    <h3 className="text-2xl text-semibold py-2">
                      {state?.state?.propertyDetail?.title}
                    </h3>
                    <p className="text-sm text-[#4B4B4B]">An entire place Booked for you</p>
                  </div>
                  <div>
                    <figure className="max-w-[71.1px] relative max-h-[69.93px]">
                      <div className="w-[69.93px] h-[69.93px] relative overflow-hidden rounded-full">
                        <img src={require("assets/img/angela.png")}></img>
                      </div>
                      <span className="bg-[#E8AD21] absolute top-1 right-[18px] z-50 border-2 border-white w-[16px] h-[16px] rounded-full"></span>
                    </figure>
                  </div>
                </div>
                <hr className="mt-6 border-gray-300 " />

                <div className="mt-6">
                  <div className="flex items-center justify-between">

                    <div className="flex flex-col">
                      <p className="font-bold">Check In</p>
                      <p className="text-[#4B4B4B]">{checkInFormattedDate}</p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold">Check Out</p>
                      <p className="text-[#4B4B4B]">{checkOutFormattedDate}</p>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center my-2">
                    <button
                      className="md:w-1/2 btn-styl-blue"
                      onClick={() => setFavourite(true)}
                    >
                      <p className="text-xl"> Save Changes</p>
                    </button>
                    {/* <button className="px-12 py-2 ml-2 border rounded-full border-color-yellow text-color-yellow">
                      Referral
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="height-line md:w-6/12 md:mt-20 md:ml-4 rounded-xl">
              <div className="reserveImage "></div>
              <div className="items-center h-48 px-2 ">
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Address</p>
                    <p className="text-sm text-[#4B4B4B]">
                      {state?.state?.propertyDetail?.address}
                    </p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* Get Direction */}
                    TBD
                  </p>
                </div>
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Guests</p>
                    <p className="text-sm text-[#4B4B4B]">{state?.state?.offerState?.guest}</p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* Invite Guest */}
                    TBD
                  </p>
                </div>
                <div className="mt-3 ">
                  <span className="font-semibold">Rooms</span>
                  <p className="text-sm text-[#4B4B4B] pt-2">{state?.state?.offerState?.room}</p>
                </div>
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Points</p>
                    <p className="text-sm text-[#4B4B4B]">{state?.state?.offerState?.points}</p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* View Receipt */}
                    TBD
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-semibold text-md">Service Fee</p>
                  <p className="text-sm text-[#4B4B4B]">${state?.state?.offerState?.serviceFee}</p>
                </div>
                <hr className="flex items-center justify-center mt-6 border-gray-300 w-12/12" />
                <div className="mt-3 ">
                  <span className="font-semibold">Reservation code</span>
                  <p className="text-sm text-[#4B4B4B] pt-2">{state?.state?.propertyDetail?._id}</p>
                </div>
                <hr className="flex items-center justify-center mt-6 border-gray-300 w-12/12" />

                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">
                      {state?.state?.propertyDetail?.user?.fname} is your host
                    </span>
                    <p className="text-sm text-color-darkgrey">
                      {state?.tripe?.message}
                    </p>
                  </div>
                  <p className="text-sm underline">
                    {/* Message Host */}
                    TBD
                  </p>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">Know what to expect</span>
                    <p className="text-sm text-[#4B4B4B]">
                      Make sure to review the house rules and amenities.
                    </p>
                  </div>
                  <div>
                    <p className="text-sm underline">
                      {/* View House Rules */}
                      TBD
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">Customer support</span>
                    <p className="text-sm text-[#4B4B4B]">
                      Contact our support team 24/7 from anywhere in the world.
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <p className="text-sm underline">
                      {/* Visit Help Centre */}
                      TBD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={favourite} onClose={handleChange}>
            <div className="w-full  mt-12">
              <div className="flex justify-center items-center p-5 ">
                <img src={msgImg} className="w-[100px] mx-auto" />
              </div>
              <div className="flex justify-center items-center">
                <p className="text-xl font-bold ">Change Reservation</p>
              </div>
              <div className="flex justify-center items-center ">

              </div>
              <div className="flex flex-col items-center justify-center my-16">
                <span className="font-bold text-lg shadow-lg text-color-darkgrey">Are You Confirm To Change Reservation!</span>
                <div className="flex gap-7 my-10 items-center justify-center">
                  <button
                    className="py-2 px-8 rounded-full bg-blue-500 text-white text-lg"
                  // onClick={handleUpdateClick}
                  >
                    YES
                  </button>
                  <button
                    className="py-2 px-8 rounded-full bg-blue-500 text-white text-lg"
                    onClick={handleChange}
                  >
                    NO
                  </button>
                </div>
              </div>

            </div>
          </Modal>
          <br />
          <br />

          <br />

          <br />
          <br />
          <br />
        </Container>
      </Container>
    </>
  );
};

export default ChangeReservation;
