export const ButtonFilled = ({ text, onClick,disabled=false }) => {
  const buttonType = onClick ? "button" : "submit";

  

  return (
    <button
      onClick={onClick}
      type={buttonType}
      disabled={disabled}
      className=" uppercase text-center py-3 px-5 rounded-full bg-[#2459BF] text-white text-sm font-semibold"
    >
      {text}
    </button>
  );
};
