import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SearchCard from "components/Cards/SearchCard";
import { config } from "../../../config";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import GoogleMapReact from "google-map-react";
import { useLocation, useParams } from "react-router-dom";
import LandingSearchBar from "components/LandingSearchBar/LandingSearchBar";

const PropertyMaker = ({ text }) => (
  <div>
    <a className="w-17 p-2 font-bold text-center  rounded shadow-lg">
      <img
        src={require("assets/img/icon.png")}
        className="lg:w-25 lg:h-25 xs:w-12 xs:h-12  rounded object-contain"
      ></img>
    </a>
  </div>
);
export default function SearchListings() {
  const { lat, long } = useParams(); // Get latitude and longitude from URL parameters
  const { state } = useLocation(); // Get state from location object, passed when navigating
  let defaultProps = {
    center: {
      lat: lat,
      lng: long,
    },
    zoom: 11,
  };

  const [allPost, setallPost] = useState([]);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
  };

  // Handle filter based on bedroom or guest
  const handleFilter = (type) => {
    if (type === "bedroom" || type === "guest") {
      setFilterType(type);
      setSelectedFilter(type);
    }
  };

  const filterPrice = (val) => {
    setSelectedPrice(val);
  };

  // Fetch property data based on lat and long
  const getproperty = async () => {
    setLoading(true);
    try {
      const res = await userServices.commonGetService(`/property/getAllProperty/${lat}/${long}/15`);
      let filterData = res?.data?.data || [];
      if (state?.spaceTypeDetail) {
        filterData = filterData.filter((item) => item.spaceTypeDetail.guests >= state?.spaceTypeDetail);
      }
      if (state?.bedroomData) {
        filterData = filterData.filter((item) => item.spaceTypeDetail.bedrooms >= state?.bedroomData);
      }
      // Filter based on check-in and check-out dates if provided
      if (state?.checkIn && state?.checkOut) {
        const checkInDate = new Date(state.checkIn);
        const checkOutDate = new Date(state.checkOut);
        filterData = filterData.filter((item) => {
          const bookedDates = item.bookedDates || [];
          const isAvailable = !bookedDates.some((bookedDate) => {
            const bookedDateObj = new Date(bookedDate);
            return (bookedDateObj >= checkInDate && bookedDateObj <= checkOutDate);
          });
          return isAvailable;
        });
      }
      setallPost(filterData);
    } catch (error) {
      ErrorService.handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle data filtering based on price and bedrooms
  const handleFilterData = (priceRange, bedrooms) => {

    const filterData = data.filter((item) => {
      return item.price >= priceRange.min && item.price >= priceRange.max;
    });

    if (filterData.length > 0) {
      setallPost(filterData);
    } else {
      setallPost(filterData);
    }
  };

  useEffect(() => {
    getproperty();
  }, [lat, long]);

  return (
    <div className="mt-20">
      <Grid item sm={12} xs={12} style={{ border: 'none' }}>
        <div className="px-4 pt-3 pb-7 bg-cover bg-center  bg-[url('assets/img/headerBg.png')]">
          <LandingSearchBar
            notPedding="notapply"
            checkindate={state.checkIn?.$d}
            checkoutdate={state.checkOut?.$d}
            onSearchInputChange={handleSearchInputChange}
            handleFilter={handleFilter}
            filterType={filterType}
            filterPrice={filterPrice}
            setSelectedPrice={setSelectedPrice}
            handleFilterData={handleFilterData}
          />
        </div>
      </Grid>
      <Grid container spacing={2} >

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="px-2 lg:px-0 w-full md:ml-10 xl:ml-24 h-full overflow-y-auto">
            <p className="px-2 py-4 font-bold text-md">
              {allPost.length} Homes Available
            </p>

            {loading ? (
              <p className="flex items-center justify-center my-4 text-lg font-semibold">
                Loading...
              </p>
            ) : allPost.length === 0 ? (
              <p className="flex items-center justify-center my-4 text-lg font-semibold">
                No data matches
              </p>
            ) : (
              <div>
                {allPost.map((item, index) => (
                  <div key={item._id}>
                    <SearchCard data={item} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="w-full h-screen">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: config.mapkey,
              }}
              defaultCenter={{
                lat: parseFloat(lat),
                lng: parseFloat(long),
              }}
              defaultZoom={defaultProps.zoom}
              center={{
                lat: parseFloat(lat),
                lng: parseFloat(long),
              }}
            >
              {allPost.length > 0 && allPost.map((item) => {
                return (
                  <PropertyMaker
                    lat={item.loc.coordinates[1]}
                    lng={item.loc.coordinates[0]}
                    text={item.price}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
