import React, { useState } from 'react';
import { Taxes } from './Taxes';
import { PersonalInfo } from './PersonalInfo';
import { TrackPayments } from './TrackPayments';
import { LoginPrivacy } from './LoginAndPrivacy';
import { PaymentsAndPayouts } from './PaymentsAndPayouts';

export const Account = () => {
  const [viewPayout, setViewPayout] = useState(false);
  const [selectedTab, setSelectedTab] = useState('personalInfo');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div>
      {
        !viewPayout ?(
          <div className="md:my-10 md:mx-20">
          <p className="text-xl md:text-2xl lg:text-4xl font-Normal pt-8 pb-4 pl-3 md:pl-0">Personal Information</p>
          <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
            <ul className="flex flex-wrap -mb-px cursor-pointer text-[#AFB5C1]">
              <li className="me-2">
                <p
                  onClick={() => handleTabClick('personalInfo')}
                  className={`inline-block p-4 border-b-2 text-[12px] md:text-base lg:text-xl ${selectedTab === 'personalInfo'
                    ? 'border-yellow-500 rounded-t-lg text-black'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    }`}
                >
                  Personal Info
                </p>
              </li>
              <li className="me-2">
                <p
                  onClick={() => handleTabClick('paymentsAndPayouts')}
                  className={`inline-block p-4 border-b-2 text-[12px] md:text-base lg:text-xl ${selectedTab === 'paymentsAndPayouts'
                    ? 'border-yellow-500 rounded-t-lg text-black'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    }`}
                >
                  Payments
                </p>
              </li>
              <li className="me-2">
                <p
                  onClick={() => handleTabClick('trackPayments')}
                  className={`inline-block p-4 border-b-2 text-[12px] md:text-base lg:text-xl ${selectedTab === 'trackPayments'
                    ? 'border-yellow-500 rounded-t-lg text-black'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    }`}
                >
                  Track Payments
                </p>
              </li>
              {/* <li className="me-2">
                <p
                  onClick={() => handleTabClick('taxes')}
                  className={`inline-block p-4 border-b-2 ${selectedTab === 'taxes'
                    ? 'border-yellow-500 rounded-t-lg text-black'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    }`}
                >
                  Taxes
                </p>
              </li> */}
              <li className="me-2">
                <p
                  onClick={() => handleTabClick('loginPrivacy')}
                  className={`inline-block p-4 border-b-2 text-[12px] md:text-base lg:text-xl ${selectedTab === 'loginPrivacy'
                    ? 'border-yellow-500 rounded-t-lg text-black'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    }`}
                >
                  Login & Privacy
                </p>
              </li>
            </ul>
          </div>
        </div>
        ):null
      }
      {/* Render content based on the selected tab */}
      {selectedTab === 'taxes' && <Taxes />}
      {selectedTab === 'loginPrivacy' && <LoginPrivacy />}
      {selectedTab === 'personalInfo' && <PersonalInfo />}
      {selectedTab === 'trackPayments' && <TrackPayments />}
      {selectedTab === 'paymentsAndPayouts' && <PaymentsAndPayouts viewPayout={viewPayout} setViewPayout={setViewPayout}/>}
    </div>
  );
};
