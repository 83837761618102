import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import userServices from "services/httpService/userAuth/userServices";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { localStorageData } from "services/auth/localStorageData";

const CommunityReply = () => {
  const [communityReply, setcommunityReply] = useState([]);
  const [replyComment, setreplyComment] = useState("");
  const params = useParams();

  // Function to fetch and set community replies
  const getproperty = async () => {
    let res = await userServices.commonGetService(
      `post/communityPost/${params.id}`
    );
    setcommunityReply(res.data.data);
  };

  useEffect(() => {
    getproperty();
  }, []);

  // Mutation hook for posting a reply
  const { mutate: postData, isLoading } = useMutation(
    (token) => userServices.communityReplyPost(`post/replyPost`, token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Reply post successfully!");
        getproperty();
        setreplyComment("");
      },
    }
  );

  // Function to handle posting a reply
  const handlePost = () => {
    postData({
      communityId: params.id,
      userId: localStorageData("_id"),
      description: replyComment,
    });
  };

  return (
    <>
      <section>
        <Container maxwidth="lg">
          <Container maxwidth="lg" className="my-4 border rounded-md p-2">
            <div className="mt-2 md:flex border-b border-b-color-grey">
              <div className="md:w-1/12 p-1">
                <img
                  src={communityReply.userId?.pic}
                  className="mr-2 rounded-full "
                  alt=""
                />
              </div>
              <div className="w-full">
                <p className="font-semibold">
                  {communityReply.userId?.fname} {communityReply.userId?.lname}
                </p>
                <p className="text-sm text-color-darkgrey">
                  Port Moody, Canada Community Manager
                </p>
                <div className=" my-4">
                  <h3 className="font-semibold">
                    {/* Getting ready for World Clean Up Day 2023 */}
                    {communityReply?.subject}
                  </h3>
                  <p
                    className="text-sm text-color-darkgrey"
                    dangerouslySetInnerHTML={{
                      __html: communityReply.description,
                    }}
                  ></p>
                </div>
                <div className="flex mb-4 justify-between  text-sm text-color-grey">
                  <p>Host Advisory Board Member . Australia</p>
                  <p>
                    Monday . {communityReply.replies?.length} <span className="cursor-pointer">Replies</span>{" "}
                    . {communityReply.likes?.length} <span className="cursor-pointer">Likes</span>
                  </p>
                </div>
              </div>
            </div>
            {communityReply.replies?.map((item, index) => {
              const dateObj = new Date(item.timeStamp);

              const dayOfWeek = dateObj.toLocaleDateString("en-US", {
                weekday: "long",
              });

              // Get the time in HH:MM:SS format
              const time = dateObj.toLocaleTimeString("en-US", {
                hour12: false,
              });

              return (
                <div className=" flex shadow  p-2" key={index}>
                  <div className="md:w-1/12 flex justify-center p-1">
                    <img
                      src={item.user?.pic}
                      className=" w-10 h-10 rounded-full "
                      alt=""
                    />
                  </div>
                  <div className="w-full">
                    <p className="font-semibold">
                      {item.user?.fname} {item.user?.lname}
                    </p>

                    <div className=" my-2">
                      <p className="text-sm text-color-darkgrey/70">
                        {item.description}
                      </p>
                      <small className="text-color-grey">
                        {dayOfWeek} {time}
                      </small>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="my-1 flex justify-between items-center w-full shadow">
              <input
                type="text"
                placeholder="Enter your messenger"
                className="px-2 py-4 w-full outline-none"
                value={replyComment}
                onChange={(e) => setreplyComment(e.target.value)}
              />
              <button
                className="px-6 py-2 bg-blue-500 text-white rounded-full"
                onClick={handlePost}
              >
                Send
              </button>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default CommunityReply;
