import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import RefundStep1 from "components/Refund/RefundStep1";
import RefundStep2 from "components/Refund/RefundStep2";
import RefundStep3 from "components/Refund/RefundStep3";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { BiSolidCalendar } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import userServices from "services/httpService/userAuth/userServices";
import moment from "moment";
const Refund = () => {
  const params = useParams();
  const [step, setStep] = useState("2");
  const [reservationDetails, setReservationDetails] = useState('')
  const [refundReason, setRefundReason] = useState("");

  // Fetches reservation details based on the reservation ID from URL params
  const getReservation = async () => {
    try {
      const apiUrl = `/reservation/reservation/${params.id}`;
      const res = await userServices.getReservationByuserId(apiUrl);
      setReservationDetails(res.data);
    } catch (error) {
      console.error("Error fetching reservation data", error);
    }
  };
  useEffect(() => {
    getReservation();
  }, []);

  //format checkin checkout date to readable form
  const formattedCheckIn = reservationDetails?.offerState
    ? moment(reservationDetails?.offerState?.checkIn).format('ddd, MMM D')
    : '';
  const formattedCheckOut = reservationDetails?.offerState
    ? moment(reservationDetails?.offerState?.checkOut).format('ddd, MMM D')
    : '';

  // Calculates refund points and service fee 
  const refundPoints = reservationDetails.offerState
    ? reservationDetails.offerState.points * 0.8
    : 0;

  const refundServiceFee = reservationDetails.offerState
    ? Math.floor(reservationDetails.offerState.serviceFee * 0.8)
    : 0;

  // Calculates reduced points based on 20% of the total points
  const reducedPoints = Math.floor(reservationDetails.offerState?.points * 0.2 || 0);

  return (
    <>
      <Container>
        <div className="py-10">
          <span className="font-medium text-[36px]">Refund</span>
        </div>
        <div className="md:flex gap-10 ">
          <div className="w-[50%]">
            {step === "1" && <RefundStep1 step={step} setStep={setStep} />}
            {step === "2" && <RefundStep2 step={step} setStep={setStep} setRefundReason={setRefundReason} refundReason={refundReason} />}
            {step === "3" && <RefundStep3 step={step} setStep={setStep} reservationDetails={reservationDetails} refundReason={refundReason} />}
          </div>

          <div className="md:w-[50%] h-fit p-5 border rounded-md gap-2 mt-3">
            <div className="md:flex gap-2 items-center">
              <img src={reservationDetails?.property_id?.pics[0]} alt="" className="w-[80px] h-[80px] rounded-lg" />
              <div className="flex flex-col">
                <p className="font-bold">{reservationDetails?.property_id?.title}</p>
                <p>{reservationDetails?.property_id?.spaceType}</p>
              </div>
            </div>
            <hr className="mt-2 "></hr>
            <div className="py-3">
              <div className="flex items-center  gap-2">
                <FaPersonBreastfeeding className="text-2xl text-color-darkgrey/80" />
                <p className="text-md text-color-darkgrey/80">
                  {reservationDetails?.property_id?.spaceTypeDetail?.guests} Guest
                </p>
              </div>
              <div className="flex items-center pt-3 gap-2">
                <BiSolidCalendar className="text-2xl text-color-darkgrey/80" />
                {/* <p className="text-md text-color-darkgrey/80">
                  Mon, Apr 6 ➡ Fri, Apr 16
                </p> */}
                <p className="text-md text-color-darkgrey/80">
                  {formattedCheckIn} ➡ {formattedCheckOut}
                </p>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">Total Points</p>
              <p className="text-color-darkgrey/80">{reservationDetails?.offerState?.points}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">Service Fee</p>
              <p className="text-color-darkgrey/80">{reservationDetails?.offerState?.serviceFee}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">reduced points</p>
              <p className="text-color-darkgrey/80">{reducedPoints}</p>
            </div>
            <hr className="mt-2" />
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="font-bold">Refund Points</p>
              <p className="font-bold">{refundPoints}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="font-bold">Refund Service Fee</p>
              <p className="font-bold">{refundServiceFee}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Refund;
