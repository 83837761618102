import React, { useEffect } from 'react'
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import userServices from 'services/httpService/userAuth/userServices';
import { FaSchoolCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';


const SuccessStripe = () => {

  // Extracts query parameters from the URL and decodes them into an object   
  const searchParams = window.location.search.substr(1).split('&');
  const params = {};

  const navigate = useNavigate();

  // Parses the query parameters and populates the `params` object
  searchParams.forEach(param => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value);
  });

  // mutation for handling referral code activation via an API call
  const { mutate: setMutate, isLoading: loadingg } = useMutation(
    (token) =>
      userServices.getReferralCode("stripe/success_payment", token),
    {
      onError: (error) => {
        toast.error(error?.response?.data?.message || "something went wrong!");
      },
      onSuccess: (data) => {
        toast.success("subscription activated successfully");
      },
    }
  );


  useEffect(() => {
    if (params.userId && params.referrelData && params.subscriptionType) {
      setMutate({ userId: params.userId, referrelId: params.referrelData, subscriptionType: params.subscriptionType })
    }
  }, [])

  return (
    <>
      <div className='flex flex-col justify-center items-center gap-10 min-h-screen bg-gray-100 pb-28 pt-5 px-3'>
        <div className='bg-white px-5 py-5 md:py-3 xl:py-12 rounded-lg shadow-md flex flex-col items-center w-full max-w-lg'>
          <div className='mb-10'>
            <FaSchoolCircleCheck className='text-blue-500 w-32 h-32' />
          </div>
          <div className='flex flex-col gap-3 justify-center items-center text-center'>
            <span className='text-4xl font-bold text-blue-500'>Thank You!</span>
            <span className='font-bold text-lg'>Payment done Successfully.</span>
          </div>
          <div className='flex flex-col justify-center text-center items-center w-full mt-5'>
            <span className='font-bold text-lg mb-2 text-color-blue'>Go Ahead to complete your profile.</span>
            <p className='text-gray-500 text-lg'>You will be redirected to the home page shortly or click here to return to the home page</p>
            <button className='py-2 px-12 mt-7 rounded-full bg-blue-500 text-white text-lg shadow-lg transition-transform transform hover:scale-105'
              onClick={() => navigate('/')}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </>


  )
}

export default SuccessStripe