import React from 'react';

export const PaymentCard = ({ name, icon, number, verification }) => {
    return (
        <div className="flex flex-col md:flex-row gap-4 border border-[#AFB5C1] p-4 rounded-2xl shadow-md transition-shadow duration-300 ease-in-out">
            <div className="flex items-center justify-center md:justify-start mb-4 md:mb-0">
                <img className="w-[110px] h-auto" src={icon} alt="visa card" />
            </div>
            <div className="flex flex-col justify-between w-full">
                <div>
                    <p className="text-xl font-medium capitalize">{name}</p>
                    <p className="text-base font-normal text-[#4B4B4B]">Bank <span>{number}</span></p>
                </div>
                <p className="text-base font-normal text-[#2459BF] pt-2 capitalize">{verification}</p>
            </div>
        </div>
    );
};
