import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLocalUserdata } from 'services/auth/localStorageData';

const AdminProtectedRoute = () => {

  const userData = getLocalUserdata();
  // const isAuthenticated = userData?.data?.role;
  // console.log("Is authenticated", isAuthenticated)
  // (userData?.data?.isAdmin === true) || (Array.isArray(userData?.role)?.length>0);
  const isAuthenticated =
    (userData?.data?.isAdmin === true) || (Array.isArray(userData?.data?.role) && userData?.data?.role?.length > 0);


  return isAuthenticated ? <Outlet /> : <Navigate to="/" />
};

export default AdminProtectedRoute;


