import React from 'react'
import { Reviews } from './Reviews'
import { Earnings } from './Earnings'

export const Insights = ({ selectButton }) => {
  return (
    <div>
      {selectButton ? <Earnings /> : <Reviews />}
    </div>
  )
}
