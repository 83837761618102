import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'components/Modal/Modal'; // Adjust the import path as necessary
import PopupModal from 'components/PopupModal/PopupModal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const StatusMenu = ({ initialStatus, itemId, onStatusChange, statusTexts }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(initialStatus);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus); // Track selected status before confirmation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newStatus) => {
    setAnchorEl(null);
    setSelectedStatus(newStatus); // Update selected status
    setOpenAlert(true); // Open the modal after selecting a new status
  };

  const handleConfirmChange = () => {
    onStatusChange(itemId, selectedStatus); // Change the status
    setStatus(selectedStatus); // Update the status state
    setOpenAlert(false); // Close the modal
  };


  const handleCancelChange = () => {
    setOpenAlert(false); // Close the modal without changing the status
  };

  const closeModal = () => {
    setOpenAlert(false);
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className={`py-2 px-4 ${status === 'Active' ? 'text-[#39CB79] bg-[#39CB791A]' : status === 'Pending' ? 'text-[#FFA500] bg-[#FFA5001A]' : 'text-[#FF0000] bg-[#FF00001A]'} rounded-full`}
      >
        {status}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {statusTexts.map((text, index) => (
          <MenuItem key={index} onClick={() => handleClose(text)}>{text}</MenuItem>
        ))}
        {/* <MenuItem onClick={() => handleClose('Pending')}>Pending</MenuItem>
        <MenuItem onClick={() => handleClose('Active')}>Active</MenuItem>
        <MenuItem onClick={() => handleClose('Rejected')}>Rejected</MenuItem> */}
      </Menu>

      {openAlert && (

        <PopupModal
          icon={
            <div className={` ${selectedStatus === 'Active' ? 'text-[#39CB79] bg-[#39CB791A]' : selectedStatus === 'Pending' ? 'text-[#FFA500] bg-[#FFA5001A]' : 'text-[#FF0000] bg-[#FF00001A]'} mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`}>
              <ExclamationTriangleIcon
                className={`h-6 w-6 ${selectedStatus === 'Active' ? 'text-[#39CB79]' : selectedStatus === 'Pending' ? 'text-[#FFA500] ' : 'text-[#FF0000]'}`}
                aria-hidden="true"
              />
            </div>
          }
          handleCancle={handleCancelChange}
          handleAccept={handleConfirmChange}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description={`Are you sure you want to change the status to ${selectedStatus}?`}
          title={`Change the Status`}
          acceptbtnText='Confirm'
          handleAcceptBgColor={`${selectedStatus === 'Active' ? 'bg-[#39CB79] px-4 py-2' : selectedStatus === 'Pending' ? 'bg-[#FFA500] px-4 py-2' : 'bg-[#FF0000] px-4 py-2'}`}
        />
      )}

    </div>
  );
};

export default StatusMenu;
