import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import profileImg from '../../../assets/img/dp.png'
import profileImg2 from '../../../assets/img/ProfileImg.png'
import profileImg3 from '../../../assets/img/angela.png'
export const Dashboard = () => {

  const notificationList = [
    {
      name: "Albert Einstein",
      date: "June 9,2023",
      timeAgo: "just now",
      new: true,
      image: profileImg
    },
    {
      name: "Isaac Newton",
      date: "June 9,2023",
      timeAgo: "1 week ago",
      new: true,
      image: profileImg2
    },
    {
      name: "Nikola Tesla",
      date: "June 9,2023",
      timeAgo: "2 mintues ago",
      new: false,
      image: profileImg3
    },
    {
      name: "Isaac Newton",
      date: "June 9,2023",
      timeAgo: "1 week ago",
      new: true,
      image: profileImg2
    },
    {
      name: "Nikola Tesla",
      date: "June 9,2023",
      timeAgo: "2 mintues ago",
      new: false,
      image: profileImg3
    },
  ]
  const bookingConversion = [
    {
      value: '0.20%',
      label: 'Overall conversion rate',
    },
    {
      value: '57.3%',
      label: 'First-page search impression rate',
    },
    {
      value: '15.97%',
      label: 'Search-to-listing conversion',
    },
    {
      value: '15.97%',
      label: 'Listing-to-booking conversion',
    },
  ];
  const userData = {
    points: '0.00',
    pointsLabel: 'Points',
    earningsLabel: 'Booked earnings for September 2023',
    paidOut: '0.00',
    expected: '0.00',
    septemberEarnings: '$0.00',
    thirtyDayViews: 60,
    thirtyDayBookings: 4,
    overallRating: 5,
    totalReviews: 240,
  };
  return (
    <div className='flex flex-col gap-4 pb-10'>
      <div className="flex justify-between pt-8 md:pt-0 pb-4 md:pb-0">
        <p className="font-medium text-lg md:text-2xl text-center">Booking Conversion</p>
        <div className="h-[40px] rounded-[50px] cursor-pointer  border-4 border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2" >
          <span className='uppercase my-auto  pr-2 text-xs font-normal'>
            Select month
          </span>
          <div className="my-auto ">
            <IoIosArrowDown />
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full flex-wrap gap-3 md:gap-0">

        {bookingConversion.map((item, index) => (
          <div
            key={index}
            className="rounded-[10px] border border-color-green w-full md:w-[270px] flex items-start p-4 justify-center flex-col"
          >
            <p className="font-extrabold text-2xl">{item?.value}</p>
            <p className="font-normal text-base">{item?.label}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full gap-10">
        <div className="md:w-[60%]">
          <div className="flex justify-between items-end pb-5">
            <p className="text-2xl font-medium">Notifications</p>
            <p className="underline text-base cursor-pointer font-normal">See All</p>
          </div>
          <div className=" rounded-xl border border-color-green">
            <div className="flex flex-col w-full p-5 gap-5  overflow-y-scroll h-[350px]">
              {notificationList.map((data, index) => {
                return (
                  <div className="flex items-center gap-5 relative border-b border-color-grey">
                    <img src={data?.image} className='h-[56px] w-[56px] rounded-full' alt="" />
                    <div className="flex flex-col pb-5">
                      <p className="font-medium text-xl">
                        {data?.name}
                      </p>
                      <p className="font-normal text-base text-color-darkgrey">
                        has request a booking on {data?.date}
                      </p>
                      <p className="font-normal text-base pt-2 text-color-grey">
                        {data?.timeAgo}
                      </p>

                    </div>
                    <span className='absolute top-0 right-0 text-base font-extrabold text-color-secondry'>{data?.new && "New"}</span>
                  </div>
                )
              })}
            </div>
            <div className="w-full flex justify-end items-center text-base font-medium px-8 py-5 border-t border-color-grey underline ">
              <span className="cursor-pointer">See All</span>
            </div>
          </div>

        </div>
        <div className=" md:w-[40%]">
          <div className="flex justify-start pb-5">
            <p className="text-2xl font-medium ">Hosting Progress</p>
          </div>

          <div className="w-full flex flex-col rounded-xl border border-color-green p-5">
            <div className="flex gap-3 items-end">
              <p className="font-extrabold text-2xl">{userData.points}</p>
              <p className="text-base text-color-gray font-medium">{userData.pointsLabel}</p>
            </div>
            <p className="font-normal text-base text-color-darkgrey">{userData.earningsLabel}</p>
            <div className="flex py-5 gap-5">
              <div className="flex flex-col px-2 border-l-8 border-black">
                <span className='font-extrabold text-xl'>{userData.paidOut}</span>
                <span className='font-normal text-base text-color-darkgrey'>Paid Out</span>
              </div>
              <div className="flex flex-col px-2 border-l-8 border-color-grey">
                <span className='font-extrabold text-xl'>{userData.expected}</span>
                <span className='font-normal text-base text-color-darkgrey'>Expected</span>
              </div>
            </div>
            <hr className='h-1 w-full border-t border-color-grey' />
            <div className="flex flex-col py-5 gap-2">
              <div className="flex justify-between text-base ">
                <p className="font-medium">September earnings</p>
                <span className='font-normal'>{userData.septemberEarnings}</span>
              </div>
              <div className="flex justify-between text-base">
                <p className="font-medium">30-day views</p>
                <span className='font-normal'>{userData.thirtyDayViews}</span>
              </div>
              <div className="flex justify-between text-base">
                <p className="font-medium">30-day bookings</p>
                <span className='font-normal'>{userData.thirtyDayBookings}</span>
              </div>
              <div className="flex justify-between text-base">
                <p className="font-medium">Overall rating</p>
                <span className='font-normal'>{userData.overallRating}</span>
              </div>
              <div className="flex justify-between text-base">
                <p className="font-medium">Total reviews</p>
                <span className='font-normal'>{userData.totalReviews}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:w-[58%]">
        <div className="flex justify-between items-center">
          <p className="font-medium text-lg md:text-2xl py-4">Bookings</p>
          <div className="h-[40px] cursor-pointer rounded-[50px]  border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2" >
            <span className='uppercase my-auto  pr-2 text-xs font-normal'>
              Select month
            </span>
            <div className="my-auto ">
              <IoIosArrowDown />
            </div>
          </div>
        </div>
        <div className="rounded-xl border border-color-green">
          <div className="flex flex-col    p-5 overflow-y-scroll h-[250px] gap-5">
            {notificationList.map((data, index) => {
              return (
                <div className="flex items-center justify-between gap-5 border-b border-color-grey">
                  <div className="flex flex-col pb-5">
                    <p className="font-medium text-xl">
                      {data?.name}
                    </p>
                    <p className="font-normal text-base text-color-darkgrey">
                      has request a booking on {data?.date}
                    </p>
                    <p className="font-normal text-base pt-2 text-color-grey">
                      {data?.timeAgo}
                    </p>

                  </div>
                  <img src={data?.image} className='h-[56px] w-[56px] rounded-full' alt="" />

                </div>
              )
            })}

          </div>
          <div className="w-full flex justify-end items-center text-base font-medium px-8 py-5 border-t border-color-grey underline ">
            <span className="cursor-pointer">See All</span>
          </div>
        </div>
      </div>

    </div>
  )
}

