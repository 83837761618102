// import React, { useEffect, useState } from "react";

// export default function SelectOption({ data, onSelect, spaceType, stateData }) {
//   // Initial state setup for different space types
//   const [spaceData, setSpaceData] = useState({
//     guests: 0,
//     bedrooms: 0,
//     bathrooms: 0,
//     // kitchen: 0,
//   });
//   const [privatedata, setPrivatedata] = useState({
//     guests: 0,
//     bedrooms: 0,
//     bathrooms: 0,
//   });
//   const [shared, setShared] = useState({
//     guests: 0,
//     bedrooms: 0,
//     bathrooms: 0,
//   });
//   const [userOption, setUserOption] = useState(null);

//   // Handler for selecting the type of space
//   const selectHandler = (value) => {
//     onSelect("spaceType", value);
//     let selectedData = {};
//     switch (value) {
//       case "entire":
//         selectedData = spaceData;
//         break;
//       case "private":
//         selectedData = privatedata;
//         break;
//       case "shared":
//         selectedData = shared;
//         break;
//       default:
//         selectedData = {};
//     }
//     onSelect("spaceTypeDetail", selectedData);
//     setUserOption(value);
//   };

//   useEffect(() => {
//     if (userOption) {
//       selectHandler(userOption);
//     }
//   }, [spaceData, privatedata, shared])

//   // Effect to initialize the component state from external stateData
//   useEffect(() => {
//     if (stateData?.spaceTypeDetail && stateData?.spaceType) {
//       setUserOption(stateData?.spaceType)
//       switch (stateData?.spaceType) {
//         case "entire":
//           setSpaceData(stateData?.spaceTypeDetail)
//           break;
//         case "private":
//           setPrivatedata(stateData?.spaceTypeDetail)
//           break;
//         case "shared":
//           setShared(stateData?.spaceTypeDetail)
//           break;
//         default:
//           return
//       }
//     }
//     if (userOption) {
//       selectHandler(userOption);
//     }
//   }, [])

//   // Handlers for incrementing and decrementing fields in spaceData
//   const handleIncrement = (field) => {
//     setSpaceData((prevData) => ({
//       ...prevData,
//       [field]: prevData[field] + 1,
//     }));
//   };

//   const handleDecrement = (field) => {
//     setSpaceData((prevData) => ({
//       ...prevData,
//       [field]: Math.max(prevData[field] - 1, 0),
//     }));
//   };

//   // Handlers for incrementing and decrementing fields in privatedata
//   const handleIncrementPrivate = (field) => {
//     setPrivatedata((prevData) => ({
//       ...prevData,
//       [field]: prevData[field] + 1,
//     }));
//   };

//   const handleDecrementPrivate = (field) => {
//     setPrivatedata((prevData) => ({
//       ...prevData,
//       [field]: Math.max(prevData[field] - 1, 0),
//     }));
//   };

//   // Handlers for incrementing and decrementing fields in shared data
//   const handleIncrementShared = (field) => {
//     setShared((prevData) => ({
//       ...prevData,
//       [field]: prevData[field] + 1,
//     }));
//   };

//   const handleDecrementShared = (field) => {
//     setShared((prevData) => ({
//       ...prevData,
//       [field]: Math.max(prevData[field] - 1, 0),
//     }));
//   };

//   return (
//     <div className="grid  gap-3 mx-3">
//       {data.map((item) => (
//         <div
//           key={item.value}
//           className={`h-auto px-10 py-5 flex flex-col items-center justify-center gap-4 md:w-full border border-color-green rounded-2xl ${item.value === userOption || spaceType === item.value
//             ? "bg-color-secondry"
//             : ""
//             }`}
//         >
//           <div className="flex w-full gap-5 cursor-pointer"
//             onClick={() => selectHandler(item.value)}
//           >
//             <div className="flex w-full gap-5 sm:flex-row flex-col">
//               <div
//                 className={`rounded-full flex  justify-center items-center w-[100px] h-[100px] sm:w-[66px] sm:h-[66px]  p-4 ${item.value === userOption || spaceType === item.value
//                   ? "bg-white"
//                   : "bg-color-secondry bg-opacity-10"
//                   }`}
//               >
//                 <img
//                   src={item.icon}
//                   alt=""
//                   className="w-full m-auto h-full object-contain  "
//                 />
//               </div>
//               <div className="">
//                 <p className="text-2xl font-medium"> {item.label}</p>
//                 <p
//                   className={`text-lg font-normal   ${item.value === userOption || spaceType === item.value
//                     ? "text-black"
//                     : "text-color-darkgrey"
//                     }`}
//                 >
//                   {" "}
//                   {item.description}.
//                 </p>
//               </div>
//             </div>
//           </div>

//           {item.value === "entire" && (
//             <div
//               className={` ${item.value === userOption || spaceType === item.value
//                 ? " block"
//                 : "hidden "
//                 } text-base font-normal w-full flex flex-col sm:flex-row gap-5 flex-wrap `}
//             >
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   GUESTS
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrement("guests")}
//                   >
//                     -
//                   </span>
//                   <span>{spaceData.guests}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrement("guests")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bedrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrement("bedrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{spaceData.bedrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrement("bedrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bathrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer "
//                     onClick={() => handleDecrement("bathrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{spaceData.bathrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrement("bathrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               {/* <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   Kitchen
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer "
//                     onClick={() => handleDecrement("kitchen")}
//                   >
//                     -
//                   </span>
//                   <span>{spaceData.kitchen}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrement("kitchen")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div> */}
//             </div>
//           )}
//           {item.value === "private" && (
//             <div
//               className={`${item.value === userOption || spaceType === item.value
//                 ? " block"
//                 : "hidden "
//                 } text-base font-normal w-full flex flex-col sm:flex-row gap-5 flex-wrap `}
//             >
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   GUESTS
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrementPrivate("guests")}
//                   >
//                     -
//                   </span>
//                   <span>{privatedata.guests}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementPrivate("guests")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bedrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrementPrivate("bedrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{privatedata.bedrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementPrivate("bedrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bathrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer "
//                     onClick={() => handleDecrementPrivate("bathrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{privatedata.bathrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementPrivate("bathrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//             </div>
//           )}
//           {item.value === "shared" && (
//             <div
//               className={` ${item.value === userOption || spaceType === item.value
//                 ? " block"
//                 : "hidden "
//                 } text-base font-normal w-full flex flex-col sm:flex-row gap-5 flex-wrap`}
//             >
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   GUESTS
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrementShared("guests")}
//                   >
//                     -
//                   </span>
//                   <span>{shared.guests}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementShared("guests")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bedrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleDecrementShared("bedrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{shared.bedrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementShared("bedrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//               <div className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey">
//                 <span className="text-color-grey w-[50%] text-sm uppercase">
//                   bathrooms
//                 </span>
//                 <div className="flex gap-5 items-center justify-evenly">
//                   <span
//                     className="cursor-pointer "
//                     onClick={() => handleDecrementShared("bathrooms")}
//                   >
//                     -
//                   </span>
//                   <span>{shared.bathrooms}</span>
//                   <span
//                     className="cursor-pointer"
//                     onClick={() => handleIncrementShared("bathrooms")}
//                   >
//                     +
//                   </span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

import React from "react";

export default function SelectOption({ data, onSelect, spaceType, spaceTypeDetail }) {
  const handleSelect = (value) => {
    onSelect("spaceType", value); // Update spaceType in Redux
    if (value !== spaceType) {
      const defaultDetails = { guests: 0, bedrooms: 0, bathrooms: 0 };
      onSelect("spaceTypeDetail", defaultDetails); // Reset spaceTypeDetail in Redux
    }
  };

  const handleIncrement = (field) => {
    const updatedDetails = {
      ...spaceTypeDetail,
      [field]: (spaceTypeDetail?.[field] || 0) + 1,
    };
    onSelect("spaceTypeDetail", updatedDetails);
  };

  const handleDecrement = (field) => {
    const updatedDetails = {
      ...spaceTypeDetail,
      [field]: Math.max((spaceTypeDetail?.[field] || 0) - 1, 0),
    };
    onSelect("spaceTypeDetail", updatedDetails);
  };

  return (
    <div className="grid gap-3 mx-3">
      {data.map((item) => (
        <div
          key={item.value}
          className={`h-auto px-10 py-5 flex flex-col items-center justify-center gap-4 md:w-full border border-color-green rounded-2xl ${
            item.value === spaceType ? "bg-color-secondry" : ""
          }`}
          onClick={() => handleSelect(item.value)}
        >
          <div className="flex w-full gap-5">
            <div className="flex w-full gap-5 sm:flex-row flex-col">
              <div
                className={`rounded-full flex justify-center items-center w-[100px] h-[100px] sm:w-[66px] sm:h-[66px] p-4 ${
                  item.value === spaceType ? "bg-white" : "bg-color-secondry bg-opacity-10"
                }`}
              >
                <img src={item.icon} alt={item.label} className="w-full m-auto h-full object-contain" />
              </div>
              <div>
                <p className="text-2xl font-medium">{item.label}</p>
                <p className={`text-lg font-normal ${item.value === spaceType ? "text-black" : "text-color-darkgrey"}`}>
                  {item.description}.
                </p>
              </div>
            </div>
          </div>

          {item.value === spaceType && (
            <div className="text-base font-normal w-full flex flex-col sm:flex-row gap-5 flex-wrap">
              {["guests", "bedrooms", "bathrooms"].map((field) => (
                <div
                  key={field}
                  className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey"
                >
                  <span className="text-color-grey w-[50%] text-sm uppercase">{field.toUpperCase()}</span>
                  <div className="flex gap-5 items-center justify-evenly">
                    <span className="cursor-pointer" onClick={() => handleDecrement(field)}>
                      -
                    </span>
                    <span>{spaceTypeDetail?.[field] || 0}</span>
                    <span className="cursor-pointer" onClick={() => handleIncrement(field)}>
                      +
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
