import { Container } from "@mui/material";
import ReviewComponent from "components/ReviewComponent/ReviewComponent";
import Footerbn from "pages/Footer/Footerbn";

const Review = () => {

  return (
    <>
      <Container>
        <ReviewComponent />
      </Container>
      <Footerbn />
    </>
  );
};

export default Review;
