import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/img/logo3.png";
import { GoHome } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import AdminSidebarSteps from "./AdminSidebarSteps";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import AdminNav from "pages/admin/Header/AdminNav";
import dashboardLogo from "../../assets/img/AdminSidebar Logos/dashboardLogo.png";
import managementLogo from "../../assets/img/AdminSidebar Logos/managementLogo.png";
import propertiesLogo from "../../assets/img/AdminSidebar Logos/propertiesLogo.png";
import referalLogo from "../../assets/img/AdminSidebar Logos/referalLogo.png";
import reservationLogo from "../../assets/img/AdminSidebar Logos/reservationLogo.png";
import reviewsLogo from "../../assets/img/AdminSidebar Logos/reviewsLogo.png";
import settingLogo from "../../assets/img/AdminSidebar Logos/settingLogo.png";
import ticketingLogo from "../../assets/img/AdminSidebar Logos/ticketingLogo.png";
import transactionLogo from "../../assets/img/AdminSidebar Logos/transactionLogo.png";
import permissionLogo from "../../assets/img/AdminSidebar Logos/permissionLogo.png";
import websiteLogo from "../../assets/img/AdminSidebar Logos/websiteLogo.png";
import { localStorageData, Logout } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";
import { ImUserPlus } from "react-icons/im";
import { getLocalUserdata } from "services/auth/localStorageData";
import { IoMdCloudUpload } from "react-icons/io";
import { RiArticleFill } from "react-icons/ri";

function SidebarAdmin() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [active, setActive] = useState(1);
  const [Dashboard, setDashboard] = useState([]);
  const [Management, setManagement] = useState([]);
  const [Properties, setProperties] = useState([]);
  const [Reservation, setReservation] = useState([]);
  const [Financial, setFinancial] = useState([]);
  const [Reviews, setReviews] = useState([]);
  const [Settings, setSettings] = useState([]);
  const [Supports, setSupports] = useState([]);
  const [Referral, setReferral] = useState();
  const [Refund, setRefund] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [showReservationItems, setShowReservationItems] = useState(false);
  const [showBlogsTabs, setShowBlogsTabs] = useState(false)
  const [showSettings, setshowSettings] = useState(false);
  const [collapseShow, setCollapseShow] = React.useState("hidden");


  const userData = getLocalUserdata();

  const roles = userData?.data?.role || [];
  const isAdmin = userData?.data?.isAdmin;

 
  const handleActive = (num) => {
    // setActive(num);
    setCollapseShow("hidden"); 
  };
  

  const handleToggle = (setter) => setter((prev) => !prev);

  // Helper function to check role permissions
  const hasRole = (requiredRoles) =>
    isAdmin || roles.some((role) => requiredRoles.includes(role));


  const routesMap = {
    "/admin/dashboard": 1,
    "/admin/dashboard/user-management": 2,
    "/admin/dashboard/properties": 3,
    "/admin/dashboard/reservation": 4,
    "/admin/dashboard/financial": 5,
    "/admin/dashboard/reviews": 6,
    "/admin/dashboard/referral-programs": 7,
    "/admin/dashboard/support-ticketing": 8,
    "/admin/dashboard/permissions": 9,
    "/admin/dashboard/settings": 10,
    "/admin/dashboard/blog-post": 11,
    "/admin/dashboard/refund-reservation": 12,
    "/admin/dashboard/all-posts": 13,
    "/admin/dashboard/add-user": 14,
  };

  const currentPath = location.pathname;
  const active = routesMap[currentPath] || 1;

  const navigateTo = (route) => {
    navigate(route);
    setCollapseShow("hidden"); 
  };



  
  return (
    <div className='flex flex-col lg:flex-row bg-[#f8f7fa]'>
      <div className='w-full lg:w-[330px] flex flex-col justify-between bg-[#01233F] lg:h-[100vh] h-auto  md:overflow-y-scroll overflow-auto '>
        <div>
          <nav className='md:left-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap shadow bg-[#01233F] flex flex-wrap items-center justify-between   lg:w-64 w-full z-10 py-4 '>
            <div className='lg:flex-col lg:items-stretch lg:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full '>
              {/* Toggler */}
              <button
                className='cursor-pointer text-white opacity-50 lg:hidden px-3 py-1 text-2xl leading-none rounded border border-solid border-transparent'
                type='button'
                onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
              >
                <i className='fas fa-bars'></i>
              </button>
              {/* Brand */}
              <Link
                className='md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4'
                to='/'
              >
                {/* Admin BnByond */}
                <img src={logo} alt='' className='' />
              </Link>
              {/* Collapse */}
              <div
                className={
                  'lg:flex lg:flex-col md:items-stretch bg-[#01233F] lg:bg-transparent opacity-100 lg:relative md:mt-4  shadow absolute top-0 left-0 right-0 z-40  overflow-x-hidden h-auto items-center flex-1 rounded ' +
                  collapseShow
                }
              >
                {/* Collapse header */}
                <div className='md:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
                  <div className='flex flex-wrap'>
                    <div className='w-6/12'>
                      <Link
                        className='md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0'
                        to='/'
                      >
                        BESCHDULED
                      </Link>
                    </div>
                    <div className='w-6/12 flex justify-end'>
                      <button
                        type='button'
                        className='cursor-pointer text-white opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                        onClick={() => setCollapseShow('hidden')}
                      >
                        <i className='fas fa-times'></i>
                      </button>
                    </div>
                  </div>
                </div>
                <ul className="text-white">
                  {/* Dashboard */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium ${hasRole(["Financial", "subadmin"])
                      ? "opacity-100 hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => hasRole(["Financial", "subadmin"]) && navigateTo('/admin/dashboard')}
                  >
                    <img
                      src={dashboardLogo}
                      alt="Dashboard"
                      className="mr-3 w-[25px]"
                    />
                    Dashboard
                  </li>

                  {/* Management */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium ${hasRole(["subadmin", "Financial"])
                      ? "opacity-100 hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => hasRole(["subadmin", "Financial"]) && navigateTo('/admin/dashboard/user-management')}
                  >
                    <img
                      src={managementLogo}
                      alt="Management"
                      className="mr-3 w-[25px]"
                    />
                    Management
                  </li>

                  
                  {/* Properties */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium ${hasRole(["Financial", "subadmin"])
                      ? "opacity-100 hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => hasRole(["Financial", "subadmin"]) && navigateTo("/admin/dashboard/properties")}
                  >
                    <img
                      src={propertiesLogo}
                      alt="Properties"
                      className="mr-3 w-[25px]"
                    />
                    Properties
                  </li>

                  {/* Reservation */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium  ${hasRole(["Financial", "subadmin"])
                      ? "opacity-100 hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => {
                      hasRole(["Financial", "subadmin"])
                        && handleToggle(setShowReservationItems)
                    }}
                  >
                    <img
                      src={reservationLogo}
                      alt="Reservation"
                      className="mr-3 w-[25px]"
                    />
                    Reservation
                    <span className="ml-auto">
                      {showReservationItems ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </span>
                  </li>

                  {showReservationItems && (
                    <ul >
                      <li
                        className="flex py-4 px-4 items-center font-medium hover:cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/reservation")}
                      >
                        <img
                          src={reservationLogo}
                          alt="Reservation Management"
                          className="mr-3 w-[20px] h-[25px] text-white"
                        />
                        Reservation Management
                      </li>
                      <li
                        className="flex py-4 px-4 items-center font-medium hover:cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/financial")}
                      >
                        <img
                          src={transactionLogo}
                          alt="Transactions"
                          className="mr-3 w-[20px]"
                        />
                        Financial Transactions
                      </li>
                      <li
                        className="flex py-4 px-4 items-center font-medium hover:cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/refund-reservation")}
                      >
                        <img
                          src={transactionLogo}
                          alt="refund"
                          className="mr-3 w-[20px]"
                        />
                        Refund
                      </li>
                    </ul>
                  )}

                  {/* Blog */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium ${hasRole(["Blogger", "subadmin"])
                      ? "opacity-100 hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => {
                      hasRole(["Blogger", "subadmin"])
                        && handleToggle(setShowBlogsTabs)
                    }}
                  >
                    <img
                      src={websiteLogo}
                      alt="Blog"
                      className="mr-3 w-[25px]"
                    />
                    Blog
                    <span className="ml-auto">
                      {showBlogsTabs ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </li>
                  {showBlogsTabs && (
                    <ul className="px-4">
                      <li
                        className="flex py-4 px-4 items-center font-medium cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/blog-post")}
                      >
                        <IoMdCloudUpload className="mr-3 text-xl" title="Upload Blog" />
                        Upload Blog
                      </li>
                      <li
                        className="flex py-4 px-4 items-center font-medium cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/all-posts")}
                      >
                        <RiArticleFill className="mr-3 text-xl" title="All Blogs" />
                        All Blogs
                      </li>
                    </ul>
                  )}

                  {/* Settings */}
                  <li
                    className={`flex py-4 px-4 items-center font-medium ${hasRole(["subadmin"])
                      ? "opacity-100  hover:cursor-pointer"
                      : "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={() => {
                      hasRole(["subadmin"])
                        && handleToggle(setshowSettings)
                    }}
                  >
                    <img
                      src={settingLogo}
                      alt="Settings"
                      className="mr-3 w-[25px]"
                    />
                    Website Settings
                    <span className="ml-auto">
                      {showSettings ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </li>
                  {showSettings && (
                    <ul>
                      <li className="flex px-4 py-4 items-center text-white font-Avenir text-base font-medium hover:cursor-pointer"   onClick={() => navigateTo("/admin/dashboard/referral-programs")}>
                        <img src={referalLogo} className='mr-3 w-[25px] h-[25px] text-white' />
                        Referral Programs
                      </li>
                      <li className="flex px-4 py-4 items-center text-white font-Avenir text-base font-medium hover:cursor-pointer" onClick={() => navigateTo("/admin/dashboard/support-ticketing")}>
                        <img src={ticketingLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                        Supports / Ticketing
                      </li>
                      <li className="flex px-4 py-4 items-center text-white font-Avenir text-base font-medium hover:cursor-pointer" onClick={() => navigateTo("/admin/dashboard/add-user")}>
                        {/* <img src={permissionLogo} className='mr-2 w-[25px] h-[25px] text-white ' /> */}
                        <ImUserPlus className='mr-2 w-[25px] h-[25px] text-gray-300' />
                        Add New user
                      </li>
                      <li
                        className="flex py-4 px-4 items-center font-medium hover:cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/permissions")}
                      >
                        <img
                          src={permissionLogo}
                          alt="Roles & Permissions"
                          className="mr-3 w-[20px]"
                        />
                        Roles & Permissions
                      </li>
                      <li
                        className="flex py-4 px-4 items-center font-medium hover:cursor-pointer"
                        onClick={() => navigateTo("/admin/dashboard/settings")}
                      >
                        <img
                          src={settingLogo}
                          alt="Settings"
                          className="mr-3 w-[20px]"
                        />
                        Settings
                      </li>
                    </ul>
                  )}

                </ul>

                {/* Logout */}
                <li
                  className="flex py-4 px-4 items-center font-medium text-red-600 hover:cursor-pointer"
                  onClick={() => {
                    Logout();
                    navigate("/admin/login");
                  }}
                >
                  <FaArrowLeft className="mr-3 w-[20px]" />
                  Logout
                </li>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="border w-full md:h-[100vh] h-auto md:overflow-y-scroll overflow-auto">
        <AdminNav />
        <AdminSidebarSteps
          active={active}
          Dashboard={Dashboard}
          Management={Management}
          PropertiesDatials={Properties}
          ReservationDatials={Reservation}
          Financial={Financial}
          Reviews={Reviews}
          Settings={Settings}
          Referral={Referral}
          Supports={Supports}
          Roles={Roles}
          Refund={Refund}
          blogPost={blogPost}
        />
      </div>
    </div>
  );
}

export default SidebarAdmin;