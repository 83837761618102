import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import plusIcon from "../../../assets/img/plusIcon.svg";
import { ButtonFilled } from "common/buttons/buttonFilled";
import { localStorageData } from "services/auth/localStorageData";
import { useMutation } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { Logout } from "services/auth/localStorageData";
import { useNavigate } from "react-router-dom";
import { storeLocalData } from "services/auth/localStorageData";
import { getLocalUserdata } from "services/auth/localStorageData";
import Modal from "../../Modal/Modal";

export const PersonalInfo = () => {
  let loginAs = ("localStorageData", localStorageData("loginAs"));
  const [frontViewImage, setFrontViewImage] = useState(null);
  const [backViewImage, setBackViewImage] = useState(null);
  const [openEmailModal, setEmailmodal] = useState(false);
  const [imageSrc, setImageSrc] = React.useState();
  const [profilePic, setProfilePic] = React.useState();
  const [userData, setUserData] = useState({});
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const navigate = useNavigate();

  // were defined but didn't called anywhere
  // const handleFrontViewImage = (image) => {
  //   setFrontViewImage(image);
  // };
  // const handleBackViewImage = (image) => {
  //   setBackViewImage(image);
  // };

  const [dropdownlogin, setDropdownlogin] = useState(false);
   const dropdownRef = useRef(null);
  const picTypeRef = useRef(null); 

  // Set initial front and back view images from local storage on component mount
  useEffect(() => {
    const initialFrontImage = localStorageData("governmentID.frontendPic");
    const initialBackImage = localStorageData("governmentID.backendPic");
    setFrontViewImage(initialFrontImage);
    setBackViewImage(initialBackImage);
  }, []);

  // Close the login dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownlogin(false);
    }
  };

  // Add and remove event listener by clicking outside of dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Validation schema for form
  const validationSchema = Yup.object({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.number().required("Phone Number is required"),
    address: Yup.string().required("Address is required"),
  });

  // Handling form submission
  const formik = useFormik({
    initialValues: {
      userId: localStorageData("_id"),
      fname: localStorageData("fname"),
      lname: localStorageData("lname"),
      email: localStorageData("email"),
      phoneNumber: localStorageData("phoneNumber"),
      address: localStorageData("address"),
      pic: localStorageData("pic"),
      // frontViewImage:localStorageData("governmentID.frontendPic"),
      // backViewImage:getLocalUserdata()?.governmentID?.backendPic
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("pics", profilePic);
      formData.append("userId", values.userId);
      formData.append("fname", values.fname);
      formData.append("lname", values.lname);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("address", values.address);
      if (frontViewImage)
        formData.append("governmentID.frontendPic", frontViewImage);
      if (backViewImage)
        formData.append("governmentID.backendPic", backViewImage);
      mutate(formData);
    },
  });
  let frontendPic = getLocalUserdata()?.governmentID?.frontendPic;
  let backendPic = getLocalUserdata()?.governmentID?.backendPic;

  // API call for updating image
  const { mutate: passMutate, isLoading2 } = useMutation(
    (token) => userServices.updateImage("/chat/upload-image", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log("data", data);
        console.log("picType", picTypeRef);
        if (picTypeRef.current == "front") {
          setFrontViewImage(data?.data?.fileUrls[0]);
        } else {
          setBackViewImage(data?.data?.fileUrls[0]);
        }
      },
    }
  );

  // API call for updating user info
  const { mutate, isLoading } = useMutation(
    (token) =>
      userServices.commonPostService("/userAuth/updateuserinfo", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data?.data?.data.isEnable == "no") {
          toast.success("your account deactivated successfully");
          Logout();
          navigate("/");
        } else {
          storeLocalData(data?.data.data);
          toast.success("updated successfully!");
        }
      },
    }
  );

  // Uploading picture to server
  const handleUploadPic = (pictureSource, pictureType) => {
    // setpicType(pictureType)
    picTypeRef.current = pictureType;
    const formData = new FormData();
    formData.append("fileUrl", pictureSource);
    passMutate(formData);
  };

  // Handling image file change and preview
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // const addRecovaryEmail = async () => {
  //   const userId = localStorageData("_id");
  //   // const apiUrl = `http://localhost:5000/userAuth/addRecoveryEmailById/${userId}`;
  //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailPattern.test(recoveryEmail)) {
  //     toast.error("Enter valid recovery email")
  //     return;
  //   }
  //   const data = { recoveryEmail };
  //   console.log(data);

  //   await axios
  //     .put(
  //       `http://localhost:5000/userAuth/addRecoveryEmailById/${userId}`,
  //       data
  //     )
  //     .then((res) => {
  //       console.log("====>>>>>", res.data);
  //       setEmailmodal(false);
  //       toast.success("Recovery email added successfully");
  //     })
  //     .catch((err) => {
  //       toast.error("This email already exist in other account");
  //       console.log(
  //         "this is recovery email error ===>>>",
  //         err.response.data.message
  //       );
  //     });
  // };
  

  const addRecoveryEmail = async (recoveryEmail) => {
  const userId = localStorageData("_id");

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(recoveryEmail)) {
      throw new Error("Enter valid recovery email");
    }

    const data = { recoveryEmail };
    return await userServices.recoveryEmail(`userAuth/addRecoveryEmailById/${userId}`, data);
  };

  const mutation = useMutation(addRecoveryEmail, {
    onSuccess: () => {
      setEmailmodal(false);
      toast.success("Recovery email added successfully");
    },
    onError: (error) => {
      toast.error( "This email already exists in another account" || error.message );

    },
  });

  const handleSubmit = () => {
    mutation.mutate(recoveryEmail);
  };
    
  const userdatabyId = async () => {
    try {
      const userId = localStorageData("_id");
      const apiUrl = `userAuth/userdatabyId/${userId}`;
      let res = await userServices.userDatagetById(apiUrl);
      let data = res.data.data;
      setUserData(data);
      storeLocalData(res?.data.data);

      if (data.recoveryEmail) {
        console.log("recovery email exists");
      } else {
        console.log("recovery email does not exist");
        setEmailmodal(true);
      }
    } catch (error) {
      console.error("Error fetching userdatabyid data", error);
    }
  };

  useEffect(() => {
    userdatabyId();
  }, []);

  return (
    <div className="flex flex-col gap-4 md:my-10 md:mx-20">
      <div className="w-full h-auto p-5 border rounded-2xl border-color-green">
        <div className="flex flex-col items-center justify-start px-5 py-10 space-y-6 bg-white md:px-none md:w-4/12">
          <img
            className="h-[187px] w-[187px] rounded-full object-cover"
            src={imageSrc ? imageSrc : localStorageData("pic")}
            alt="Profile"
          />
        </div>
        <Modal isOpen={openEmailModal}>
          <div className="w-full p-8 h-full flex flex-col justify-center">
            <label
              htmlFor="fname"
              className="block mb-2 text-xl font-medium text-gray-900 "
            >
              Recovery Email
            </label>
            <p className="text-gray-500 leading-none font-light mb-2 text-sm mt-4">
            Add a recovery email address, different from your registration email, to securely recover your account in case you lose access to your credentials.
            </p>
            <input
              type="text"
              id="recoveryEmail"
              name="recoveryEmail"
              onChange={(e) => {
                setRecoveryEmail(e.target.value);
              }}
              // onBlur={formik.handleBlur}
              value={recoveryEmail}
              className="bg-gray-50 border outline-none mb-2 border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
              placeholder="Recovery Email"
              required
            />
            {/* {formik.touched.fname && formik.errors.fname && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.fname}
                </div>
              )} */}

            <ButtonFilled
              text="Add Recovery Email"
              type="submit"
              onClick={handleSubmit}
            />
            <div className=" text-blue-600 mt-4 text-center">
             <span onClick={()=>{setEmailmodal(false)}} className="cursor-pointer">Remind me later</span>
            </div>
          </div>
        </Modal>
        <form onSubmit={formik.handleSubmit} className="max-w-4xl">
          <div className="flex w-full gap-5 mb-5">
            <div className="w-full">
              <label
                htmlFor="fname"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                First Name
              </label>
              <input
                type="text"
                id="fname"
                name="fname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fname}
                className="bg-gray-50 border outline-none  border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
                placeholder="First Name"
                required
              />
              {formik.touched.fname && formik.errors.fname && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.fname}
                </div>
              )}
            </div>
            <div className="w-full">
              <label
                htmlFor="lname"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Last Name
              </label>
              <input
                type="text"
                id="lname"
                name="lname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lname}
                className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
                placeholder="Last Name"
                required
              />
              {formik.touched.lname && formik.errors.lname && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.lname}
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full gap-5 mb-5">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
                placeholder="Email"
                required
              />
              {formik.touched.email && formik.errors.email && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div className="w-full">
              <label
                htmlFor="number"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Phone Number
              </label>
              <input
                type="number"
                id="number"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
                placeholder="Phone Number"
                required
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.phoneNumber}
                </div>
              )}
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
              placeholder="Address"
              required
            />
            {formik.touched.address && formik.errors.address && (
              <div className="mt-1 text-sm text-red-500">
                {formik.errors.address}
              </div>
            )}
          </div>
          {loginAs === "Host" && (
            <div className="">
              <div>
                {/* <LanguageDropdown
                  setDropdownlang={setDropdownlang}
                  dropdownlang={dropdownlang}
                  selectlang={selectlang}
                  setSelectlang={setSelectlang}
                /> */}
              </div>

              {/* <div>
                <h2 className="font-semibold text-lg mt-2">
                  Verification Document Type:
                </h2>
                <p className="text-sm">Need a Document Verification? Here's How!</p>
                <div
                  ref={dropdownRef}
                  className="relative  border border-slate-300 mb-2 p-2 rounded-md w-[280px] "
                >
                  <div
                    onClick={() => setDropdownlogin(!dropdownlogin)}
                    className=" flex justify-between items-center cursor-pointer"
                  >
                    <p className="text-color-darkgrey/50">
                      {" "}
                      {selectMember ? selectMember : "Selection an option"}
                    </p>

                    <span>
                      <RiArrowDropDownFill className="text-2xl" />
                    </span>
                  </div>
                  {dropdownlogin && (
                    <div className="absolute  top-[100%] w-full bg-white left-0 border border-color-grey">
                      <label
                        onClick={() => setSelectMember("Passport")}
                        className="flex items-center gap-2  p-2 border-b border-b-color-grey hover:bg-color-yellow/20"
                        for="passport-radio"
                      >
                        <input type="radio" name="user-type" id="passport-radio" />
                        <p className="text-color-darkgrey/80"> Passport</p>
                      </label>
                      <label
                        onClick={() => setSelectMember("Id Card")}
                        className="flex items-center gap-2  p-2 border-b border-b-color-grey hover:bg-color-yellow/20"
                        for="guest-radio"
                      >
                        <input type="radio" name="user-type" id="guest-radio" />
                        <p className="text-color-darkgrey/80"> State ID/Govt. ID</p>
                      </label>
                      <label
                        onClick={() => setSelectMember("Driving License")}
                        className="flex items-center gap-2 p-2 hover:bg-color-yellow/20"
                        for="host-radio"
                      >
                        <input type="radio" name="user-type" id="host-radio" />
                        <p className="text-color-darkgrey/80"> Driving License</p>
                      </label>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          )}
          <div className="flex flex-col gap-4 py-5">
            <p className="text-lg font-medium ">Government ID Card</p>
            <p className=" text-base text-[#4B4B4B] font-normal">
              Need a REAL ID? Here's How!
            </p>
            <div className="flex gap-5">
              <ButtonBorderDashed
                name="Front View"
                icon={plusIcon}
                onImageChange={(image) => handleUploadPic(image, "front")}
              />
              <ButtonBorderDashed
                name="Back View"
                icon={plusIcon}
                onImageChange={(image) => handleUploadPic(image, "back")}
              />
            </div>
            <div className="w-full md:w-[20%]  pt-4">
              <ButtonFilled text="Save" type="submit" />
            </div>
          </div>
        </form>
        <div className="items-center gap-2 md:flex">
          {(frontViewImage || frontendPic) && (
            <div className="mt-5">
              <p className="text-lg font-medium">ID Front Side</p>
              <img
                className="mt-2 h-[180px] rounded-xl w-[300px] object-cover"
                src={frontViewImage ? frontViewImage : frontendPic}
                alt="Front View"
              />
            </div>
          )}

          {(backViewImage || backendPic) && (
            <div className="mt-5">
              <p className="text-lg font-medium">ID Back Side</p>
              <img
                className="mt-2 h-[180px] rounded-xl w-[300px] object-cover"
                src={backViewImage ? backViewImage : backendPic}
                alt="Back View"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ButtonBorderDashed = ({ icon, name, onImageChange }) => {
  const inputRef = useRef(null);
  const handleButtonClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onImageChange(file);
    }
  };
  return (
    <div
      className="flex cursor-pointer text-sm md:text-lg w-[180px] items-center capitalize justify-center px-4 py-2 h-[50px] bg-[#F9F9F9] border-2 border-dashed text-[#646464]  border-[#C1E1C2] gap-8  font-medium rounded-2xl"
      onClick={handleButtonClick} // Make the entire div clickable
    >
      <p>{name}</p>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleImageChange}
      />
      <img src={icon} alt="" className="text-black" />
    </div>
  );
};
