const CharacteristicsData = [
  {
    id: 0,
    value: 'luxurious',
    label: 'Luxurious',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 1,
    value: 'modern',
    label: 'Modern',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 2,
    value: 'spacious',
    label: 'Spacious',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 3,
    value: 'chill',
    label: 'Chill',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 4,
    value: 'central',
    label: 'Central',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 5,
    value: 'historic',
    label: 'Historic',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 6,
    value: 'designer',
    label: 'Designer',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 7,
    value: 'private',
    label: 'Private',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 8,
    value: 'quiet',
    label: 'Quiet',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 9,
    value: 'cozy',
    label: 'Cozy',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 10,
    value: 'scenic',
    label: 'Scenic',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
  {
    id: 11,
    value: 'ecofriendly',
    label: 'Eco-Friendly',
    status: false,
    img: require('assets/img/Fireplace.png'),
  },
];

export { CharacteristicsData };
