import React, { useEffect, useState } from "react";
import userServices from "services/httpService/userAuth/userServices";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { localStorageData } from "services/auth/localStorageData";
import PopupModal from "components/PopupModal/PopupModal";
import Modal from "components/Modal/Modal";
import Pagination from "components/Pagination/Pagination";
import Rating from "react-rating";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import ErrorService from "services/formatError/ErrorService";

const RefundReservation = () => {
  const [reservationData, setReservationData] = useState([]);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [openAlert, setOpenAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState("");
  const [actionType, setActionType] = useState("");
  const [refundMessage, setRefundMessage] = useState("");

  // Fetch reservations with a refund request
  const getReservations = async () => {
    setLoading(true); // Ensure loading is set at the start
    try {
      const res = await userServices.commonGetService(
        `/reservation/reservations/${localStorageData("_id")}?role=admin`
      );
      if (res.data && Array.isArray(res.data)) {
        const filteredReservations = res.data.filter(
          (reservation) => reservation.isRefundRequest === true
        );
        setReservationData(filteredReservations);
      }
    } catch (error) {
      toast.error("Error fetching reservation data.");
    } finally {
      setLoading(false); // Always set loading to false after the operation
    }
  };

  useEffect(() => {
    getReservations();
  }, []);

  const refundApproval = async () => {
    setBtnLoader(true);
    try {
      const refundStripePayment = `/stripe/refundpayment`;
      await userServices.stripeRefund(refundStripePayment, {
        paymentIntentId: paymentIntentId,
        amount: serviceFee,
      });

      const refundPoints = `/reservation/refundApproval/${selectedReservationId}`;
      await userServices.refundReservation(refundPoints, {
        status: actionType,
      });

      toast.success(
        `Reservation successfully ${actionType === "approved" ? "refunded" : "rejected"}`
      );
      setReservationData((prev) =>
        prev.filter((res) => res._id !== selectedReservationId)
      );
    } catch (error) {
      toast.error(ErrorService.uniformError(error?.response?.data?.error));
    } finally {
      setBtnLoader(false);
    }
  };

  const handleRefund = (reservationId, action, paymentIntentId, serviceFee) => {
    setSelectedReservationId(reservationId);
    setActionType(action);
    setOpenAlert(true);
    setPaymentIntentId(paymentIntentId);
    setServiceFee(Math.floor(serviceFee * 0.8));
  };

  const handleCancelModal = () => {
    setOpenAlert(false);
  };

  const handleAcceptModal = () => {
    setOpenAlert(false);
    refundApproval();
  };

  const sortedData = (data) => {
    return [...data].reverse();
  };

  const totalPages = Math.ceil(reservationData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    reservationData.length > 0
      ? sortedData(reservationData).slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleView = (message) => {
    setRefundMessage(message);
    setIsOpen(true);
  };

  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {openAlert && (
        <PopupModal
          handleCancle={handleCancelModal}
          handleAccept={handleAcceptModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description={`Are you sure you want to ${actionType} the refund request? This action cannot be undone.`}
          title={`Confirm ${actionType === "approved" ? "Refund" : "Rejection"}`}
          acceptbtnText="Confirm"
        />
      )}

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1 className="text-2xl font-bold text-center p-6">Refund Reason</h1>
        <div className="px-10 text-xl italic text-center">{refundMessage}</div>
      </Modal>

      <div className="overflow-x-auto">
        {reservationData.length === 0 ? (
          <div className="flex justify-center items-center h-40">
            <p className="text-xl text-gray-600">No refund reservations found.</p>
          </div>
        ) : (
          <>
          {/* Added heading here */}
        <h2 className="text-2xl font-bold my-4 px-4">
          Refunded Reservations
        </h2>
            <table className="table-auto border-collapse w-full bg-white rounded-xl">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Refunded Reservations</th>
                  <th className="px-4 py-2 text-left">Location</th>
                  <th className="px-4 py-2 text-left">Points</th>
                  <th className="px-4 py-2 text-left">Message</th>
                  <th className="px-4 py-2 text-left">Refund Status</th>
                  <th className="px-4 py-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((data, index) => {
                  const averageRating = calculateAverageRating(
                    data.property_id?.reviews || []
                  );
                  const isActionDisabled =
                    data.isRefundStatus === "refunded" ||
                    data.isRefundStatus === "rejected";

                  return (
                    <tr key={index} className="border-t">
                      <td className="px-4 py-2 text-left">{data?.property_id?.title}</td>
                      <td className="px-4 py-2 text-left">{data?.property_id?.address}</td>
                      <td className="px-4 py-2 text-left">{data?.offerState?.points} Points</td>
                      <td className="px-4 py-2 text-left">
                        <button
                          className="text-blue-500"
                          onClick={() => handleView(data.refundReason)}
                        >
                          View Message
                        </button>
                      </td>
                      <td className="px-4 py-2 text-left">{data.isRefundStatus}</td>
                      <td className="px-4 py-2 text-left">
                        <button
                          className={`px-4 py-2 m-1 rounded ${isActionDisabled || btnLoader
                              ? "bg-gray-400"
                              : "bg-green-500 text-white"
                            }`}
                          onClick={() =>
                            handleRefund(
                              data._id,
                              "approved",
                              data.paymentIntentId,
                              data.offerState.serviceFee
                            )
                          }
                          disabled={isActionDisabled || btnLoader}
                        >
                          Refund
                        </button>
                        <button
                          className={`px-4 py-2 m-1 rounded ${isActionDisabled || btnLoader
                              ? "bg-gray-400"
                              : "bg-red-500 text-white"
                            }`}
                          onClick={() =>
                            handleRefund(
                              data._id,
                              "rejected",
                              data.paymentIntentId,
                              data.offerState.serviceFee
                            )
                          }
                          disabled={isActionDisabled || btnLoader}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {reservationData.length > itemsPerPage && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RefundReservation;
