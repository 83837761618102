import ReactApexChart from "react-apexcharts";

export function GraphComponent({ params }) {
    return (
        <div
            className={`ExpendedCard rounded-lg  ${params.color.background} ${params.color.textColor}`}
            style={{
                boxShadow: params.color.boxShadow,
            }}
            layoutId="expandableCard"
        >
            <div className="chartContainer">
                <ReactApexChart
                    options={params.options}
                    series={params.series}
                    type="area"
                    height={params.options.chart.height}
                />
            </div>
        </div>
    );
}