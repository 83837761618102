import { ButtonFilled } from "common/buttons/buttonFilled";
import PopupModal from "components/PopupModal/PopupModal";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { localStorageData } from "services/auth/localStorageData";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import { modalText } from "common/commonText/ModalText";
import { useNavigate } from "react-router-dom";
import { Logout } from "services/auth/localStorageData";
import { storeLocalData } from "services/auth/localStorageData";
import { FaSpinner } from "react-icons/fa";

export const ChangePassword = () => {
  const [updatePass, setUpdatePass] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [userConnect, setUserConnect] = useState(false);
  const [userData, SetUserData] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);

  let navigate = useNavigate();

  //password update API call
  const { mutate: passMutate, isLoading: loading } = useMutation(
    (token) =>
      userServices.commonPostService(
        "/userAuth/updatepasswordFromProfile",
        token
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        storeLocalData(data?.data.data);
        toast.success("updated successfully!");
      },
    }
  );
  const userId = localStorageData("_id").trim();

  // Google account verification API call
  const { mutate: setMutate, isLoading: loadingg } = useMutation(

    (token) =>

      userServices.googleVerification(
        `/userAuth/gmailVerification/${userId}`,
        token

      ),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setUserConnect(true)
        storeLocalData(data?.data.data);
        toast.success("password updated successfully!");
      },
    }
  );

  //fetching user data by ID
  const userdatabyId = async () => {
    try {
      const userId = localStorageData("_id");
      const apiUrl = `userAuth/userdatabyId/${userId}`;
      let res = await userServices.userDatagetById(apiUrl);
      SetUserData(res?.data.data)
      storeLocalData(res?.data.data);
    } catch (error) {
      console.error("Error fetching userdatabyid data", error);
    }
  };

  useEffect(() => {
    userdatabyId();
  }, [userConnect]);

  //handling account deactivation
  const { mutate, isLoading } = useMutation(
    (token) =>
      userServices.commonPostService("/userAuth/updateuserinfo", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data?.data?.data.isEnable == "no") {
          setOpenAlert(false);
          toast.success("your account deactivated successfully");
          Logout();
          navigate("/");
        }
      },
    }
  );

  //handling password update
  const handlePassUpdate = () => {
    if (updatePass.new_password.length < 8) {
      return toast.error("New password must be at least 8 characters long");
    } else if (updatePass.new_password !== updatePass.confirm_password) {
      return toast.error("New password and confirm password do not match");
    } else if (!updatePass.current_password) {
      return toast.error("Please enter the current password");
    } else {
      passMutate({
        oldPass: updatePass.current_password,
        newPass: updatePass.new_password,
        userId: localStorageData("_id"),
      });
    }
  };

  //handling changes in password update form fields
  const handleChangePass = (event) => {
    setUpdatePass((prevUpdatePass) => ({
      ...prevUpdatePass,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDeativateAccount = () => {
    setOpenAlert(true);
  };
  const handleAcceptModal = () => {
    mutate({ isEnable: "no", userId: localStorageData("_id") });
  };
  const handleCancleModal = () => {
    setOpenAlert(false);
  };
  const handleGoogleVerification = () => {
    setMutate()
  }
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {openAlert && (

        <PopupModal
          handleCancle={handleCancleModal}
          handleAccept={handleAcceptModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description={modalText.deactivationAccount}
          title={modalText.deactivationTitle}
          acceptbtnText={modalText.deactivate}
        />
      )}
      <p className="text-2xl pt-10 pb-5 font-medium">Change your password</p>
      <div className="max-w-4xl">
        <div className="mb-5 flex w-full lg:w-[49%] gap-5 ">
          <div className="w-full">
            <label
              for="currentPassword"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              name="current_password"
              onChange={handleChangePass}
              value={updatePass.current_password}
              className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
              placeholder="name@example.com"
              required
            />
          </div>
        </div>
        <div className="mb-5 flex flex-col md:flex-row w-full gap-5">
          <div className="w-full">
            <label
              for="newPassword"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              name="new_password"
              onChange={handleChangePass}
              value={updatePass.new_password}
              className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
              placeholder="name@example.com"
              required
            />
          </div>
          <div className="w-full">
            <label
              for="confirmNewPassword"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirm_password"
              onChange={handleChangePass}
              value={updatePass.confirm_password}
              className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
              placeholder="name@example.com"
              required
            />
          </div>
        </div>
        <div className="mb-5 flex gap-5 ">
          <div className="">
            <ButtonFilled
              text={
                loading ? (
                  <div className="flex items-center">
                    <FaSpinner className="pr-2 h-5 w-5 " />
                    <span>Updating...</span>
                  </div>
                ) : (
                  "Update Password"
                )
              } onClick={handlePassUpdate} disabled={loading} />
          </div>
        </div>
      </div>
      <div className="">
        <p className="text-2xl font-medium">Security</p>
        <div className="flex justify-between items-center w-full pt-10 pb-2 border-b-2 ">
          <div className="flex flex-col justify-between gap-2">
            <p className="text-lg font-medium">Google</p>
            <p className="text-lg font-normal text-[#4B4B4B]">
              {userData?.verify === "yes"
                ? "Connected"
                : "Disconnected"}{" "}
            </p>
          </div>

          <button
            className="text-lg underline cursor-pointer"
            onClick={handleOpenModal}
          >
            {userData?.verify === 'no' ? 'Connect' : <p className="text-red-500">Disconnect</p>}
          </button>
          <PopupModal
            handleCancle={handleCloseModal}
            handleAccept={() => {
              handleGoogleVerification();
              if (userData?.verify === 'no') {
                toast.success("verification email has been sent to you!");
              }
              else {
                toast.success("Account has been desconnected!");
              }

              setIsModalOpen(false);
            }}
            openAlert={isModalOpen}
            setOpenAlert={setIsModalOpen}
            description={userData?.verify === 'no' ? 'Do you want to connect the account?' : 'Do you want to disconnect the account?'}
            title={userData?.verify === 'no' ? 'Connect' : 'Disconnect'}
            acceptbtnText={userData?.verify === 'no' ? 'Connect' : 'Disconnect'}
          />
        </div>

        {/* <div className="flex justify-between items-center w-full pt-10 pb-2 border-b-2 ">
          <div className="flex flex-col justify-between gap-2">
            <p className="text-lg font-medium">Session</p>
            <p className="text-lg font-normal text-[#4B4B4B]">
              September 12,2023 at 23:25
            </p>
          </div>
          <p className="text-lg underline cursor-pointer">logout device</p>
        </div>
        <div className="flex justify-between items-center w-full pt-10 pb-2 border-b-2 ">
          <div className="flex flex-col justify-between gap-2">
            <p className="text-lg font-medium">Session</p>
            <p className="text-lg font-normal text-[#4B4B4B]">
              September 12,2023 at 23:25
            </p>
          </div>
          <p className="text-lg underline  cursor-pointer">logout device</p>
        </div> */}
        <div className="flex justify-between items-center w-full pt-10 pb-2 border-b-2 ">
          <div className="flex flex-col justify-between gap-2">
            <p className="text-lg font-medium">Account Deactivation</p>
            <p className="text-lg font-normal  text-[#FF2D2D]">
              Deactivate your account
            </p>
          </div>
          <p
            className="text-lg underline  cursor-pointer text-[#FF2D2D]"
            onClick={() => handleDeativateAccount()}
          >
            Deactivate
          </p>
        </div>
      </div>
    </div>
  );
};
