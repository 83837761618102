// import { CircularProgress } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import { Container } from "@mui/material";
// import icon3 from "assets/img/filter.png"
// import icon4 from "assets/img/searchbar.png"
// import icon5 from "assets/img/export.png"
// import Pagination from 'components/Pagination/Pagination';
// import ReactHtmlTableToExcel from 'react-html-table-to-excel';
// import Modal from 'components/Modal/Modal';
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { RxCrossCircled } from 'react-icons/rx';
// import { toast } from 'react-toastify';

// const ReservationManagement = ({ reservation, setStepsAndDetail, setSteps }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10);
//   const [search, setSearch] = useState('');
//   const [isAllSelected, setIsAllSelected] = useState(false);
//   const [selectedReservations, setSelectedReservations] = useState([]);
//   const [filtered, setFiltered] = useState([]);
//   const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
//   const [checkIn, setCheckIn] = useState(null);
//   const [checkOut, setCheckOut] = useState(null);
//   const [statusFilter, setStatusFilter] = useState('');
//   const [filtersApplied, setFiltersApplied] = useState(false);
//   const [loading, setLoading] = useState(true);

//   // Initialize filtered data and maintain state
//   useEffect(() => {
//     const initialData = reservation.map(res => ({ ...res, isSelected: false }));
//     setFiltered(initialData);
//   }, [reservation]);

//   useEffect(() => {
//     if (reservation.length > 0) {
//       setLoading(false);
//     }
//   }, [reservation]);

//   // Updates search input value and resets the current page
//   const handleInputChange = (e) => {
//     setSearch(e.target.value);
//     setCurrentPage(1);
//   };

//   const handleFilterButtonClick = () => {
//     setIsFilterModalOpen(true);
//   }

//   const handleCheckInChange = (newValue) => {
//     setCheckIn(newValue);
//   };
//   const handleCheckOutChange = (newValue) => {
//     setCheckOut(newValue);
//   };

//   // Applies filters to the reservation data based on check-in, check-out, and status, and updates the state
//   const applyFilters = () => {
//     let filteredData = reservation;

//     if (checkIn && checkOut) {
//       filteredData = filteredData.filter(item => {
//         const checkInDate = new Date(item.offerState.checkIn);
//         return checkInDate >= checkIn && checkInDate <= checkOut;
//       });
//     }

//     if (statusFilter) {
//       filteredData = filteredData.filter(item => item.status === statusFilter);
//     }

//     setFiltered(filteredData);
//     setIsFilterModalOpen(false);
//     setFiltersApplied(true);
//     setCurrentPage(1);
//   };

//   //resetting all filters to show all reservation
//   const resetFilters = () => {
//     setCheckIn(null);
//     setCheckOut(null);
//     setStatusFilter('');
//     setFiltersApplied(false);
//     setFiltered(reservation);
//     setCurrentPage(1);
//   };


//   const options = {
//     hour: '2-digit',
//     minute: '2-digit',
//     timeZone: 'UTC'
//   };

//   // Filters data based on search input for user's first and last name
//   const filteredData = filtered.filter((item) =>
//     (item.userId?.fname + " " + item.userId?.lname).toLowerCase().includes(search.toLowerCase())
//   );

//   // Sorts reservation data that new reservation added to the top of the list
//   const sortedData = (reservation) => {
//     return [...reservation].reverse();
//   };

//   //pages calculation for pagination
//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = sortedData(filtered).length > 0 && sortedData(filtered).slice(indexOfFirstItem, indexOfLastItem);

//   // const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

//   const onPageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Toggles the selection of all reservations and updates the selected reservations list
//   const handleSelectAll = () => {
//     const newIsAllSelected = !isAllSelected;
//     setIsAllSelected(newIsAllSelected);

//     const updatedData = filtered.map(res => ({ ...res, isSelected: newIsAllSelected }));
//     setFiltered(updatedData);

//     if (newIsAllSelected) {
//       setSelectedReservations(updatedData.map(res => res._id));
//     } else {
//       setSelectedReservations([]);
//     }
//   };

//   // Toggles the selection of a single reservation and updates the selected reservations list
//   const handleCheckboxChange = (reservationId) => {
//     const updatedData = filtered.map(res => {
//       if (res._id === reservationId) {
//         return { ...res, isSelected: !res.isSelected };
//       }
//       return res;
//     });

//     const updatedSelectedReservations = updatedData.filter(res => res.isSelected).map(res => res._id);
//     setFiltered(updatedData);
//     setSelectedReservations(updatedSelectedReservations);
//     setIsAllSelected(updatedSelectedReservations.length === filtered.length);
//   };

//   // Updates the select all checkbox state based on the selected reservations and filtered data
//   useEffect(() => {
//     setIsAllSelected(selectedReservations.length === filtered.length && filtered.length > 0);
//   }, [selectedReservations, filtered]);

//   // Handles the export button click and triggers the export if any reservations are selected
//   const handleExportClick = () => {
//     if (selectedReservations.length === 0) {
//       toast.warning("Please select reservation to export");
//       return;
//     }

//     document.getElementById("test-table-xls-button").click();
//   };


//   return (
//     <>
//       <section className="bg-[#f8f7fa]">
//         <Container maxWidth="xl">
//           <div className="my-4 flex md:flex-row flex-col justify-between md:pt-7 ">
//             <div className="">
//               <h1 className="text-2xl font-extrabold ">Reservation Management</h1>

//             </div>
//             {/* <div className="flex gap-2 pt-3 md:pt-0">
//               <Dropdown />
//               <button className="bg-[#2459BF] w-130 rounded-full w-[120px] h-[40] text-color-cream" >+ ADD NEW</button>
//             </div> */}
//           </div>
//           {/* <TopCard /> */}
//           <div className="flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white">
//             <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
//               <div className=''>
//                 <p className='font-medium sm:text-lg text-sm'>Reservations</p>
//               </div>
//               <div className='flex  space-x-2'>
//                 <div onClick={handleFilterButtonClick} className='flex justify-between sm:p-4 p-2 items-center cursor-pointer  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
//                   <div>
//                     <img src={icon3} />
//                   </div>
//                   <div>
//                     <p className='font-normal text-xs text-gray-600'>Fillter</p>
//                   </div>
//                 </div>

//                 <div className='flex items-center justify-center'>
//                   {filtersApplied && (
//                     <button onClick={resetFilters}>
//                       <RxCrossCircled />
//                     </button>
//                   )}
//                 </div>

//                 <button
//                   onClick={handleExportClick}
//                   className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
//                 >
//                   <div className='w-4 h-4'>
//                     <img src={icon5} alt="Export Icon" className='w-full h-full' />
//                   </div>
//                   <div className='ml-2 sm:ml-0'>
//                     <p className='font-normal text-xs text-gray-600'>Export</p>
//                   </div>
//                 </button>

//                 <ReactHtmlTableToExcel
//                   id="test-table-xls-button"
//                   className="hidden"
//                   table="selected-users-table"
//                   filename="tablexls"
//                   sheet="tablexls"
//                   buttonText="Export"
//                 />

//                 <div className="relative md:w-64  ">
//                   <input
//                     type="text"
//                     value={search}
//                     onChange={handleInputChange}
//                     placeholder='Search'
//                     className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
//                   />
//                   <img
//                     src={icon4}
//                     alt="search icon"
//                     className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
//                   />
//                 </div>
//               </div>
//             </div>


//             <div className='overflow-auto'>
//               <table className="table-auto border-collapse w-full bg-white ">
//                 <thead>
//                   <tr className='bg-[#fafbff]'>
//                     <th className="px-4 py-2 border-t border-b text-left">
//                       <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} className="mr-2" />
//                       Guests
//                     </th>
//                     <th className="px-4 py-2 border-t text-left border-b">Res. code</th>
//                     <th className="px-4 py-2 border-t text-left border-b">Check-in</th>
//                     <th className="px-4 py-2 border-t text-left border-b">check-out</th>
//                     <th className="px-4 py-2 border-t text-left border-b">points</th>
//                     {/* <th className="px-4 py-2 border-t  text-left border-b">Guests</th> */}
//                     {/* <th className="px-4 py-2 border-t text-left border-b">Amount</th> */}
//                     {/* <th className="px-4 py-2 border-t text-left border-b">Payment</th> */}
//                     <th className="px-4 py-2 border-t text-left border-b">Status</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {/* {currentItems.length <= 0 ? (
//                     <tr>
//                       <td colSpan='6' className="text-center py-10">
//                         <CircularProgress />
//                       </td>
//                     </tr>
//                   ) :  */}

//                   {loading ? (
//                     <tr>
//                       <td colSpan="6" className="text-center py-10">
//                         <CircularProgress />
//                       </td>
//                     </tr>
//                   ) : currentItems.length === 0 ? (
//                     <tr>
//                       <td colSpan="6" className="text-center py-10">
//                         No reservations found.
//                       </td>
//                     </tr>
//                   ) :
//                     (
//                       currentItems.map((item, index) => {
//                         const checkInDate = new Date(item.offerState.checkIn);
//                         const checkOutDate = new Date(item.offerState.checkOut);
//                         const reservationDate = new Date(item.timeStamp);

//                         const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
//                         const formattedCheckInTime = checkInDate.toLocaleTimeString('en-US', options);

//                         const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
//                         const formattedCheckOutTime = checkOutDate.toLocaleTimeString('en-US', options);

//                         const formattedreservationDate = reservationDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
//                         const formattedreservationTime = reservationDate.toLocaleTimeString('en-US', options);

//                         console.warn("Check Time options", formattedCheckInTime, formattedCheckOutTime)
//                         return (
//                           <tr key={index} className='border-b'  >
//                             <td className="px-4 py-2">
//                               <div className="flex items-center">
//                                 <input
//                                   type="checkbox"
//                                   checked={isAllSelected || item.isSelected}
//                                   onChange={() => handleCheckboxChange(item._id)}
//                                   className="mr-2"
//                                 />
//                                 <img src={item.userId?.pic} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
//                                 <div className='flex flex-col'>
//                                   <span onClick={() => setStepsAndDetail(item._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{item.userId?.fname + " " + item.userId?.lname}</span>
//                                   <span className="font-medium text-xs leading-4 text-gray-400">{item.userId?.email}</span>

//                                 </div>
//                               </div>

//                             </td>


//                             <td className="px-4 py-2 font-medium text-sm leading-5 text-black">{item?._id}</td>
//                             <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                               <div className='flex flex-col'>
//                                 <span>{formattedCheckInDate}</span>
//                                 <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckInTime}</span>
//                               </div>
//                             </td>
//                             <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                               <div className='flex flex-col'>
//                                 <span>{formattedCheckOutDate}</span>
//                                 <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckOutTime}</span>
//                               </div>
//                             </td>
//                             {/* <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                             <div className="flex items-center">
//                               <div className='flex flex-col'>
//                                 <span className="font-medium text-sm leading-5 text-black">Home{index + 1}</span>
//                                 <span className="font-medium text-xs leading-4 text-gray-400">Room No. 0024</span>
  
//                               </div>
//                             </div>
//                           </td> */}
//                             {/* <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                             <div className="flex items-center gap-4">
//                               <div className="flex items-center justify-center">
//                                 <CiUser className='w-[20px] h-[20px] text-[#AFB5C1]' />
//                                 <span className="text-sm ml-1">{item.property_id?.spaceTypeDetail.guests}</span>
//                               </div>
//                               <div className="flex items-center justify-center">
//                                 <PiHouse className='w-[20px] h-[20px] text-[#AFB5C1]' />
//                                 <span className="text-sm ml-1">{item.property_id?.spaceTypeDetail.bedrooms}</span>
//                               </div>
//                             </div>
//                           </td> */}
//                             <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                               {item.offerState.points} Points
//                             </td>
//                             {/* <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
//                           <div className=" rounded-full flex justify-center items-center">
//                               <button
//                                 className={`font-extrabold text-xs leading-4 text-center ${
//                                   item.status === 'pending' ? 'bg-[#DE3434] rounded-full bg-opacity-20 w-[60px] h-[30px] text-[#DE3434]' :
//                                   item.status === 'Refund' ? 'bg-[#E8AD21] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#E8AD21]' :
//                                   item.status === 'Received' ? 'bg-[#39CB79] rounded-full bg-opacity-20 w-[60px] h-[30px] text-[#39CB79]' : ''
//                                 }`}
//                               >
//                                 {item.status}</button>
//                             </div>
//                           </td> */}
//                             <td className="flex flex-col px-4 py-2 font-medium text-sm leading-5 text-black">
//                               <div className=" rounded-full flex justify-center items-center">
//                                 <button
//                                   className={`font-extrabold text-xs leading-4 text-center ${item.status === 'pending' ? 'bg-[#DE3434] rounded-full bg-opacity-20 w-[60px] h-[30px] text-[#DE3434]' :
//                                     item.status === 'cancelled' ? 'bg-[#6C6C6C] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#6C6C6C]' :
//                                       item.status === 'completed' ? 'bg-[#39CB79] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#39CB79]' : ''
//                                     }`}
//                                 >
//                                   {item.status}</button>
//                               </div>
//                               <div className='flex gap-1 text-[#6C6C6C] pt-2'>
//                                 <p>{formattedreservationDate} {" | "}</p>
//                                 <p>{formattedreservationTime}</p>
//                               </div>
//                             </td>
//                           </tr>
//                         )
//                       })
//                     )
//                   }
//                 </tbody>
//               </table>

//               {/* Separate table for exporting selected users */}
//               <table id="selected-users-table" style={{ display: 'none' }}>
//                 <thead>
//                   <tr>
//                     <th>Guest Name</th>
//                     <th>Email</th>
//                     <th>Res.Code</th>
//                     <th>Check-In</th>
//                     <th>Check-Out</th>
//                     <th>Points</th>
//                     <th>Status</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {sortedData(filtered).filter(item => selectedReservations.includes(item._id)).map((item, index) => {

//                     const checkInDate = new Date(item.offerState.checkIn);
//                     const checkOutDate = new Date(item.offerState.checkOut);

//                     const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
//                     const formattedCheckInTime = checkInDate.toLocaleTimeString('en-US', options);

//                     const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
//                     const formattedCheckOutTime = checkOutDate.toLocaleTimeString('en-US', options);

//                     return (
//                       <tr key={index}>
//                         <td>{item.userId?.fname + " " + item.userId?.lname}</td>
//                         <td>{item.userId?.email}</td>
//                         <td>{item?._id}</td>
//                         <td>{formattedCheckInDate}</td>
//                         <td>{formattedCheckOutDate}</td>
//                         <td>{item.offerState.points}</td>
//                         <td>{item.status}</td>
//                       </tr>
//                     )

//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>

//         </Container>


//         {filteredData.length > itemsPerPage && (
//           <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
//         )}

//       </section>

//       {isFilterModalOpen && (
//         <Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
//           <div className="py-4 text-center font-bold">Filters</div>
//           <hr className="text-[#C1E1C2]" />
//           <div className="flex flex-col h-[90%] justify-between pt-14 items-center">

//             {/* CheckIn CheckOut */}
//             <div className="px-5 text-center flex flex-col gap-5">
//               <p className="text-xl font-semibold mb-2">Check-In Between</p>
//               <div className="flex justify-between w-full rounded-md border border-gray-500 text-[#AEAFB0]">
//                 <div className="p-2  ">
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <DatePicker
//                       label="Basic example"
//                       value={checkIn}
//                       dateFormat="LLL"
//                       onChange={handleCheckInChange}
//                       // minDate={currentDate.toDate()}
//                       // shouldDisableDate={(date) => state?.state?.propertyDetail?.bookDates.includes(date.toISOString())}
//                       renderInput={({
//                         inputRef,
//                         inputProps,
//                         InputProps,
//                         params,
//                       }) => (
//                         <div className="flex items-center justify-between ">
//                           <input
//                             className="w-24 text-black outline-0"
//                             ref={inputRef}
//                             {...inputProps}
//                             placeholder="From"
//                             {...params}
//                           />
//                           {InputProps?.endAdornment}

//                         </div>
//                       )}
//                     />
//                   </LocalizationProvider>
//                 </div>

//                 <div class="border-l border-[#AEAFB0] my-2 lg:mx-4"></div>

//                 <div className="p-2">
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <DatePicker
//                       label="Basic example"
//                       value={checkOut}
//                       dateFormat="LLL"
//                       onChange={handleCheckOutChange}
//                       shouldDisableDate={(date) => {
//                         // Disable dates before the selected check-in date
//                         return checkIn && date.isBefore(checkIn, 'day');
//                       }}
//                       renderInput={({
//                         inputRef,
//                         inputProps,
//                         InputProps,
//                         params,
//                       }) => (
//                         <div className="flex items-center">
//                           <input
//                             className="w-24 text-black outline-0"
//                             ref={inputRef}
//                             {...inputProps}
//                             placeholder="To"
//                             {...params}
//                           />
//                           {InputProps?.endAdornment}

//                         </div>
//                       )}
//                     />
//                   </LocalizationProvider>
//                 </div>
//               </div>
//             </div>

//             {/* Reservation Status */}
//             <div className='px-5 text-center flex flex-col gap-5'>
//               <p className="text-xl font-semibold mb-2">Reservation Status</p>
//               <div className="flex items-center space-x-1 md:space-x-4">
//                 <input type="radio" id="pending" name="Status" value="pending" onChange={() => setStatusFilter('pending')} />
//                 <label htmlFor="pending">Pending</label>
//                 <input type="radio" id="cancelled" name="Status" value="cancelled" onChange={() => setStatusFilter('cancelled')} />
//                 <label htmlFor="cancelled">Cancelled</label>
//                 <input type="radio" id="completed" name="Status" value="completed" onChange={() => setStatusFilter('completed')} />
//                 <label htmlFor="completed">Completed</label>
//               </div>
//             </div>

//             {/* Filter Button */}
//             <button
//               className="w-full bg-blue-500 text-white py-2 rounded-md rounded-tr-none rounded-tl-none hover:bg-blue-600 transition duration-300"
//               onClick={applyFilters}
//             >
//               Filter
//             </button>
//           </div>
//         </Modal>
//       )}

//     </>
//   )
// }

// export default ReservationManagement


import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Container } from "@mui/material";
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import Pagination from 'components/Pagination/Pagination';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Modal from 'components/Modal/Modal';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RxCrossCircled } from 'react-icons/rx';
import { toast } from 'react-toastify';

const ReservationManagement = ({ reservation, setStepsAndDetail, setSteps }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [loading, setLoading] = useState(true);

  // Initialize filtered data and maintain state
  useEffect(() => {
    const initialData = reservation.map(res => ({ ...res, isSelected: false }));
    setFiltered(initialData);
  }, [reservation]);

  useEffect(() => {
    if (reservation.length >= 0) {
      setLoading(false);
    }
  }, [reservation]);

  // Updates search input value and resets the current page
  const handleInputChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterButtonClick = () => {
    setIsFilterModalOpen(true);
  }

  const handleCheckInChange = (newValue) => {
    setCheckIn(newValue);
  };
  const handleCheckOutChange = (newValue) => {
    setCheckOut(newValue);
  };

  // Applies filters to the reservation data based on check-in, check-out, and status, and updates the state
  const applyFilters = () => {
    let filteredData = reservation;

    if (checkIn && checkOut) {
      filteredData = filteredData.filter(item => {
        const checkInDate = new Date(item.offerState.checkIn);
        return checkInDate >= checkIn && checkInDate <= checkOut;
      });
    }

    if (statusFilter) {
      filteredData = filteredData.filter(item => item.status === statusFilter);
    }

    setFiltered(filteredData);
    setIsFilterModalOpen(false);
    setFiltersApplied(true);
    setCurrentPage(1);
  };

  //resetting all filters to show all reservation
  const resetFilters = () => {
    setCheckIn(null);
    setCheckOut(null);
    setStatusFilter('');
    setFiltersApplied(false);
    setFiltered(reservation);
    setCurrentPage(1);
  };


  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  };

 
// Sorts reservation data so new reservations appear at the top of the list
const sortedData = (reservation) => {
  return [...reservation].reverse();
};

// Filters data based on search input for user's first and last name
const filteredData = sortedData(filtered).filter((item) => {
  const fullName = (item.userId?.fname || '') + " " + (item.userId?.lname || '');
  return fullName.toLowerCase().includes(search.toLowerCase());
});

// Pagination logic
const totalPages = Math.ceil(filteredData.length / itemsPerPage);
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Toggles the selection of all reservations and updates the selected reservations list
  const handleSelectAll = () => {
    const newIsAllSelected = !isAllSelected;
    setIsAllSelected(newIsAllSelected);

    const updatedData = filtered.map(res => ({ ...res, isSelected: newIsAllSelected }));
    setFiltered(updatedData);

    if (newIsAllSelected) {
      setSelectedReservations(updatedData.map(res => res._id));
    } else {
      setSelectedReservations([]);
    }
  };

  // Toggles the selection of a single reservation and updates the selected reservations list
  const handleCheckboxChange = (reservationId) => {
    const updatedData = filtered.map(res => {
      if (res._id === reservationId) {
        return { ...res, isSelected: !res.isSelected };
      }
      return res;
    });

    const updatedSelectedReservations = updatedData.filter(res => res.isSelected).map(res => res._id);
    setFiltered(updatedData);
    setSelectedReservations(updatedSelectedReservations);
    setIsAllSelected(updatedSelectedReservations.length === filtered.length);
  };

  // Updates the select all checkbox state based on the selected reservations and filtered data
  useEffect(() => {
    setIsAllSelected(selectedReservations.length === filtered.length && filtered.length > 0);
  }, [selectedReservations, filtered]);

  // Handles the export button click and triggers the export if any reservations are selected
  const handleExportClick = () => {
    if (selectedReservations.length === 0) {
      toast.warning("Please select reservation to export");
      return;
    }

    document.getElementById("test-table-xls-button").click();
  };


  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 flex md:flex-row flex-col justify-between md:pt-7 ">
            <div className="">
              <h1 className="text-2xl font-extrabold ">Reservation Management</h1>
            </div>
          </div>
          <div className="flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white">
            <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
              <div className=''>
                <p className='font-medium sm:text-lg text-sm'>Reservations</p>
              </div>
              <div className='flex  space-x-2'>
                <div onClick={handleFilterButtonClick} className='flex justify-between sm:p-4 p-2 items-center cursor-pointer  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                  <div>
                    <img src={icon3} alt="Filter" />
                  </div>
                  <div>
                    <p className='font-normal text-xs text-gray-600'>Filter</p>
                  </div>
                </div>

                <div className='flex items-center justify-center'>
                  {filtersApplied && (
                    <button onClick={resetFilters}>
                      <RxCrossCircled />
                    </button>
                  )}
                </div>

                <button
                  onClick={handleExportClick}
                  className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
                >
                  <div className='w-4 h-4'>
                    <img src={icon5} alt="Export Icon" className='w-full h-full' />
                  </div>
                  <div className='ml-2 sm:ml-0'>
                    <p className='font-normal text-xs text-gray-600'>Export</p>
                  </div>
                </button>

                <ReactHtmlTableToExcel
                  id="test-table-xls-button"
                  className="hidden"
                  table="selected-users-table"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Export"
                />

                <div className="relative md:w-64  ">
                  <input
                    type="text"
                    value={search}
                    onChange={handleInputChange}
                    placeholder='Search'
                    className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                  />
                  <img
                    src={icon4}
                    alt="search icon"
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                  />
                </div>
              </div>
            </div>


            <div className='overflow-auto'>
              <table className="table-auto border-collapse w-full bg-white ">
                <thead>
                  <tr className='bg-[#fafbff]'>
                    <th className="px-4 py-2 border-t border-b text-left">
                      <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} className="mr-2" />
                      Guests
                    </th>
                    <th className="px-4 py-2 border-t text-left border-b">Res. code</th>
                    <th className="px-4 py-2 border-t text-left border-b">Check-in</th>
                    <th className="px-4 py-2 border-t text-left border-b">check-out</th>
                    <th className="px-4 py-2 border-t text-left border-b">points</th>
                    <th className="px-4 py-2 border-t text-left border-b">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center py-10">
                        <CircularProgress />
                      </td>
                    </tr>
                  ) : currentItems.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-10">
                        No reservations found.
                      </td>
                    </tr>
                  ) : (
                    currentItems.map((item, index) => {
                      const checkInDate = new Date(item.offerState.checkIn);
                      const checkOutDate = new Date(item.offerState.checkOut);
                      const reservationDate = new Date(item.timeStamp);

                      const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                      const formattedCheckInTime = checkInDate.toLocaleTimeString('en-US', options);

                      const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                      const formattedCheckOutTime = checkOutDate.toLocaleTimeString('en-US', options);

                      const formattedreservationDate = reservationDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                      const formattedreservationTime = reservationDate.toLocaleTimeString('en-US', options);

                      return (
                        <tr key={index} className='border-b'  >
                          <td className="px-4 py-2">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                checked={isAllSelected || item.isSelected}
                                onChange={() => handleCheckboxChange(item._id)}
                                className="mr-2"
                              />
                              <img src={item.userId?.pic} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                              <div className='flex flex-col'>
                                <span onClick={() => setStepsAndDetail(item._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{item.userId?.fname + " " + item.userId?.lname}</span>
                                <span className="font-medium text-xs leading-4 text-gray-400">{item.userId?.email}</span>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-2 font-medium text-sm leading-5 text-black">{item?._id}</td>
                          <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                            <div className='flex flex-col'>
                              <span>{formattedCheckInDate}</span>
                              <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckInTime}</span>
                            </div>
                          </td>
                          <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                            <div className='flex flex-col'>
                              <span>{formattedCheckOutDate}</span>
                              <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckOutTime}</span>
                            </div>
                          </td>
                          <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                            {item.offerState.points} Points
                          </td>
                          <td className="flex flex-col px-4 py-2 font-medium text-sm leading-5 text-black">
                            <div className=" rounded-full flex justify-center items-center">
                              <button
                                className={`font-extrabold text-xs leading-4 text-center ${item.status === 'pending' ? 'bg-[#DE3434] rounded-full bg-opacity-20 w-[60px] h-[30px] text-[#DE3434]' :
                                  item.status === 'cancelled' ? 'bg-[#6C6C6C] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#6C6C6C]' :
                                    item.status === 'completed' ? 'bg-[#39CB79] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#39CB79]' : ''
                                  }`}
                              >
                                {item.status}</button>
                            </div>
                            <div className='flex gap-1 text-[#6C6C6C] pt-2'>
                              <p>{formattedreservationDate} {" | "}</p>
                              <p>{formattedreservationTime}</p>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>

              {/* Separate table for exporting selected users */}
              <table id="selected-users-table" style={{ display: 'none' }}>
                <thead>
                  <tr>
                    <th>Guest Name</th>
                    <th>Email</th>
                    <th>Res.Code</th>
                    <th>Check-In</th>
                    <th>Check-Out</th>
                    <th>Points</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData(filtered).filter(item => selectedReservations.includes(item._id)).map((item, index) => {

                    const checkInDate = new Date(item.offerState.checkIn);
                    const checkOutDate = new Date(item.offerState.checkOut);

                    const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                    const formattedCheckInTime = checkInDate.toLocaleTimeString('en-US', options);

                    const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                    const formattedCheckOutTime = checkOutDate.toLocaleTimeString('en-US', options);

                    return (
                      <tr key={index}>
                        <td>{item.userId?.fname + " " + item.userId?.lname}</td>
                        <td>{item.userId?.email}</td>
                        <td>{item?._id}</td>
                        <td>{formattedCheckInDate}</td>
                        <td>{formattedCheckOutDate}</td>
                        <td>{item.offerState.points}</td>
                        <td>{item.status}</td>
                      </tr>
                    )

                  })}
                </tbody>
              </table>
            </div>
          </div>

        </Container>


        {filteredData.length > itemsPerPage && (
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
        )}

      </section>

      {isFilterModalOpen && (
        <Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
          <div className="py-4 text-center font-bold">Filters</div>
          <hr className="text-[#C1E1C2]" />
          <div className="flex flex-col h-[90%] justify-between pt-14 items-center">

            {/* CheckIn CheckOut */}
            <div className="px-5 text-center flex flex-col gap-5">
              <p className="text-xl font-semibold mb-2">Check-In Between</p>
              <div className="flex justify-between w-full rounded-md border border-gray-500 text-[#AEAFB0]">
                <div className="p-2  ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Basic example"
                      value={checkIn}
                      dateFormat="LLL"
                      onChange={handleCheckInChange}
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center justify-between ">
                          <input
                            className="w-24 text-black outline-0"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="From"
                            {...params}
                          />
                          {InputProps?.endAdornment}

                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="border-l border-[#AEAFB0] my-2 lg:mx-4"></div>

                <div className="p-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Basic example"
                      value={checkOut}
                      dateFormat="LLL"
                      onChange={handleCheckOutChange}
                      shouldDisableDate={(date) => {
                        // Disable dates before the selected check-in date
                        return checkIn && date.isBefore(checkIn, 'day');
                      }}
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center">
                          <input
                            className="w-24 text-black outline-0"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="To"
                            {...params}
                          />
                          {InputProps?.endAdornment}

                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            {/* Reservation Status */}
            <div className='px-5 text-center flex flex-col gap-5'>
              <p className="text-xl font-semibold mb-2">Reservation Status</p>
              <div className="flex items-center space-x-1 md:space-x-4">
                <input type="radio" id="pending" name="Status" value="pending" onChange={() => setStatusFilter('pending')} />
                <label htmlFor="pending">Pending</label>
                <input type="radio" id="cancelled" name="Status" value="cancelled" onChange={() => setStatusFilter('cancelled')} />
                <label htmlFor="cancelled">Cancelled</label>
                <input type="radio" id="completed" name="Status" value="completed" onChange={() => setStatusFilter('completed')} />
                <label htmlFor="completed">Completed</label>
              </div>
            </div>

            {/* Filter Button */}
            <button
              className="w-full bg-blue-500 text-white py-2 rounded-md rounded-tr-none rounded-tl-none hover:bg-blue-600 transition duration-300"
              onClick={applyFilters}
            >
              Filter
            </button>
          </div>
        </Modal>
      )}

    </>
  )
}

export default ReservationManagement