import { Container, Divider } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

const StartListing = () => {
    return (
        <>
            <section>
                <Container maxWidth='lg'>
                    <div className='flex items-center justify-between'>
                        <div className='w-[93px] h-[49.06px]'>
                            {/* <img src={require('assets/img/logo.jpg')} alt='icon here' /> */}
                        </div>
                        <div>
                            <button className='w-[150px] h-[40px] rounded-full border border-[#E8AD21] text-[#E8AD21] text-base font-normal'>
                                Save & Exit
                            </button>
                        </div>
                    </div>

                    <div className='gap-4 md:flex pt-7'>
                        <div className='md:w-[573.5px] w-full bg-[#03243E] h-[500px]'>
                            <div className='flex flex-col items-center'>
                                <div className='md:w-[104.87px] w-full h-[160.51px]'>
                                    <img src={require('assets/img/icon.png')} alt='icon here' />
                                </div>
                                <div className='md:w-[394.62px] w-full h-[94px]'>
                                    <h4 className='text-3xl font-bold text-white'>It's easy to get
                                        started on BnByond</h4>
                                </div>
                            </div>

                        </div>

                        <div className='md:w-[706.24px] w-full flex flex-col justify-center'>
                            <div>
                                <div className='md:w-[539.78px] w-full md:h-[96px] h-full items-center md:flex gap-6'>
                                    <div className='md:w-[54.95px] w-full flex items-center justify-center h-[54.95px] rounded-full bg-[#58C0D0]'>
                                        <span className='text-2xl font-bold text-white'>1</span>
                                    </div>
                                    <div className='md:w-[458.82px] w-full h-[96px]'>
                                        <h4 className='text-[#000000] text-2xl font-medium'>Tell us about your place</h4>
                                        <p className='text-[#646464] text-xl font-normal'>Share some basic info, like where it is and how many guests can stay.</p>
                                    </div>
                                </div>
                                <div className='pt-7'>
                                    <Divider />
                                </div>
                            </div>
                            <div className='pt-7'>
                                <div className='md:w-[539.78px] w-full md:h-[96px] h-full items-center md:flex gap-6'>
                                    <div className='md:w-[54.95px] w-full flex items-center justify-center h-[54.95px] rounded-full bg-[#58C0D0]'>
                                        <span className='text-2xl font-bold text-white'>2</span>
                                    </div>
                                    <div className='md:w-[458.82px] w-full h-[96px]'>
                                        <h4 className='text-[#000000] text-2xl font-medium'>Make it stand out</h4>
                                        <p className='text-[#646464] text-xl font-normal'>Add 5 or more photos plus a title and description— we'll help you out.</p>
                                    </div>
                                </div>
                                <div className='pt-7'>
                                    <Divider />
                                </div>
                            </div>
                            <div className='pt-7'>
                                <div className='md:w-[539.78px] w-full h-full md:h-[96px] items-center md:flex gap-6'>
                                    <div className='md:w-[54.95px] w-full flex items-center justify-center h-[54.95px] rounded-full bg-[#58C0D0]'>
                                        <span className='text-2xl font-bold text-white'>3</span>
                                    </div>
                                    <div className='md:w-[458.82px] w-full h-[96px]'>
                                        <h4 className='text-[#000000] text-2xl font-medium'>Finish up and publish</h4>
                                        <p className='text-[#646464] text-xl font-normal'>Choose if you'd like to start with an experienced guest, set a starting price, and publish your listing.</p>
                                    </div>
                                </div>
                                <div className='pt-7'>
                                    <Divider />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-end justify-end'>
                        <NavLink to='/member/propertylisting'>
                            <button className='md:w-[200px] rounded-full  w-full text-[#FFFFFF] bg-[#2459BF] h-[50px]'>
                                GET STARTED
                            </button>
                        </NavLink>
                    </div>

                    <div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default StartListing
