import React, { useState, useEffect } from "react";
import girl from "../../assets/img/girl.jpeg";
import selfie from "../../assets/img/selfie.jpeg";
import selfie2 from "../../assets/img/selfie2.jpeg";
import selfie3 from "../../assets/img/selfie3.jpeg";
import icon from "../../assets/img/icon.png";
import InputWithCopy from "./InputWithCopy";
import { Link, useNavigate } from "react-router-dom";
import InfoBlock from "./InfoBlock";
import { localStorageData } from "../../services/auth/localStorageData";

const StrInnovator = () => {
  const userCode = localStorageData("referralCode");
  const userToken = localStorageData("token");
  const [sharableLink, setSharableLink] = useState(
    `https://www.beta.bnbyond.com/auth/signup?referralCode=${userCode}`
  );
  const [innovatorCode, setInnovatorCode] = useState("");
  const [copiedField, setCopiedField] = useState(null);

  useEffect(() => {
    const url = new URL(sharableLink);
    const referralCode = url.searchParams.get("referralCode");
    if (referralCode) {
      setInnovatorCode(referralCode);
    }
  }, [sharableLink]);

  const handleCopy = async (text, field) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    } catch (error) {
      alert(`Failed to copy`, error);
    }
  };

  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/auth/signin", { state: { from: "/str-innovator" } });
  };

  return (
    <>
      <div className="relative h-screen">
        {/* Header Section: Logo and Text */}
        <div className="relative z-10 flex items-center p-4 bg-white">
          {/* Logo */}
          <Link to="/">
            <img src={icon} alt="Logo" className="w-auto h-10 mr-4" />
          </Link>
          {/* Text */}
          <p className="text-gray-700 text-base lg:text-[2.30rem] font-normal">
            Get handsomely rewarded with content opportunities you never
            imagined.
          </p>
        </div>

        {/* Background Image with Overlay */}
        <div
          className="absolute inset-0 bg-cover bg-[center_top_3rem] bg-no-repeat"
          style={{
            backgroundImage: `url(${girl})`,
          }}
        >
          {/* Gray Overlay */}
          <div className="absolute inset-0 bg-black opacity-40"></div>
        </div>

        {/* Content Section */}
        <div className="relative z-10 flex flex-wrap min-h-[90vh]">
          {/* Left Section */}
          <div className="w-full md:w-1/2 flex items-center justify-center">
            {/* Content Placeholder */}
          </div>

          {/* Right Section */}
          <div className="w-full md:w-1/2 flex items-start md:items-center justify-center p-8">
            <div className="text-left text-white md:max-w-md lg:mt-40">
              <h1 className="text-lg lg:text-3xl font-bold mb-5">
                Help the vacation rental community convert unbooked time into a
                gateway for unforgettable experiences!
              </h1>
              <button className="bg-[#b75852] hover:bg-red-400 lg:text-2xl text-white font-black py-3 px-6 rounded-xl lg:max-w-xs w-full shadow-lg">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-[#e0ae3f] mt-4 p-6 md:p-8 rounded-3xl shadow-lg w-[95%] lg:max-w-7xl mx-auto overflow-visible">
        {/* Top-Right Image Moving Out */}
        <img
          src={selfie}
          alt="man selfie"
          className="absolute top-0 right-0 lg:-top-8 lg:-right-8 w-40 h-28 lg:w-48 lg:h-32 object-cover shadow-md"
        />

        {/* Heading */}
        <h2 className="mt-20 text-2xl lg:mt-0 md:text-3xl font-bold text-gray-900 mb-4">
          As an Invited BnByond STR Innovator
        </h2>

        {/* Bullet Points */}
        <ul className="text-gray-800 list-disc pl-5 space-y-2 mb-6">
          <li>
            You will receive 50 points (equivalent to $50US) for every follower
            who joins using your STR Innovator link.
          </li>
          <li>
            Can use your points at any property, allowing for an entirely new
            world of content creation!
          </li>
          <li>
            STR Innovators are not required to have a listed property on BnByond
            or any other site.
          </li>
          <li>
            When Members renew, you are again awarded 50 points to use for stays
            at any property.
          </li>
          <li>
            No limit to point accumulation. 1000 followers sign up? 50,000
            points to use around the world.
          </li>
        </ul>

        {/* Input Fields */}
        <div className="flex flex-col md:flex-row items-center gap-4">
          {/* Conditionally render the copy input fields */}
          {userCode ? (
            <>
              <InputWithCopy
                label="STR Innovator Code:"
                value={innovatorCode}
                field="innovatorCode"
                copiedField={copiedField}
                handleCopy={handleCopy}
              />
              <InputWithCopy
                label="Sharable Link:"
                value={sharableLink}
                field="sharableLink"
                copiedField={copiedField}
                handleCopy={handleCopy}
              />
            </>
          ) : (
            <p className="text-gray-700 text-center">
              Please{" "}
              <Link to="/auth/signin" className="text-blue-600 underline">
                log in
              </Link>{" "}
              to access your referral tools.
            </p>
          )}
        </div>
      </div>

      <InfoBlock
        bgColor="bg-[#c7e3c6]"
        title="Benefits Your Audience Will Enjoy"
        listItems={[
          "1000 credited points.",
          "An additional 2000 credited points to be used before July 1, 2025.",
          "Discounted Membership. Your audience will enjoy an annual fee of $48 vs regular fee of $88. They will not be charged until their first booking.",
          "Their annual membership fee will remain at the discounted $48.",
        ]}
        image={{ src: selfie2, alt: "Top Right" }}
      />


      {/* Block 2 */}
      <InfoBlock
        bgColor="bg-[#74bfce]"
        title="Unbooked Time is Wasted Opportunity"
        description="This is why BnByond was created. On average, short-term rental properties have an occupancy rate of 50%. Even if they enjoy an occupancy rate of 80%, it means their STR has a full two months of unrealized value. What better way to benefit from vacancies than to enjoy free travel?"
        image={{ src: selfie3, alt: "Top Right" }}
      />

      {/* Block 3 */}
      <InfoBlock
        bgColor="bg-[#b75852]"
        textColor="text-white"
        title="Our Commitment"
        description="We are committed to vastly optimizing the short-term rental ownership experience by putting our members at the heart of everything we do. We value active listening, open communication, and a deep understanding of our members' needs and aspirations. Our mission is to provide a more fulfilling and enriching short-term rental experience for every vacation rental property owner - as a host and as a guest - creating a platform that not only facilitates seamless exchanges but also fosters meaningful connections, lasting memories and extraordinary value."
        image={{ src: selfie3, alt: "Top Right" }}
      />

      {/* Block 4 */}

      <div
        className={`relative mt-4 p-6 md:p-8 w-[95%] lg:max-w-7xl mx-auto overflow-visible`}
      >
        <h2 className="mt-6 lg:mt-3 text-2xl md:text-3xl font-black mb-4 text-black/80">
          The Details
        </h2>
        <div className="flex flex-col gap-y-4 text-black/70">
          <p>
            BnByond will work with a limited number of STR Innovators
            (influencers, content creators, industry specialists, and
            consultants) on an invitation-only basis. <br />
            Those wishing to participate are subject to approval. STR Innovators
            participating will introduce audience/followers as they wish, with
            only the first 500 Members receiving 2000 bonus points. Subsequent
            Members (joining via STR Innovator program) will receive the
            standard 1000 points plus an additional 1000 points to be used
            within six months
          </p>
          <p>
            New Members will register at beta.bnbyond.com, uploading their
            property details, profiles, etc. bnbyond.com will be live on or
            about March 1, 2025, and at that time Members will be able to use
            their points to book stays at other Member properties. Members will
            not be charged their discounted Membership rate until their first
            booking (as guest or host). STR Innovators will never be charged a
            membership fee.
          </p>
          <p>
            Those Members joining BnByond outside of the STR Innovator program
            will be notified that $40 of their Membership fee will be credited
            back to them if they follow an STR Innovator. They will be provided
            a list of influencers, content creators, and industry specialists to
            consider following, and they can receive their code or link from any
            of them.
          </p>
          <p>
            All bookings are subject to a 5% service fee by the Guest. The Host
            pays no fee.
          </p>
          <p>
            All participating STR Innovators and Charter Members (first 500)
            will be invited to participate in forums and conversations to help
            drive the platform and community. Participation in these forums is
            not mandatory.
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center flex-col gap-3 md:flex-row mt-4 mb-4">
        <button className="bg-[#b75852] hover:bg-red-400 lg:text-2xl text-white font-black py-3 px-6 rounded-xl lg:max-w-xs w-[95%] lg:w-full shadow-lg">
          Get Started
        </button>
        <button className="bg-[#e0ae3f] hover:bg-yellow-400 lg:text-2xl text-white font-black py-3 px-6 rounded-xl lg:max-w-xs w-[95%] lg:w-full shadow-lg">
          Download PDF
        </button>
      </div>
    </>
  );
};

export default StrInnovator;
