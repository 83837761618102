import React from "react";
import img1 from "assets/img/cirless.png";
import { useRef } from "react";
import img2 from "assets/img/cirgrt.png";
import img3 from "assets/img/ResDet1.png";
import img4 from "assets/img/ResDet2.png";
import img5 from "assets/img/ResDet3.png";
import img6 from "assets/img/3group.png";
import img7 from "assets/img/Antony.png";
import img8 from "assets/img/Subtract.png";
import img9 from "assets/img/gcheck.png";
import img10 from "assets/img/maped.png";
import img11 from "assets/img/resgirl.png";
import img12 from "assets/img/residentity.png";
import img13 from "assets/img/suprhost.png";
import Slider from "react-slick";
import { IoIosArrowBack } from "react-icons/io";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router-dom";

const AdminReservationDetail = ({ setSteps, ReservationDetail, onBack }) => {
  console.log("I am reservation detail Admin", ReservationDetail);
  const sliderRef = useRef(null);
  const location = useLocation();
  const isAdminDashboard = location.pathname === "/admin/dashboard";

  const AnyReactComponent = ({ text }) => (
    <div>
      <img
        src="https://i.ibb.co/xqDW27s/Vector.png"
        alt="Vector"
        className="object-contain w-10 h-10"
      />
    </div>
  );

  // Default properties for the map component
  const defaultProps = {
    center: {},
    zoom: 11,
  };

  //slider settings and responsivness
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //navigating to the next slide
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  //navigating to the previous slide
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  //formating checkin and checkout date into a readable form
  const registrationDate = new Date(ReservationDetail.offerState.checkIn);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = days[registrationDate.getDay()];
  const month = months[registrationDate.getMonth()];
  const dayOfMonth = registrationDate.getDate();
  const year = registrationDate.getFullYear();

  const formattedRegistrationDate = `\n${month} ${dayOfMonth}, ${year}`;

  const hour = registrationDate.getHours();
  const minute = registrationDate.getMinutes();

  const formattedRegistrationTime = `Check-in time is ${
    hour > 12 ? hour - 12 : hour
  }${hour >= 12 ? "PM" : "AM"} - ${(hour + 5) % 12}${
    hour + 5 >= 12 ? "PM" : "AM"
  }`;

  const checkoutDate = new Date(ReservationDetail.offerState.checkOut);

  const dayOfWeekCheckout = days[checkoutDate.getDay()];
  const monthCheckout = months[checkoutDate.getMonth()];
  const dayOfMonthCheckout = checkoutDate.getDate();
  const yearCheckout = checkoutDate.getFullYear();

  const formattedCheckoutDate = `\n${monthCheckout} ${dayOfMonthCheckout}, ${yearCheckout}`;

  const hourCheckout = checkoutDate.getHours();
  const minuteCheckout = checkoutDate.getMinutes();

  const formattedCheckoutTime = `Check out ${
    hourCheckout > 12 ? hourCheckout - 12 : hourCheckout
  }${hourCheckout >= 12 ? "PM" : "AM"}`;

  // const minPoint =
  //   Array.isArray(ReservationDetail?.property_id?.points) &&
  //   ReservationDetail?.property_id?.points.length > 0
  //     ? ReservationDetail?.property_id?.points.reduce(
  //         (min, p) => (p.point < min ? p.point : min),
  //         ReservationDetail?.property_id?.points[0].point
  //       )
  //     : null;

  return (
    <>
      <div className="p-5">
        <button
          className="flex justify-center items-center text-blue-500 font-medium mb-4 "
          onClick={onBack}
        >
          <IoIosArrowBack /> Back 
        </button>
        <div>
          <h1 className="text-2xl font-extrabold">Reservation Detail</h1>
        </div>

        <div className="flex md:flex-row flex-col gap-4 w-full">
          <div className="flex flex-col md:w-[70%] w-full gap-4 pt-2 ">
            <div className="flex flex-col p-3 space-y-2 border border-[#C1E1C2] rounded-xl">
              <div className="flex justify-between">
                <div>
                  <h1 className="sm:text-xl text-lg font-extrabold">
                    Reservation Confirmed
                  </h1>
                </div>

                <div className="flex sm:space-x-3 space-x-2">
                  <button onClick={handlePrev}>
                    <img src={img1} className="sm:w-10 sm:h-10 w-6 h-6" />
                  </button>
                  <button onClick={handleNext}>
                    <img src={img2} className="sm:w-10 sm:h-10 w-6 h-6" />
                  </button>
                </div>
              </div>
              <div />

              <div className="w-full">
                <Slider ref={sliderRef} {...settings}>
                  {ReservationDetail?.property_id?.pics.map((pic, index) => (
                    <div key={index} className="p-1">
                      <img
                        src={pic}
                        alt=""
                        className="rounded-md h-56 w-full object-cover"
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              <p className="md:text-xl text-lg font-medium pt-5">
                {ReservationDetail?.property_id?.title}
              </p>
              <p className="text-sm font-normal text-[#4B4B4B]">
                Meet{" "}
                {ReservationDetail?.userId?.fname +
                  " " +
                  ReservationDetail?.userId?.lname}
                , your warm and welcoming host.
              </p>
            </div>

            <div className="w-full justify-between flex flex-col md:flex-row bg-white border border-green-200 rounded-xl sm:p-6 p-3 md:space-x-4">
              <div className="border-r flex-col items-center sm:w-[35vw] sm:space-y-4 space-y-2 sm:border  sm:border-r-green-200  border-none w-full md:w-[60%]">
                <div className="flex flex-col justify-between space-y-5">
                  {/* <div className='flex flex-col space-y-5 text-sm font-normal text-black'> */}
                  <div className="flex justify-start items-center">
                    <span className="text-base font-medium w-[30%]">
                      Res Code:
                    </span>
                    <span className=" text-sm text-[#4B4B4B]">
                      {ReservationDetail?._id}
                    </span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-base font-medium w-[30%]">
                      CheckIn
                    </span>
                    <span className=" text-sm text-[#4B4B4B]">
                      {dayOfWeek} {" " + formattedRegistrationDate}
                    </span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-base font-medium w-[30%]">
                      CheckOut
                    </span>
                    <span className=" text-sm text-[#4B4B4B]">
                      {dayOfWeekCheckout} {" " + formattedCheckoutDate}
                    </span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-base font-medium w-[30%]">
                      Status
                    </span>
                    <span className=" text-sm text-[#4B4B4B]">
                      {ReservationDetail?.status}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[40%] flex flex-col space-y-5 pt-5 md:pt-0 md:space-y-2  md:items-start ">
                <div className="flex flex-row md:flex-col sm:space-y-1">
                  <span className="w-[40%] md:w-full sm:text-base text-sm sm:font-medium font-normal">
                    Address
                  </span>
                  <span className="text-sm font-normal w-full text-[#4B4B4B]">
                    {ReservationDetail?.property_id?.address}
                  </span>
                </div>
                <div className="flex flex-row md:flex-col sm:space-y-1 ">
                  <span className="w-[40%] md:w-full sm:text-base text-sm  sm:font-medium font-normal">
                    Guests
                  </span>
                  <span className="text-sm font-normal w-full text-[#4B4B4B]">
                    {ReservationDetail?.property_id?.spaceTypeDetail?.guests}
                  </span>
                </div>
                <div className="flex flex-row md:flex-col sm:space-y-1 ">
                  <span className="w-[40%] md:w-full sm:text-base text-sm  sm:font-medium font-normal">
                    Amount
                  </span>
                  <span className="text-sm font-normal w-full text-[#4B4B4B]">
                    {ReservationDetail?.offerState.points} Points
                  </span>
                </div>
              </div>
            </div>

            <div className="md:w-full bg-white border border-green-200 flex flex-col space-y-1 rounded-2xl">
              {/* <img src={img10} alt="" /> */}
              {ReservationDetail.property_id.loc ? (
                <div style={{ height: "423px", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      // key: "AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8",
                      key: "AIzaSyC23GAPasXhA6vYd7VYALzaPcFJufEUb5s",
                    }}
                    defaultCenter={{
                      lat: ReservationDetail.property_id.loc.coordinates[1],
                      lng: ReservationDetail.property_id.loc.coordinates[0],
                    }}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={ReservationDetail.property_id.loc.coordinates[1]}
                      lng={ReservationDetail.property_id.loc.coordinates[0]}
                      text="My Marker"
                    />
                  </GoogleMapReact>
                  {/* Display location information */}
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex flex-col md:w-[30%] w-full  pt-2 sm:space-y-6 space-y-3">
            {isAdminDashboard && (
              <div className="flex flex-col bg-white  border border-green-200 sm:p-6 p-2 rounded-xl">
                <div className="flex justify-end">
                  <img src={img6} />
                </div>

                <div className="flex flex-col items-center justify-center ">
                  <img src={ReservationDetail?.userId?.pic} className="w-20" />
                </div>

                <div className="flex flex-col sm:space-y-2 space-y-1 items-center justify-center sm:pt-6 pt-4  border-b border-b-gray-200 sm:p-4 p-2">
                  <p className="sm:text-2xl text-xl font-medium">
                    {ReservationDetail?.userId?.fname}
                  </p>
                  <p className="text-base font-normal text-[#929293]">Guest</p>
                  <div className="flex items-center">
                    <img src={img8} className="w-4 h-4" />
                    <span className="pl-2 sm:text-base text-sm">
                      {ReservationDetail?.userId?.address}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center">
                  <div className=" sm:p-6 p-3 flex space-x-1">
                    <img src={img9} />
                    <span className="sm:text-base text-sm font-normal text-[#929293]">
                      {ReservationDetail?.userId?.email}
                    </span>
                  </div>

                  <div>
                    <button className="bg-transparent text-[#E8AD21] border-[#E8AD21] py-2 px-20 md:px-10 xl:px-20 rounded-full border-2 text-base font-bold">
                      CONTACT
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col bg-white  border border-green-200 sm:p-6 p-3 rounded-xl">
              <div className="flex justify-end">
                <img src={img6} alt="" />
              </div>

              <div className="flex flex-col items-center justify-center ">
                <img
                  src={ReservationDetail?.property_id?.userId?.pic}
                  className="w-20"
                />
              </div>

              <div className="flex flex-col space-y-2 items-center justify-center pt-6 border-b border-b-gray-200 p-4">
                <p className="sm:text-2xl text-xl font-medium">
                  {ReservationDetail?.property_id?.userId?.fname}
                </p>
                <p className="text-base font-normal text-[#929293]">Host</p>
                <div className="flex items-center">
                  <img src={img8} className="w-4 h-4" />
                  <span className="pl-2 sm:text-base text-sm">
                    {ReservationDetail?.property_id?.userId?.address}
                  </span>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className=" sm:p-6 p-3 flex flex-col space-x-1 sm:space-y-2 space-y-1 items-center">
                  <div className="flex space-x-1">
                    <img src={img9} />
                    <span className="sm:text-base text-sm font-normal text-[#929293]">
                      {ReservationDetail?.property_id?.userId?.email}
                    </span>
                  </div>
                  {/* <div className='flex items-center'>
                    <img src={img13} />
                    <span className='text-base font-normal text-[#929293] pl-2'>Superhost</span>
                  </div> */}
                </div>
                <div>
                  <button className="bg-transparent text-[#E8AD21] border-[#E8AD21] py-2 px-20 md:px-10 xl:px-20 rounded-full border-2 text-base font-bold">
                    CONTACT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminReservationDetail;
