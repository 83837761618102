import { ButtonOutlined } from 'common/buttons/buttonOutlined'
import React from 'react'

const TaxPayerCard = ({ name, information, register, special }) => {
  return (
    <div className="gap-4 border border-[#AFB5C1] p-5 rounded-2xl mt-4 px-8 space-y-7">
      <div className="space-y-1">
        <p className="text-lg font-medium">{information}</p>
        <p className="text-base font-normal text-[#4B4B4B]">{register}
          <span className="border-b border-black font-normal cursor-pointer">Learn more</span></p>
      </div>
      <div className='lg:w-1/2'>
        <ButtonOutlined text={name} />
      </div>
    </div>
  );
}

export default TaxPayerCard