import React from 'react';
import history from '../history';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import Main from './Main';
import Auth from './Auth';
import PropertyDetails from 'pages/customer/PropertyDetails';
import Member from './Member';
import LandingPage from 'pages/Landingpage';
import Admin from './Admin';
import BnbNav from 'components/NavBar/BnbNav';
import ProtectedRoute from './ProtectedRoute';
import SearchListings from 'pages/customer/SearchListings';
import BlogsDetails from 'pages/Blogs/BlogsDetails';
import { Blogs } from 'pages/Blogs';
import ScrollToTop from 'components/ScrollToTop';
import { ChatProvider } from 'common/contexts/chatConversationsContext';
import AboutUs from 'components/AboutUs/AboutUs';
import CommingSoon from "pages/CommingSoon/CommingSoon";
import ContactUs from 'components/ContactUs/ContactUs';
import Influencer from 'pages/influencer';
import StrInnovator from 'components/Str/StrInnovator';
import PartnerResource from 'components/PartnerResource/PartnerResource';


function Root() {
  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <ChatProvider>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/*"
                element={<Main />} />

              <Route
                path="member/*"
                element={<Member />} />
            </Route>
            <Route
              path="/"
              element={<> <BnbNav /> <LandingPage /></>} />
            <Route path="/search/:lat/:long" element={<> <BnbNav /> <SearchListings /></>} />
            <Route path='/propertydetails/:id' element={<PropertyDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="blog/:id" element={<BlogsDetails />} />
            <Route path='auth/*' element={<Auth />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/influencer" element={<Influencer />} />
            <Route path='/contactus'
              element={<ContactUs />}
            />
            <Route path='/str-innovator'
              element={<StrInnovator />}
            />
            <Route path='/partner-resource'
              element={<PartnerResource />}
            />
            <Route path="/comming-soon" element={<CommingSoon />} />
            <Route
              path="admin/*"
              element={<Admin />} />

          </Routes>
        </ChatProvider>
      </Router>

    </>
  );
}

export default Root;

