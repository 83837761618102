import React, { useState } from 'react';
import searchIcon from '../../../assets/img/searchIcon.png'
import dp from '../../../assets/img/dp.png'
import CustomDropdown from './CustomDropdown';

const AdminModal = ({ closeModal }) => {


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-8 w-full max-w-sm">
                <h2 className="text-xl font-bold mb-4 text-center">Add new Role</h2>
                <div className='relative'>
                    <input type='search' placeholder='Search' className='border border-[#E9E9E9] rounded-md px-4 py-2 pr-10 w-full'></input>
                    <div className='absolute inset-y-0 right-2 flex items-center pr-2 pointer-events-none'>
                        <img src={searchIcon} />
                    </div>
                </div>
                <div className="flex items-center my-4">
                    <div className='flex-col'>
                        <img
                            src={dp}
                            alt="User"
                            className="w-12 h-12 rounded-full mr-4"
                        />
                        <span>Leo D...</span>
                    </div>
                </div>

                <div className='pb-5'>
                    <CustomDropdown />
                </div>


                <div className="flex justify-center">
                    <button
                        className="border-2 border-[#C0392D] py-2 rounded-full mr-2 px-14"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button className="bg-[#2459BF] text-white py-2 rounded-full px-14">
                        Done
                    </button>
                </div>
            </div>
        </div>

    );
};

export default AdminModal;
