import React from 'react'
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import { useState } from 'react';
import Pagination from 'components/Pagination/Pagination';
import Rating from 'react-rating';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import Modal from "components/Modal/Modal";


const ReviewsList = ({ setSteps, allProperty, setStepsAndDetail }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  // Function to format review date
  const reviewDate = (value) => {
    const date = new Date(value);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formatedReviewDate = date.toLocaleDateString(
      "en-US",
      options
    );
    return formatedReviewDate;
  };

  const allReviewsWithProperty = allProperty.flatMap(property =>
    (property.reviews || []).map(review => ({
      ...review,
      propertyId: property._id,
      propertyPic: property.pics[0],
      propertyTitle: property?.title,
      propertyLocation: property?.address,
    }))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);

  //pagination logic
  const totalPages = Math.ceil(allReviewsWithProperty.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const currentPageData = allReviewsWithProperty.slice(lastIndex - itemsPerPage, lastIndex);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className='flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white'>
        <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
          <div className=''>
            <p className='font-medium sm:text-lg text-sm'>All Guests</p>
          </div>
          <div className='flex  space-x-2'>
            <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
              <div>
                <img src={icon3} />
              </div>
              <div>
                <p className='font-normal text-xs text-gray-600'>Fillter</p>
              </div>
            </div>
            <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px] border border-gray-300 rounded-md'>
              <div>
                <img src={icon5} />
              </div>
              <div>
                <p className='font-normal text-xs text-gray-600'>Export</p>
              </div>
            </div>

            <div className="relative md:w-64  ">
              <input
                type="text"
                placeholder='Search'
                className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
              />
              <img
                src={icon4}
                alt="search icon"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white sm:overflow-auto">
        {currentPageData.map((item, index) => (
          <div key={index} className="w-[900px] md:w-auto flex border-b border-gray-200 justify-between py-5">
            <div className=" px-4 py-2 flex flex-col items-start justify-center w-[30%] md:w-[55%] lg:w-[30%] xl:w-[22%]">
              <div className="flex items-center">
                {/* <input type="checkbox" className="mr-2" /> */}
                <img src={item?.user?.pic} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                <div className="flex flex-col">
                  <span className="font-medium text-sm leading-5 text-black">{item?.user?.fname + " " + item?.user?.lname}</span>
                  <div className="flex items-center gap-2">
                    <Rating
                      initialRating={item?.rating}
                      readonly
                      emptySymbol={<FaRegStar color="#d3d3d3" />}
                      halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                      fullSymbol={<FaStar color="#ffd700" />}
                      fractions={2}
                    />
                    <span className="text-sm pr-2 m-auto">({item?.rating})</span>
                  </div>
                  <span className="font-medium text-xs leading-4 text-[#AFB5C1]">{item?.user?.email}</span>
                </div>
              </div>
            </div>
            <div className="flex w-[650px] h-[104px] px-[30px] gap-3 border-l-2 border-gray-200">
              <div className='w-[150px] h-[110px]'>
                <img src={item.propertyPic} alt="Profile" className="w-full h-full object-cover rounded-xl" />
              </div>
              <div className='flex flex-col gap-3 justify-center'>
                <p onClick={() => setStepsAndDetail(item.propertyId)} className="flex justify-start items-center font-bold text-[16px] leading-6 cursor-pointer">{item?.propertyTitle}</p>
                <p className="flex justify-start items-center text-[16px] text-[#AFB5C1] leading-6 ">{item?.propertyLocation}</p>
              </div>
            </div>
            <div className=" px-4 py-2 font-medium text-sm leading-5 text-black flex items-center justify-center">
              <div className=" bg-[#2459BF] rounded-full flex justify-center items-center mr-3">
                <button onClick={() => openModal(item)} className="font-extrabold text-xs leading-4 text-center text-white px-3 py-2">Review</button>
              </div>
            </div>
          </div>
        ))}

        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
      </div>

      {selectedItem && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="my-5 max-h-[78vh] overflow-y-auto py-2 ">
            <div className="w-full h-full px-6 rounded-xl flex flex-col justify-start">
              <div>
                <div className="flex items-center gap-4">
                  <div className="w-12 h-12">
                    <img
                      src={selectedItem?.user?.pic}
                      alt="User Pic"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <span className="font-medium text-sm leading-5 text-[#AFB5C1]">{reviewDate(selectedItem?.date)}</span>
                    <h4 className="text-lg text-black font-semibold">
                      {selectedItem?.user?.fname + " " + selectedItem?.user?.lname}
                    </h4>
                  </div>
                </div>
                <div className="pt-3">

                  <Rating
                    initialRating={selectedItem.rating}
                    readonly
                    emptySymbol={<FaRegStar color="#d3d3d3" />}
                    halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                    fullSymbol={<FaStar color="#ffd700" />}
                    fractions={2}
                  />
                </div>
              </div>
              <div className="mt-2">
                <p className="text-base text-gray-700 font-normal leading-7">
                  {selectedItem.comment}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}


    </>
  )
}

export default ReviewsList