import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiCopy } from "react-icons/fi";
import { IoMdCheckmark } from "react-icons/io";

const ShortUrlWithCopy = ({ url, maxLength = 30 }) => {
  const [isCopied, setIsCopied] = useState(false);

  // Function to shorten the URL
  const shorten = (str, length) => {
    if (str.length > length) {
      return str.slice(0, length) + '...';
    }
    return str;
  };

  // Function to copy the URL to the clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="flex items-center space-x-4 mb-3">
      <td
        className="text-blue-600 truncate max-w-[300px]"
      >
        {shorten(url, maxLength)}
      </td>
      <button
        onClick={handleCopy}
        className={`px-3 py-1 rounded text-white transition ${
          isCopied ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-600'
        }`}
      >
        {isCopied ? <IoMdCheckmark size={20} title="Copied!" /> : <FiCopy size={20} title="Copy to Clipboard" />}

      </button>
    </div>
  );
};

export default ShortUrlWithCopy;
