import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoAddress from "components/AutoAddress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const LandingSearchBar = ({
  notPedding,
  onSearchInputChange,
  checkindate,
  checkoutdate,
  handleFilterData
}) => {
  const [checkIn, setCheckIn] = React.useState(dayjs("2025-04-03")); // Default check-in date
  const [checkOut, setCheckOut] = React.useState(dayjs("2025-04-06")); // Default check-out date
  const [guest, setGuest] = useState("");
  const [rooms, setRooms] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [fullAddress, setFullAddress] = useState({ address: "South Carolina, USA", lat: 33.8361, long: -81.1637 }); // Default address (South Carolina, USA)
  const [lat, setLat] = useState(33.8361); // South Carolina default latitude
  const [long, setLong] = useState(-81.1637); // South Carolina default longitude

  const currentDate = dayjs();
  let navigate = useNavigate();

  // Handle address changes from AutoAddress input
  const changeAddress = (address, lat, long) => {
    setLat(lat);
    setLong(long);
    setFullAddress({ address, lat, long });
  };

  // Handle search button click
  const onSearch = () => {
    if (lat === 0) {
      toast.error("Location required for search");
      return;
    }

    const spaceTypeDetailInput = document.getElementById("guestsInput");
    const bedroomsInput = document.getElementById("bedroomsInput");
    const spaceTypeDetail = spaceTypeDetailInput ? spaceTypeDetailInput.value : "";
    const bedroomData = bedroomsInput ? bedroomsInput.value : "";

    navigate(`/search/${lat}/${long}`, {
      state: { spaceTypeDetail, checkIn, checkOut, fullAddress, bedroomData },
    });
  };

  useEffect(() => {
    // Initial location can be set here if needed (from a service, default is SC)
  }, []);

  // Handle guest input change
  const handleInputChange = (e) => {
    setGuest(e.target.value);
    setSearchInput(e.target.value);
    onSearchInputChange(e.target.value);
  };

  // Handle room input change
  const handleRoomInput = (e) => {
    setRooms(e.target.value);
    setSearchInput(e.target.value);
    onSearchInputChange(e.target.value);
  };

  // Handle address input change (to make it editable)
  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setFullAddress((prev) => ({
      ...prev,
      address: newAddress,
    }));
    // Optional: Call an API or geocoding service to get lat/long for the new address
    if (newAddress === "") {
      setLat(0); // Clear latitude if address is empty
      setLong(0); // Clear longitude if address is empty
    }
  };

  return (
    <Container maxWidth="lg">
      <div className="flex justify-center items-center">
        <span className="text-white font-bold w-[90%] pt-40 lg:pt-24 md:w-[80%] lg:w-[60%] xl:w-[54%] leading-tight text-center text-xl md:text-4xl">
          The Exchange Community for Vacation Rental Property Owners
        </span>
      </div>

      <div className={`z-10 w-full ${notPedding === "notapply" ? "pt-0" : "md:pt-20 lg:pt-28"} center-styl`}>
        <div className="text-start pl-0 md:pl-0 md:text-start pt-20 md:pt-0">
          {notPedding !== "notapply" ? (
            <span className="text-[#FFFFFF] text-[24px] md:text-[30px] font-extrabold">
              Find Your Destination
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="block lg:flex pt-5">
          <div className="w-full py-2 flex md:justify-center lg:h-[100px] h-full bg-white shadow rounded-r-xl rounded-l-xl md:rounded-r-none md:rounded-l-xl">
            <div className="flex flex-col gap-2 lg:flex-row px-4 text-[#AFB5C1] w-full lg:w-auto items-center justify-between">
              <div className="lg:my-0 md:my-0 w-full lg:w-auto h-[50px]">
                <div className="flex items-center justify-between px-4 py-3 border border-gray-300 rounded-xl">
                  <input
                    type="text"
                    className="w-full outline-none"
                    placeholder="Destination"
                    value={fullAddress.address} // Bind the address to the state
                    onChange={handleAddressChange} // Make it editable
                  />
                  <div className="pr-3">
                    <i className="fa-solid fa-location-dot"></i> {/* Location marker icon */}
                  </div>
                </div>
              </div>

              {/* Check-in and Check-out DatePickers */}
              <div className="flex w-full lg:w-auto items-center justify-center px-4 py-2 border border-gray-300 rounded-xl h-[50px]">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Check-in"
                    value={checkIn}
                    onChange={(newValue) => setCheckIn(newValue)}
                    minDate={currentDate.toDate()}
                    renderInput={({ inputRef, inputProps, InputProps, params }) => (
                      <div className="flex items-center">
                        <input
                          className="w-full text-black outline-0"
                          ref={inputRef}
                          {...inputProps}
                          placeholder="Check-in"
                          {...params}
                        />
                        {InputProps?.endAdornment}
                      </div>
                    )}
                  />
                </LocalizationProvider>
                <div className="w-px h-full mx-2 bg-gray-300"></div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Check-out"
                    value={checkOut}
                    onChange={(newValue) => setCheckOut(newValue)}
                    minDate={checkIn || currentDate.toDate()}
                    renderInput={({ inputRef, inputProps, InputProps, params }) => (
                      <div className="flex items-center">
                        <input
                          className="w-full text-black outline-0"
                          ref={inputRef}
                          {...inputProps}
                          placeholder="Check-out"
                          {...params}
                        />
                        {InputProps?.endAdornment}
                      </div>
                    )}
                  />
                </LocalizationProvider>
              </div>

              {/* Guests and Rooms Input */}
              <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                <input
                  placeholder="Guests"
                  className="w-full outline-none"
                  id="guestsInput"
                  value={guest}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                <input
                  placeholder="Rooms"
                  className="w-full outline-none"
                  id="bedroomsInput"
                  value={rooms}
                  onChange={handleRoomInput}
                />
              </div>

              {/* Search Button */}
              <div className="flex w-full lg:w-auto h-[50px] justify-center">
                <button
                  onClick={onSearch}
                  className="text-sm w-full lg:w-[100px] font-bold text-white transition-all duration-150 ease-linear rounded-full shadow bg-[#2459BF] hover:shadow-lg"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LandingSearchBar;
