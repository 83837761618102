import React from "react";
import { GoDotFill } from "react-icons/go";
import { useNavigate} from "react-router-dom";


const pricingData = [
  {
    title: "Pricing Plans",
    content:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem perspiciatis voluptatum magni, velit omnis quia a doloribus obcaecati dolorum natus suscipit! Consequuntur molestias ipsa, distinctio at minus vero dolorem voluptatibus?",
  },
  {
    title: "Features Comparison",
    content:
      "See at a glance how our plans compare. From essential features to premium perks, find the perfect balance for your requirements with our easy-to-read comparison chart.",
  },
  {
    title: "Billing Cycle",
    content:
      "Choose the billing cycle that suits you best – monthly for flexibility or annually for savings. Enjoy the same great features, just with a billing frequency that matches your business rhythm.",
  },
  {
    title: "Customization Options",
    content:
      "Need something tailored to your specific needs? Explore our custom plans and enterprise solutions. Contact us to discuss how we can craft the perfect package for you.",
  },
  {
    title: "Free Trial or Demo",
    content:
      "Experience the power of our platform risk-free with our [X]-day free trial. No credit card required. Sign up now and see the difference for yourself.",
  },
  {
    title: "Money-Back Guarantee",
    content:
      "Your satisfaction is our priority. We stand by our service with a [X]-day money-back guarantee. If you're not delighted, we'll refund your investment – no questions asked.",
  },
  {
    title: "Payment Methods",
    content:
      "We accept a variety of payment methods, including major credit cards and secure online payment options. Rest assured, your transactions are in safe hands.",
  },
  {
    title: "Taxes and Fees",
    content:
      "Our prices are transparent and inclusive of all applicable taxes. No hidden fees. What you see is what you get, allowing you to budget with confidence.",
  },
  {
    title: "Cancellation Policy",
    content:
      "Life happens, and we understand. Cancel anytime hassle-free with our straightforward cancellation policy. No long-term commitments or hidden penalties.",
  },
  {
    title: "Contact Information",
    content:
      "Have questions or need assistance? Our support team is here to help. Reach out via [email/phone/live chat] for prompt and friendly assistance.",
  },
  {
    title: "FAQ Section",
    content:
      "Find quick answers to common questions in our FAQ section. Can't find what you're looking for? Contact our support team for personalized assistance.",
  },
  {
    title: "Terms of Service and Refund Policy",
    content:
      "For a detailed overview of our terms and policies, please review our Terms of Service and Refund Policy. Your understanding is important to us.",
  },
];

const Pricing = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full p-5">
        <div className="md:flex md:p-5 p-2 ">
          <div className="md:w-[65%] ">
            <h1 className="text-[30px] font-semibold mb-3">Pricing</h1>
            <p className=" mb-5 text-[#4B4B4B]">The following terms apply if you live outside the United States. If you live within the United States please refer to the relevant terms of service linked <span className="underline text-black " >here</span>.</p>
            {pricingData.map((item, index) => (
              <div key={index}>
                <p className="font-bold text-[#4B4B4B]">
                  {index + 1}.{item.title}:
                </p>
                <p className="flex">
                  <GoDotFill className="text-xl mt-2 mr-2" />{" "}
                  <span className="text-justify my-2 text-[#4B4B4B]">{item.content}</span>
                </p>
              </div>
            ))}
          </div>
          <div className="md:w-[35%] mt-10 md:p-5 p-2 ">
            <div className="border p-3 rounded-xl">
              <p className="font-semibold text-[18px] ">Need to get in touch?</p>
              <p className="text-[16px] text-[#4B4B4B] py-2">
                We’ll start with some questions and get you to the right place.
              </p>
              <button onClick={() => navigate("/contactus",)}
                className="text-yellow-500 rounded-3xl border p-2  my-2">
                CONTACT US
              </button>
              <p className="py-2">
                You can also{" "}
                <strong className="underline">give us feedback.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
