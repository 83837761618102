import React from 'react'
import { FaArrowTrendUp } from "react-icons/fa6";

const TopCard = ({ allUsers }) => {

  const totalInfluencers = allUsers.filter(user => {
    return user.roles.includes("Influencer");
}).length;
  const totalGuestsHosts = allUsers.filter(user => {
    return user.roles.includes("Guest") && !user.roles.includes("Influencer");

}).length;

  const totalUsers = allUsers.length;
  const cardsData = [
    { title: 'All Users', count: totalUsers, percentage: '12.00%' },
    { title: 'Guests/Hosts', count: totalGuestsHosts, percentage: '8.00%' },
    { title: 'Influencers', count: totalInfluencers, percentage: '10.00%' }
  ];

  return (
    <div className="flex flex-wrap gap-2 mt-4">
      {cardsData.map((card, index) => (
        <div key={index} className="xl:w-[32.8%] lg:w-[32.49%] md:w-[49.3%] w-full h-[82px] border border-green-200 rounded-2xl p-5 bg-white">
          <div>
            <p className="font-normal text-lg leading-4 text-gray-700 pb-2">{card.title}</p>
          </div>
          <div className="justify-between flex">
            <div>
              <h2 className="font-semibold text-2xl leading-7">{card.count}</h2>
            </div>
            <div className="flex gap-3">
              <FaArrowTrendUp className="text-green-500" />
              <div>
                <p className="font-normal text-xs leading-4 text-green-500">{card.percentage}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

  )
}

export default TopCard