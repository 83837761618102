import { config } from '../../config';

export function localStorageData(value) {
  let fialValue = null;

  let localData = JSON.parse(localStorage.getItem(config.localToken));

  if (localData) {
    Object.keys(localData).forEach(function (key) {
      if (key === value) {
        fialValue = localData[key];
      }
    });
  }

  return fialValue;
}

export function getLocalUserdata(value) {
  let localData = JSON.parse(localStorage.getItem(config.localToken));
  return localData;
}

export function storeLocalData(value) {
  localStorage.setItem(config.localToken, JSON.stringify(value));
}

export function Logout(value) {
  localStorage.removeItem(config.localToken);

  return true;
}
