import React, { useRef } from 'react';
import img1 from "assets/img/abtdesign.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Footerbn from 'pages/Footer/Footerbn';
import Container from "@mui/material/Container";
import icon from "../../assets/img/icon.png";
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import BnbNav from 'components/NavBar/BnbNav';
import Navbar from 'components/Navbars/AuthNavbar';
import NewsLetter from 'components/NewsLetter/NewsLetter';

const ContactUs = () => {
  const formRef = useRef();  // Reference to the form for emailjs

  // Function to send email using emailjs
  const sendEmail = (values, actions) => {
    emailjs.sendForm('service_ljl7dzo', 'template_jy6n7qf', formRef.current, 'NO2pXno55HxArz-27')
      .then((result) => {
        toast.success("Message sent successfully");
        actions.resetForm();
      }, (error) => {
        toast.error("Failed to send message");
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <BnbNav />
      <Navbar />
      <div className='flex flex-col items-center justify-center space-y-2 md:p-16 p-3 '>
        <h1 className='md:text-4xl text-2xl font-medium'>Let's Make Your Travel Dreams a Reality!</h1>
        <p className='md:text-xl text-lg font-normal text-[#4B4B4B] md:w-[526px] w-full text-center'>Your Journey Begins with a Click: Reach Out and Explore the World Together.</p>
      </div>

      <div className='flex flex-col items-center bg-[#F8F8F8] md:p-10 p-4 md:space-y-4 space-y-2 '>
        <div className='w-full flex md:flex-row flex-col md:gap-5 gap-3 items-center justify-center'></div>
        <div>
          <img src={img1} alt="" />
        </div>
      </div>

      <div className='px-3 pt-20 flex items-center justify-center xl:justify-start xl:pl-36 '>
        <span className='text-2xl font-extrabold'>Send Us A Message</span>
      </div>

      <Formik
        initialValues={{
          user_name: '',
          user_email: '',
          user_phone: '',
          user_city: '',
          message: ''
        }}
        validate={(values) => {
          const errors = {};
          if (!values.user_name) {
            errors.user_name = 'Required';
          }
          if (!values.user_email) {
            errors.user_email = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          sendEmail(values, actions);
        }}
      >
        {({ isSubmitting }) => (
          <Form ref={formRef} className='flex flex-col gap-4 items-center justify-center pt-6 px-3'>
          <div className='w-full max-w-[1140px] flex flex-col gap-3'>
            <div className='flex flex-col xl:flex-row gap-3 w-full'>
              <div className='flex flex-col flex-1'>
                <label htmlFor="user_name">Name*</label>
                <Field type="text" id="user_name" name="user_name" placeholder='Your Name' className='p-2 w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_name" component="div" className="text-red-500" />
              </div>
              <div className='flex flex-col flex-1'>
                <label htmlFor="user_email">Email*</label>
                <Field type="email" id="user_email" name="user_email" placeholder='Email Address' className='p-2 w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_email" component="div" className="text-red-500" />
              </div>
            </div>
        
            <div className='flex flex-col xl:flex-row gap-3 w-full'>
              <div className='flex flex-col flex-1'>
                <label htmlFor="user_phone">Phone number</label>
                <Field type="tel" id="user_phone" name="user_phone" placeholder='+62' className='p-2 w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_phone" component="div" className="text-red-500" />
              </div>
              <div className='flex flex-col flex-1'>
                <label htmlFor="user_city">City</label>
                <Field type="text" id="user_city" name="user_city" placeholder='City' className='p-2 w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_city" component="div" className="text-red-500" />
              </div>
            </div>
        
            <div className='flex flex-col w-full'>
              <label htmlFor="message">Description</label>
              <Field as="textarea" id="message" name="message" placeholder="Enter description" rows="6" className="p-2 rounded-lg border border-[#C1E1C2] w-full" />
              <ErrorMessage name="message" component="div" className="text-red-500" />
              <div className='pt-3 pb-2 flex justify-center'>
          <button type="submit" disabled={isSubmitting} className='max-w-fit py-2 px-6 bg-[#2459BF] rounded-full text-white w-[160px]'>
          {isSubmitting ? 'Submitting...' : 'SEND MESSAGE'}
          </button>
           </div>
            </div>
          </div>
        </Form>        
        )}
      </Formik>

      <div className='flex justify-center items-center'>
        <div className="flex justify-center w-full">
        <NewsLetter />
          {/* <section className="flex relative z-30 md:top-20 w-[94%] lg:mb-10">
            <div className="">
              <img src={require("../../assets/img/icon.png")} className="absolute left-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] z-[-10]" />
            </div>
            <Container style={{ paddingLeft: "6px", paddingRight: "6px" }}>
              <div
                className="bg-[#AF3227]/95 md:h-[300px] h-full rounded-2xl lg:w-[90%] xl:w-[96%] mx-auto"
                style={{ backgroundImage: "url(" + require("assets/img/badgebg.png") + ")", backgroundRepeat: "no-repeat" }}
              >
                <Container>
                  <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">
                    <div className="lg:pl-10">
                      <div className="md:w-[445px] flex items-center justify-center w-full">
                        <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold">
                          Get travel tips, offers and inspiration from BnByond
                        </p>
                      </div>
                      <div className="relative pt-4">
                        <input type="text" placeholder="Email address" className="md:w-[400px] lg:w-[495px] bg-transparent pl-5 border text-white placeholder-white border-white w-full h-[50px] rounded-2xl" />
                        <button className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]">Subscribe</button>
                      </div>
                    </div>
                    <div>
                      <img src={require("assets/img/downrrow.png")} />
                    </div>
                    <div className="relative">
                      <img src={require("assets/img/squad2.png")} />
                      <img src={require("assets/img/squad.png")} className="absolute z-40 top-4" />
                    </div>
                  </div>
                </Container>
              </div>
            </Container>
            <div className="">
              <img src={require("../../assets/img/icon.png")} className="absolute right-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] transform scale-x-[-1] z-[-10]" />
            </div>
          </section> */}
        </div>
      </div>

      <Footerbn />
    </>
  );
}

export default ContactUs;
