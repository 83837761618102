import React from 'react';

const Payment = () => {
  const createConnectAccount = async () => {
    try {
      // POST request for creating connect account
      const response = await fetch('http://localhost:3001/create-connect-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Parse the JSON response from server
      const data = await response.json();
      const { accountId } = data;

      // Redirect the user to the Stripe onboarding page
      window.location.href = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=http://localhost:3000&client_id=${accountId}&state=onboarding`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button onClick={createConnectAccount}>
      Connect with Stripe
    </button>
  );
};

export default Payment;
