import { Account } from 'components/Account'
import React from 'react'

export const HostAccount = () => {
    const role = 'host'
    return (
        <>
            <Account role={role} />
        </>
    )
}
