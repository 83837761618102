import React from "react";

export default function ListingColor({ bg, text, color, btn, hideButton, images }) {
  return (
    <>
      <div
        className={`${bg} grid center-styl  rounded h-full  gap-8 lg:px-32 xs:px-8 pt-20 lg:pt-0`}

      >
        <img
          src={require("assets/img/icon.png")} alt=" "
          className="lg:w-38 lg:h-38 xs:w-20 xs:h-20  rounded object-contain"
        ></img>

        {hideButton ? null : (
          <button className={`bg-[#FFFFFF] rounded-full text-black w-[150px] h-[40px] flex justify-center items-center border-1`} >
            {btn}
          </button>)}


        <h1
          className={`font-bold lg:text-3xl xs:text-[25px] leading-[46.8px] tracking-[0.02em] font-avenir ${color}`}
        >
          {text}
        </h1>
        <div className="relative">
          {images && images.length > 0 && (
            <img src={images[0]} className="absolute" alt="Image 1" />
          )}
          {images && images.length > 1 && <img src={images[1]} alt="Image 2" />}
        </div>
        <div className="absolute left-0 bottom-0">
          <img
            src={require("assets/img/bgIcon.png")}
            alt="icon"
            className="w-full h-auto rounded object-cover"
          />
        </div>
      </div>
    </>
  );
}
