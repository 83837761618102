import { Container } from "@mui/material";
import DatePickerLocalize from "components/DatePicker/DatePickerLocalize";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";
import moment from "moment";
import WishlistButton from "common/buttons/WishlistButton/WishlistButton";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';


const WishList = () => {
  const navigate = useNavigate();
  const [favourite, setFavourite] = useState([]);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false); // State to track if filter is applied
  const [loading, setLoading] = useState(true); // State to track loading

  // Fetches all available properties and filters them based on the user's favorites.
  const getproperty = async () => {
    let res = await userServices.commonGetService(
      `/property/getAllPropertyAvailable`
    );

    // Filter properties to include only those that are in the user's favorite list
    const filteredData =
      res.data.data &&
      res.data.data.filter(
        (item) =>
          item.favouriteList &&
          item.favouriteList.some(
            (fav) => fav.userId === localStorageData("_id")
          )
      );
    setFavourite(filteredData);
    setLoading(false); // Set loading to false once data is fetched

  };
  useEffect(() => {
    getproperty();
  }, []);

  //navigating to the property detail page
  const handleClick = (propertyId) => {
    navigate(`/propertydetails/${propertyId}`);
  };

  // Filters the favorite properties based on check-in and check-out dates
  const handleFilter = () => {
    let availabile = favourite;

    if (checkIn && checkOut) {
      availabile = favourite.filter((item) => {
        if (item.bookDates.length > 0) {
          return !item.bookDates.some((date) => {
            const startDate = moment(date.start);
            const endDate = moment(date.end);
            const formattedCheckIn = moment(checkIn).startOf("day");
            const formattedCheckOut = moment(checkOut).startOf("day");

            return (
              formattedCheckIn.isBetween(startDate, endDate, null, "[]") ||
              formattedCheckOut.isBetween(startDate, endDate, null, "[]")
            );
          });
        }
      });
      setFilterApplied(true); // Filter is applied

    }
    setFavourite(availabile)
  };

  //resetting the filter to get all the favorite properties again
  const resetFilter = () => {
    getproperty();
    setFilterApplied(false); // Reset filterApplied state

  }

  useEffect(() => {
    resetFilter();
  }, []);

  return (
    <>
      <section>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <div>
              <h1 className="font-semibold text-2xl py-4">WishList</h1>
              <p className="font-bold text-xl">Confirm Availability</p>
            </div>
            <div className="flex gap-5 pb-6  items-center ">
              <div>
                <DatePickerLocalize
                  checkIn={checkIn}
                  setCheckIn={setCheckIn}
                  checkOut={checkOut}
                  setCheckOut={setCheckOut}
                />
              </div>
              <div className="flex gap-3">
                <button
                  className="bg-blue-500 px-6 py-2 rounded-full text-white text-xl "
                  onClick={handleFilter}

                >
                  Filter
                </button>
                <button
                  className={`bg-blue-500 px-6 py-2 rounded-full text-white text-xl ${!filterApplied ? 'opacity-50 cursor-not-allowed bg-gray-500' : '' // Apply custom styling when button is disabled
                    }`}
                  onClick={resetFilter}
                  disabled={!filterApplied} // Disable the button if filter is not applied

                >
                  Reset
                </button>
              </div>
            </div>

            {loading ? (<p className="text-lg font-bold">Loading...</p>) :
              favourite.length === 0 ? (<p className="text-gray-600 font-sans text-xl">Sorry, there are currently no favorite properties/items in your wishlist. <span className="text-gray-600 font-bold font-avenir">Happy browsing!</span></p>) :
                (
                  <div className="flex flex-wrap gap-5">
                    {favourite.map((item) => {

                      const ratings = item.reviews.map((review) => review.rating);
                      const total = ratings.reduce((acc, rating) => acc + rating, 0);
                      const averageRating = ratings.length > 0 ? Number((total / ratings.length).toFixed(1)) : 0;

                      const minPoint = Array.isArray(item.points) && item.points.length > 0
                        ? item.points.reduce((min, p) => (p.point < min ? p.point : min), item.points[0].point)
                        : null;

                      return (
                        <div className="group sm:w-[32%] md:w-[24%] border border-color-grey relative rounded-xl overflow-hidden">
                          <div className="relative h-[200px] w-full">
                            <img
                              src={item.pics[0]}
                              className="object-cover w-full h-full"
                              alt=""
                            />

                            <span className="absolute top-2 right-2 px-4 py-1 bg-color-primary text-white text-sm rounded-md cursor-pointer">
                              Availabel
                            </span>
                          </div>
                          <div className="px-2 pt-2">
                            <div className="flex justify-between">
                              {averageRating > 0 ?
                                <div className="flex items-center gap-2">
                                  <Rating
                                    initialRating={averageRating}
                                    readonly
                                    emptySymbol={<FaRegStar color="#d3d3d3" />}
                                    halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                    fullSymbol={<FaStar color="#ffd700" />}
                                    fractions={2}
                                  />
                                  <span>({averageRating})</span>
                                </div>
                                :
                                <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                              }
                              <div className="">
                                <WishlistButton
                                  propertyId={item._id}
                                  favourite={true} // You can adjust this value based on your logic
                                />
                              </div>
                            </div>
                            <div style={{ height: "3.3rem" }} className="mb-2"> {/* 2 lines height */}
                              <h4
                                className="text-[#000000] text-lg font-bold cursor-pointer hover:text-blue-500 overflow-hidden"
                                style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}

                              >
                                {item.title}
                              </h4>
                            </div>
                            <div className="text-[#AFB5C1] w-52">
                              <span className="text-base font-normal ">
                                {item.spaceTypeDetail &&
                                  item.spaceTypeDetail.bathrooms &&
                                  item.spaceTypeDetail.bedrooms &&
                                  item.spaceTypeDetail.guests && (
                                    <>
                                      Guests:{" "}
                                      {item.spaceTypeDetail.guests || 0}{" | "}
                                      Bedrooms:{" "}
                                      {item.spaceTypeDetail.bedrooms || 0}{" "}
                                      {/* Kitchens:{" "}
                                      {item.spaceTypeDetail.kitchen || 0}{" | "} */}
                                      Bathrooms:{" "}
                                      {item.spaceTypeDetail.bathrooms || 0}
                                    </>
                                  )}
                              </span>
                            </div>

                            <hr />
                            <div className="my-1 flex justify-between items-center">
                              <p className="text-sm">From</p>
                              <p>
                                <span className="text-color-grey text-xs line-through">
                                  {minPoint}
                                </span>{" "}
                                <span className="text-color-red">{minPoint} Points</span>{" "}
                              </p>
                            </div>
                          </div>
                          <div className="absolute group-hover:block hidden top-0 left-0 w-full h-full bg-color-primary/80 opacity-0 transition-opacity hover:opacity-100">
                            <div className="flex flex-col w-full h-full justify-center items-center ">
                              <button
                                className="border border-white rounded-full  text-white text-sm px-4 py-1 mt-2"
                                onClick={() => handleClick(item._id)}
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
          </Container>
        </Container>
      </section>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default WishList;
