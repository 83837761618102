import React from "react";

const Careers = () => {
  return (
    <div className="bg-gray-100 py-12 px-6 sm:px-12 lg:px-24">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Join BnByond
        </h2>
        <p className="text-gray-700 leading-relaxed mb-4">
          <span className="font-semibold">BnByond</span> is committed to creating a diverse and inclusive work environment. We value active listening, open communication, and understanding the needs and aspirations of our staff and partners.
        </p>
        <p className="text-gray-700 leading-relaxed mb-4">
          BnByond is always interested in speaking with talented individuals who are passionate about the short-term rental (STR) industry and who are dedicated to enhancing the BnByond community.
        </p>
        <p className="text-gray-700 leading-relaxed mb-4">
          While there are no immediate job openings, BnByond encourages interested individuals to submit their resumes, which will be kept on file for future consideration. BnByond is building a team of experienced professionals in tech, hospitality, and marketing.
        </p>
        <p className="text-gray-700 leading-relaxed mb-4">
          BnByond  believe that a diverse team brings a range of perspectives and experiences that are essential to our success. BnByond is committed to fostering a welcoming and supportive work environment where all employees feel valued and respected.
        </p>
        <p className="text-gray-700 leading-relaxed mb-4">
          If you are passionate about the short-term rental industry and are looking for a challenging and rewarding opportunity to make a difference, BnByond encourage you to submit your resume to:
        </p>
        <div className="bg-gray-50 p-4 rounded-md shadow-inner text-gray-800">
          <p className="font-semibold">
            Email: <a href="mailto:info@bnbyond.com" className="text-blue-500 hover:underline">info@bnbyond.com</a>
          </p>
          <p>Subject: HR Department - Resume</p>
        </div>
        <p className="text-gray-700 leading-relaxed mt-4">
          BnByond  appreciate your interest in joining our team and look forward to hearing from you.
        </p>
      </div>
    </div>
  );
};

export default Careers;
