import React from "react";
import { ButtonOutlined } from "common/buttons/buttonOutlined";
import termsAndConditions from "../../../common/commonText/TermsAndCondition";
import { Link } from "react-router-dom";

const Linker = ({ content, to }) => {
  return (
    <Link to={`${to}`} className="text-blue-500">
      {content}
    </Link>
  );
};

const TermsAndConditions = () => {
  return (
    <div className="md:container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-2/3">
          {/* <div className="bg-white rounded-lg p-6 shadow-md">
            <h1 className="text-3xl font-bold  mb-8">
              Terms and Conditions
            </h1>
            <p className="text-gray-800 font-normal text-sm mb-4">
              Last updated: July 4th, 2023
            </p>
            {termsAndConditions.map((item, index) => {
              if (typeof item === "string") {
                return (
                  <p
                    className="text-gray-800 text-justify my-2 text-sm"
                    key={index}
                  >
                    {item}
                  </p>
                );
              } else if (typeof item === "object" && item.heading) {
                return (
                  <h2 className="text-xl font-semibold my-4" key={index}>
                    {item.heading}
                  </h2>
                );
              }
              return null;
            })}
          </div> */}
          <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen p-1 rounded-xl">
            {/* <div> */}
            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
              <div className="p-3 md:p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                  Terms and Conditions
                </h1>
                <h1 className="text-xl font-bold text-gray-900 mb-6">
                  Last Revised: 12/02/2024
                </h1>

                <p className="text-gray-700 mb-8">
                  Welcome to <Linker content="BnByond.com!" to="/" /> These
                  Terms of Service (“Terms”) are a binding contract between you
                  and Great Big Brains, Inc. (“Great Big Brains”, “we”, “us”, or
                  “our”), owner and operator of{" "}
                  <Linker content="BnByond.com" to="/" /> (“Service” or
                  “{<Linker content="BnByond.com" to="/" />}”). Please read these Terms carefully, as they
                  govern your use of the Service and your participation in the{" "}
                  <Linker content="BnByond.com" to="/" /> community.
                </p>
                <p className="text-gray-700 mb-8">
                  By using <Linker content="BnByond.com" to="" />, you agree to
                  these Terms, which incorporate our Privacy Statement,
                  available at <Linker content="privacy policy" to="/privacy" />
                  . If you do not agree to these Terms, do not use the Service.
                </p>

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    1. Our Service
                  </h2>

                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    <Linker content="BnByond.com" to="/" /> is an online
                    platform that connects vacation rental property owners
                    (“Members”) to exchange stays at each other's properties
                    using a points-based system. We act as a venue to facilitate
                    these exchanges, but we are not a party to any rental
                    agreements between Members, nor are we a travel agency or
                    provider of travel packages.
                  </p>

                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    2. Eligibility
                  </h2>

                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    To be eligible to use the Service, you must:
                  </p>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>Be at least 18 years old.</li>
                    <li>
                      Own a short-term rental property that is actively listed
                      on a reputable platform (currently Airbnb and VRBO).
                      Verification of property ownership via listing code is
                      required during the onboarding process.
                    </li>
                    <li>
                      Agree to abide by these Terms and our Community Guidelines
                      (see Section 8 below).
                    </li>
                  </ul>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    3. Membership and Accounts
                  </h2>

                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    To access all features of the Service, you must create an
                    account (“Account”). You are responsible for maintaining the
                    confidentiality of your Account login information and for
                    all activity that occurs under your Account.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    3.1 Membership Tiers
                  </h2>

                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    To access all features of the Service, you must create an
                    account (“Account”). You are responsible for maintaining the
                    confidentiality of your Account login information and for
                    all activity that occurs under your Account.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    3.2 Account Verification
                  </h2>

                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    You may be required to verify your Account by providing us
                    with certain information, including a government-issued ID.
                    This helps us maintain the security and integrity of the{" "}
                    <Linker content="BnByond.com" to="/" /> community.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    3.3 Account Termination
                  </h2>

                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    We may terminate your Account for any reason, at any time,
                    without notice. We may also suspend your Account if you
                    violate these Terms or our Community Guidelines. You can
                    appeal a decision to remove content or terminate your
                    Account by contacting us.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    4. Points System
                  </h2>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    4.1 Earning Points
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Members earn points by making their properties available for
                    stays by other Members. Points are earned based on the
                    length of stay and the point value of the property. The
                    number of points you earn is based on your Membership Tier.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    4.2 Using Points
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Points can be used to book stays at other Members'
                    properties. The point value of a stay is determined by the
                    host Member and must be consistent with their advertised
                    rates on other platforms. One point is accepted as being of
                    the same value as one US dollar.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    4.3 Point Expiration
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Points do not expire as long as your Membership is active.
                    If your Membership is terminated or expires, your points may
                    be forfeited.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    5. Payment Processing
                  </h2>

                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    All financial transactions on{" "}
                    <Linker content="BnByond.com" to="/" />{" "}
                     are processed through secure third-party payment providers.
                    We do not store your credit card information.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    6. Booking and Stays
                  </h2>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.1 Booking Process
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    To book a stay, you must submit a booking request to the
                    host Member through the Service. The host Member has the
                    right to accept or decline your request.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.2 Booking Confirmation
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Once your booking request is accepted, you will receive a
                    booking confirmation (“Booking Confirmation”). The Booking
                    Confirmation will include the details of your stay,
                    including the point value.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.3 Payment
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Payment for stays is made using points. The required points
                    are deducted from your Account at the time of booking and
                    held in reserve until the stay is complete.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.4 Cancellations and Changes
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Cancellations and changes to bookings are subject to the
                    host Member's cancellation policy, which is displayed on
                    their property listing. If you cancel a booking, the number
                    of points refunded to you will be determined by the host
                    Member's cancellation policy.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.5 Guest Responsibilities
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Guests are responsible for:
                  </p>
                  <ul className="list-disc pl-12 mb-4 text-gray-700 space-y-2">
                    <li>Treating the host Member's property with respect</li>
                    <li>Complying with all applicable laws and regulations.</li>
                    <li>
                      Leaving the property in the condition it was found in.
                    </li>
                  </ul>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    6.6 Host Responsibilities
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Hosts are responsible for:
                  </p>
                  <ul className="list-disc pl-12 mb-4 text-gray-700 space-y-2">
                    <li>
                      Providing accurate and complete information about their
                      property.
                    </li>
                    <li>
                      Maintaining their property in a safe and habitable
                      condition.
                    </li>
                    <li>Complying with all applicable laws and regulations.</li>
                  </ul>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    7. Content
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    “Content” means all text, descriptions, reviews,
                    photographs, images, videos, software, and other content
                    submitted to our Service. You are solely responsible for any
                    Content you submit to the Service. You represent and warrant
                    that you own all rights to the Content or have obtained all
                    necessary licenses and permissions to use the Content. You
                    agree not to submit any Content that:
                  </p>
                  <ul className="list-disc pl-12 mb-4 text-gray-700 space-y-2">
                    <li>
                      Is illegal or violates any applicable laws or regulations.
                    </li>
                    <li>
                      Infringes on the intellectual property rights of any third
                      party.
                    </li>
                    <li>
                      Is defamatory, obscene, offensive, or otherwise
                      inappropriate.
                    </li>
                    <li>Contains viruses or other malicious code.</li>
                    <li>
                      Contains personal information about any third party
                      without their consent.
                    </li>
                  </ul>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    7.1 Content Removal
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    We reserve the right to remove any Content from the Service,
                    at any time, for any reason, without notice.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    7.2 Reviews
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Members may submit reviews of their stays at other Members'
                    properties. Reviews must comply with our Community
                    Guidelines. We may remove reviews that violate our Community
                    Guidelines.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    8. Community Guidelines
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    <Linker content="BnByond.com!" to="/" /> is a community
                    built on trust and respect. We encourage Members to interact
                    with each other in a positive and constructive manner.
                  </p>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    8.1 Prohibited Conduct
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    The following conduct is prohibited on the Service:
                  </p>
                  <ul className="list-disc pl-12 mb-4 text-gray-700 space-y-2">
                    <li>Harassment, bullying, or threats of violence.</li>
                    <li>
                      Discrimination based on race, religion, gender, sexual
                      orientation, or any other protected characteristic.
                    </li>
                    <li>Spamming or unsolicited commercial communications.</li>
                    <li>Fraudulent or deceptive activity.</li>
                    <li>Violation of any applicable laws or regulations.</li>
                  </ul>
                </section>
                <section>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    8.2 Consequences of Violation
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    We may take any action we deem appropriate, including
                    suspending or terminating your Account, if you violate our
                    Community Guidelines.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    9. Liability
                  </h2>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    9.1 Disclaimer of Warranties
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    THE SERVICE IS PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY
                    KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT
                    THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                    DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE IS FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    9.2 Limitation of Liability
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT SHALL GREAT BIG BRAINS, INC. BE LIABLE FOR ANY DIRECT,
                    INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
                    DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
                    PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES,
                    ARISING OUT OF OR IN CONNECTION WITH THE SERVICE, WHETHER
                    BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                    ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    9.3 Indemnification
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    You agree to indemnify, defend, and hold harmless Great Big
                    Brains, Inc., its officers, directors, employees, agents,
                    licensors, and suppliers from and against any and all
                    claims, demands, losses, liabilities, costs, and expenses
                    (including attorneys' fees) arising out of or relating to:
                  </p>
                  <ul className="list-disc pl-14 mb-4 text-gray-700 space-y-2">
                    <li>Your use of the Service.</li>
                    <li>Your violation of these Terms.</li>
                    <li>
                      Your violation of any applicable laws or regulations.
                    </li>
                    <li>
                      Your infringement of the intellectual property rights of
                      any third party.
                    </li>
                  </ul>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    10. Intellectual Property
                  </h2>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    10.1 Ownership
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    The Service and all content and materials included on or
                    available through the Service, including, but not limited
                    to, text, graphics, logos, button icons, images, audio
                    clips, digital downloads, data compilations, and software,
                    are the property of Great Big Brains, Inc. or its licensors
                    and are protected by United States and international
                    copyright laws.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    10.2 License Grant
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    We grant you a limited, non-exclusive, non-transferable
                    license to access and use the Service for your personal,
                    non-commercial use. This license does not include any resale
                    or commercial use of the Service or its contents; any
                    collection and use of any product listings, descriptions, or
                    prices; any derivative use of the Service or its contents;
                    any downloading or copying of account information for the
                    benefit of another merchant; or any use of data mining,
                    robots, or similar data gathering and extraction tools.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    10.3 Trademarks
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    BnByond and the BnByond logo are trademarks of Great Big
                    Brains, Inc. All other trademarks, service marks, logos, and
                    trade names appearing on the Service are the property of
                    their respective owners.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    11. Termination
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    We may terminate your access to all or any part of the
                    Service at any time, with or without cause, with or without
                    notice, effective immediately. If you wish to terminate
                    these Terms, you may do so by discontinuing your use of the
                    Service. All provisions of these Terms which by their nature
                    should survive termination shall survive termination,
                    including, without limitation, ownership provisions,
                    warranty disclaimers, indemnity and limitations of
                    liability.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    12. Governing Law and Jurisdiction
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    These Terms shall be governed by and construed in accordance
                    with the laws of the Province of Nova Scotia, Canada,
                    without regard to its conflict of laws provisions. You and
                    Great Big Brains, Inc. agree to submit to the personal and
                    exclusive jurisdiction of the courts located within the
                    county of HRM, Nova Scotia, Canada
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    13. Dispute Resolution
                  </h2>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    13.1 Mandatory Pre-Arbitration Dispute Resolution
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Prior to initiating any arbitration or court proceeding, you
                    agree to first contact us in writing and attempt in good
                    faith to resolve any dispute, claim, or controversy arising
                    out of or relating to these Terms or the Service. You may
                    send your written notification to Great Big Brains, Inc.
                  </p>
                  <pre className="pl-10 text-gray-700 space-y-2 max-w-md text-sm leading-6 whitespace-pre-wrap">
                    Attention: BnByond/Great Big Brains Legal Department <br />
                    168 Hobsons Lake Rd Suite 301, <br />
                    NS, Canada. <br />
                    B3S 1A2
                  </pre>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    If we are unable to resolve the dispute within 60 days of
                    your written notification, you may then proceed to initiate
                    an arbitration or court proceeding as set forth below.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    13.2 Arbitration
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    Any and all disputes arising out of or relating to these
                    Terms, the Service, or your relationship with Great Big
                    Brains, Inc., that are not resolved through the mandatory
                    pre-arbitration dispute resolution process described above,
                    shall be resolved by a binding arbitration. The arbitration
                    shall be held in Halifax, Nova Scotia, unless you and Great
                    Big Brains, Inc. agree otherwise.
                  </p>
                  <h2 className="pl-6 text-xl font-semibold text-gray-900 mb-4">
                    13.3 Class Action Waiver
                  </h2>
                  <p className="pl-10 mb-4 text-gray-700 space-y-2">
                    YOU AND GREAT BIG BRAINS, INC. AGREE THAT EACH MAY BRING
                    CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
                    CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                    PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    14. Entire Agreement
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    These Terms constitute the entire agreement and
                    understanding between you and Great Big Brains, Inc. with
                    respect to the Service and supersede all prior or
                    contemporaneous communications and proposals, whether oral
                    or written, between you and Great Big Brains, Inc.
                  </p>
                </section>
                <section>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    15. Contact Us
                  </h2>
                  <p className="pl-6 mb-4 text-gray-700 space-y-2">
                    If you have any questions about these Terms, please contact
                    us at:
                  </p>
                  <pre className="pl-6 text-gray-700 space-y-2 max-w-md text-sm leading-6 whitespace-pre-wrap">
                    BnByond/Great Big Brains, Inc. <br />
                    Attention: Legal Department <br />
                    168 Hobsons Lake Rd Suite 301 <br />
                    Halifax, NS, Canada. <br />
                    B3S 1A2
                  </pre>
                  <p className="pl-6 mb-4 text-gray-700 mt-3">
                    Thank you for choosing <Linker content="BnByond.com!" to="/" />
                  </p>
                  <p className="pl-6 mb-4 text-gray-700">
                    Please note that these Terms of Service are subject to
                    change at any time without notice. It is your responsibility
                    to review these Terms periodically for any updates or
                    changes. Your continued use of the Service following the
                    posting of any changes to these Terms constitutes your
                    acceptance of those changes.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-lg p-6 shadow-md sticky top-10">
            <h2 className="text-lg font-medium mb-4">Need to get in touch?</h2>
            <p className="text-gray-600 mb-4 text-sm font-normal">
              We’ll start with some questions and get you to the right place.
            </p>
            <Link to="/contactus" className="text-center">
              <ButtonOutlined text="Contact Us" />
            </Link>
            <p className="text-gray-600 mt-4 text-sm font-normal">
              You can also{" "}
              <Link to="#" className="text-black font-semibold underline">
                give us feedback
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
