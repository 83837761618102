import React, { useState } from 'react'
import { Container } from "@mui/material";
import PropertyListSteps from './PropertyListSteps';

const GuestProperty = () => {
  const [active, setActive] = useState(1);
  const [ActiveProperties, setActiveProperties] = useState([]);
  const [PendingProperties, setPendingProperties] = useState([]);
  const [RejectedProperties, setRejectedProperties] = useState([]);
  const handleActive = (num) => {
    setActive(num);
  };
  return (
    <>
      <section>
        <Container maxWidth="xl">
          <Container maxWidth="xl">
            <div className="my-4">
              <h1 className="text-xl font-medium ml-4">My Properties</h1>
            </div>
            <div className=" md:p-4 p-1">
              <div className="flex items-center flex-wrap border-b border-b-color-grey relative ">
                <p
                  onClick={() => handleActive(1)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mr-2 relative -bottom-[0.5px] ${active == 1
                      ? "border-b-color-yellow text-color-black"
                      : "border-b-transparent"
                    } `}
                >
                  Pending List
                </p>
                <p
                  onClick={() => handleActive(2)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 2
                      ? "border-b-color-yellow text-color-black"
                      : "border-b-transparent"
                    } `}
                >
                  Active List
                </p>
                <p
                  onClick={() => handleActive(3)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 3
                      ? " border-b-color-yellow text-color-black"
                      : "border-b-transparent"
                    }`}
                >
                  Rejected List
                </p>
              </div>
              <PropertyListSteps
                active={active}
                ActiveProperties={ActiveProperties}
                PendingProperties={PendingProperties}
                RejectedProperties={RejectedProperties}
              />
            </div>
          </Container>
        </Container>
      </section>
    </>
  )
}

export default GuestProperty