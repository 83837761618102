import * as React from "react";
import { useMutation } from "react-query";
import Menu from "@mui/material/Menu";
import { localStorageData } from "services/auth/localStorageData";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { storeLocalData } from "services/auth/localStorageData";
import { useChat } from "common/contexts/chatConversationsContext";

const Dropdown = () => {

  let navigate = useNavigate();
  let location = useLocation()
  let loginAs = ("localStorageData", localStorageData("loginAs"))
  let userId = ("localStorageData", localStorageData("_id"))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openNotificationMenu, setOpenNotificationMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(openNotificationMenu);
  const { conversationData2, isLoading2, error, fetchConversations } = useChat();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event) => {
    setOpenNotificationMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenNotificationMenu(null);
  };
  const handleChangeRole = (role) => {
    mutate({ loginAs: role, userId })
  }

  // API call for updating the user role
  const { mutate, isLoading } = useMutation(
    (token) =>
      userServices.updatePostService("/userAuth/updateRole", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        storeLocalData(data?.data.user);
        toast.success("User role changed successfully");
        navigate("/");
      },
    }
  );

  return (
    <>
      <div className="flex items-center gap-2">

        <a onClick={handleClick}>
          <div className={`${location.pathname == '/' ? 'bg-white' : 'border border-[#C1E1C2]'} flex justify-center gap-1 items-center rounded-full w-[160px] h-[40px]`}>

            <button className="text-[#AEAFB0] text-base font-normal">
              LAST 30 DAYS
            </button>
            <div>
              <IoIosArrowDown className="text-[#AEAFB0] mr-1" />
            </div>
          </div>
        </a>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-[300px] p-4 ">
            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"

            >
              Menu
            </div>
            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"

            >
              Menu
            </div>
            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose(); // Close the menu
                navigate("/menu");
              }}
            >
              Menu
            </div>

          </div>
        </Menu>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={openNotificationMenu}
          open={openNotification}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >

        </Menu>
      </div>
    </>
  );
};

export default Dropdown;
