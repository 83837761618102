import React from 'react';
import { useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import Divider from '@mui/material/Divider';
import { MdEditNote } from "react-icons/md";

const RejectedProperty = ({ rejectedProperty }) => {
  const navigate = useNavigate();

  //claculate rating
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  return (
    <div className="flex flex-wrap gap-6 pt-6">
  {rejectedProperty.length > 0 ? (
    rejectedProperty.map((property) => {
      const averageRating = calculateAverageRating(property.reviews || []);

      const minPoint = Array.isArray(property.points) && property.points.length > 0
        ? property.points.reduce((min, p) => (p.point < min ? p.point : min), property.points[0].point)
        : null;

      return (
        <div key={property._id} className="md:w-[265px] w-full">
          <div className="relative cursor-pointer md:w-[265px] rounded-t-xl w-full h-[200px] bg-center bg-cover group"
            style={{
              backgroundImage: `url(${property.pics[0]})`,
            }}
            onClick={() => navigate(`/propertydetails/${property._id}`)}
          >
            {/* Edit Button - Hidden by default, shown on hover */}
            <button
              className="absolute top-2 right-2 hidden group-hover:flex items-center justify-center bg-blue-500 text-white rounded-full w-10 h-10 shadow-lg"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/propertylisting`, { state: property });
              }}
            >
              <MdEditNote className="w-8 h-8" title="Edit Property" />
            </button>
          </div>
          <div className="md:w-[265px] w-full py-3 h-auto rounded-b-xl bg-white border flex flex-col justify-between">
            <div className="px-4">
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  {averageRating > 0 ? (
                    <div className="flex items-center">
                      <Rating
                        initialRating={averageRating}
                        readonly
                        emptySymbol={<FaRegStar color="#d3d3d3" />}
                        halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                        fullSymbol={<FaStar color="#ffd700" />}
                        fractions={2}
                      />
                      <span>({averageRating})</span>
                    </div>
                  ) : (
                    <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                  )}
                </div>
              </div>
              <div style={{ height: "3.3rem" }} className="mb-2">
                <h4
                  className="text-[#000000] text-lg font-bold cursor-pointer hover:text-blue-500 overflow-hidden"
                  style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                  onClick={() => navigate(`/propertydetails/${property._id}`)}
                >
                  {property.title}
                </h4>
              </div>
              <div>
                <h4 className="text-[#000000] text-base font-medium">
                  {property.address.length > 25 ? property.address.substring(0, 25) + '...' : property.address}
                </h4>
              </div>
              <div className="text-[#AFB5C1]">
                <span className="text-base font-normal">
                  {property.spaceTypeDetail &&
                    property.spaceTypeDetail.bathrooms &&
                    property.spaceTypeDetail.bedrooms &&
                    property.spaceTypeDetail.guests && (
                      <>
                        Guests:{" "}
                        {property.spaceTypeDetail.guests || 0}{" | "}
                        Bedrooms:{" "}
                        {property.spaceTypeDetail.bedrooms || 0}{" "}
                        {/* Kitchens:{" "}
                        {property.spaceTypeDetail.kitchen || 0}{" | "} */}
                        Bathrooms:{" "}
                        {property.spaceTypeDetail.bathrooms || 0}
                      </>
                    )}
                </span>
              </div>
            </div>
            <div className="pt-2">
              <Divider />
            </div>
            <div className="px-4 pb-2">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-[#AFB5C1] text-base font-normal">From</p>
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-[#AFB5C1] text-sm line-through">
                    {minPoint}
                  </span>
                  <span className="text-[#C0392D] text-base">
                    {minPoint} Points
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div className='flex flex-col justify-center items-center gap-3'>
      <p className='font-bold text-center'>No Rejected Properties</p>
      <p className='text-[#AFB5C1]'> All properties have been approved. Thank you!</p>
    </div>
  )}
</div>

  );
};

export default RejectedProperty;
