export const config = {
    endPoint: process.env.REACT_APP_ENDPOINT,
    baseUrl: process.env.REACT_APP_BASEURL,
    ImageEndPoint: process.env.REACT_APP_IMAGEENDPOINT,
    localToken: process.env.REACT_APP_LOCALTOKEN,
    mapkey: process.env.REACT_APP_MAPKEY,
    googleapi: process.env.REACT_APP_GOOGLEAPI,
    facebookapi: process.env.REACT_APP_FACEBOOKAPI,
    SECRET_ENCRYPTION_KEY: process.env.REACT_APP_SECRET_ENCRYPTION_KEY,
    Standard_ClientId: process.env.REACT_APP_STANDARD_CLIENTID,
    textEditorTinyKey: process.env.REACT_APP_TEXTEDITORTINYKEY,
    stripe_publishkey: process.env.REACT_APP_STRIPE_PUBLISHKEY,
};
  
export default config;
