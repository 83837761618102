import http from "../http-common";

class userService {
  signUp(data) {
    return http.post("/register", data);
  }
  login(data) {
    return http.post("/login", data);
  }

  applyForForgetPass(data) {
    return http.post("/applyforgetpass", data);
  }

  updatePass(data) {
    return http.post("/updatepassword", data);
  }
  userGetbyId(data) {
    return http.post("/userget", data);
  }

  commonPostService(route, data) {
    return http.post(route, data);
  }
  communityhost(route, data) {
    return http.post(route, data);
  }
  updatePropertyById(route, data) {
    return http.put(route, data);
  }
  addReviewToProperty(route, data) {
    return http.put(route, data);
  }
  Blogpost(route, data) {
    return http.post(route, data);
  }
  getBlogPost(route, data) {
    return http.get(route, data);
  }
  communityReplyPost(route, data) {
    return http.post(route, data);
  }
  communitylikePost(route, data) {
    return http.post(route, data);
  }
  communitydeletePost(route, data) {
    return http.delete(route, data);
  }
  communityGethost(route, data) {
    return http.get(route, data);
  }
  updatePostService(route, data) {
    return http.put(route, data);
  }
  refundReservation(route, data) {
    return http.put(route, data);
  }
  updateReservation(route, data) {
    return http.put(route, data);
  }
  updateImage(route, data) {
    return http.post(route, data);
  }
  availabilityToogle(route, data) {
    return http.put(route, data);
  }
  deletePostService(route, data) {
    return http.delete(route, data);
  }
  otpService(route, data) {
    return http.put(route, data);
  }
  commonGetService(route, data) {
    return http.get(route, data);
  }
  commonPutService(route, data) {
    return http.put(route, data);
  }
  commonDeleteService(route, data) {
    return http.delete(route, data);
  }
  Reservation(route, data) {
    return http.post(route, data);
  }
  getReservationByuserId(route, data) {
    return http.get(route, data);
  }
  getReservationById(route, data) {
    return http.get(route, data);
  }
  Wishlist(route, data) {
    return http.put(route, data);
  }
  //chat routes
  getChatUsers(route, data) {
    return http.get(route, data);
  }
  createConversation(route, data) {
    return http.post(route, data);
  }
  getChatConversations(route, data) {
    return http.get(route, data);
  }
  getConversation(route, data) {
    return http.get(route, data);
  }
  fetchMessages(route, data) {
    return http.get(route, data);
  }
  sendMessageApi(route, data) {
    return http.post(route, data);
  }
  sendFileApi(route, data) {
    return http.post(route, data);
  }

  googleVerification(route, data) {
    return http.post(route, data);
  }
  userDatagetById(route, data) {
    return http.get(route, data);
  }
  userById(route, data) {
    return http.get(route, data);
  }
  userUpdateById(route, data) {
    return http.put(route, data);
  }

  adminLogin(route, data) {
    return http.post(route, data);
  }

  getReferralCode(route, data) {
    return http.post(route, data);
  }
  stripeConnect(route, data) {
    return http.post(route, data);
  }
  stripeRefund(route, data) {
    return http.post(route, data);
  }
  stripeauthorize(route, data) {
    return http.post(route, data);
  }
  getStripeTransactions(route) {
    return http.get(route);
  }
  recoveryEmail(route, data) {
    return http.put(route, data);
  }

  createEmployee(data) {
    return http.post("/employes/addEmploye", data);
  }

  getAllEmployees() {
    return http.get("employes/getEmploye");
  }

  editEmployee(employeeId, data) {
    return http.put(`/employes/employeRole/${employeeId}`, data);
  }

  getUserDeviceInfo(route) {
    return http.get(route);
  }

  getRefferalNumberCount() {
    return http.get("admin/refferalNumberCount");
  }
}

export default new userService();
