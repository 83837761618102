import React from "react";
import { ButtonOutlined } from "common/buttons/buttonOutlined";
import { Link } from "react-router-dom";

const PrivacyAndPolicy = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row p-4 lg:p-12 gap-5">
        <div className="w-full md:w-[60%]">
          {/* <div className=' text-gray-800'>
            <p className='font-normal text-sm mt-2 text-gray-800'>Welcome to bnbyoud privacy policy.</p>
          </div>
          <div className=' text-gray-800'>
            <p className='font-medium text-gray-700'>Last Updated: January 25, 2023</p>
            <p className='font-normal text-sm'>BnByond exists to help build connections between people and make the world more open and inclusive. In short—to build a world where anyone can belong anywhere. We are a community built on trust. A fundamental part of earning that trust means being clear about how we use your information and protecting your human right to privacy.</p><br />

          </div>
          <div className=' text-gray-800 font-normal text-sm'>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit reiciendis fuga aperiam soluta! Fugit, tempora magni! Corporis voluptate error earum possimus repellat!</p><br />
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore deserunt sequi nostrum sed quibusdam! Officia et aliquam dolore maxime sed impedit quisquam delectus provident illum, labore rem error praesentium accusamus, placeat ex voluptatum laborum. Ipsa ipsum autem vel omnis fugiat amet, dignissimos cupiditate culpa laudantium aperiam maxime, assumenda facere neque quidem! Veritatis ea, voluptatem laborum dolore eum nam velit libero. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae, veritatis in. Commodi veritatis quod distinctio excepturi mollitia quisquam amet sunt?</p><br />
            <p>1. Lorem ipsum</p>
            <p>1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae.</p><br />
            <p>1.3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae.</p><br />
            <p>1.4. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.5. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.6. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.7. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet, </p><br />
            <p>1.8. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet</p><br />
            <p>1.9. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet,</p><br />

          </div> */}
          <div
            className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen p-1 rounded-xl"
          >
            {/* <div> */}
            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
              <div className="p-3 md:p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                  Privacy Statement for BnByond
                </h1>

                <p className="text-gray-700 mb-8">
                  BnByond prioritizes the protection of your personal
                  information and is dedicated to maintaining your privacy. The
                  following Privacy Statement outlines BnByond's practices
                  regarding the collection, utilization, and disclosure of your
                  information when you engage with BnByond's platform and its
                  associated services. This Privacy Statement is intended to
                  provide you with a comprehensive understanding of BnByond's
                  data handling practices and your rights as a user.
                </p>

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    I. Information Collection
                  </h2>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    A. Information You Provide:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      When you establish an account with BnByond, you directly
                      furnish BnByond with personally identifiable information,
                      which may encompass your name, email address, telephone
                      number, and government-issued identification.
                    </li>
                    <li>
                      To enhance the security of the BnByond platform, you are
                      required to submit photographs of both the front and back
                      of your government-issued identification during the
                      account setup process.
                    </li>
                    <li>
                      Furthermore, to facilitate seamless financial
                      transactions, you will be required to provide your payment
                      information, including your credit card details. BnByond
                      employs encryption and adheres to contemporary security
                      standards to safeguard all financial data within the
                      platform.
                    </li>
                    <li>
                      To foster a sense of community and allow fellow BnByond
                      members to get to know you better, you are encouraged to
                      create a personal profile, including a photograph and a
                      brief description of yourself and your interests.
                    </li>
                    <li>
                      As a member of BnByond, you are obligated to list your
                      short-term rental property or properties on the platform.
                      This entails furnishing details about your property,
                      including photographs, amenities, and the desired points
                      value for each night of stay. It is imperative that the
                      points value you assign accurately reflects the cash price
                      at which you list your property on other established
                      platforms like Airbnb or VRBO. This policy ensures
                      fairness and transparency within the BnByond community.
                    </li>
                    <li>
                      BnByond may also gather data about your travel
                      preferences, such as preferred destinations, accommodation
                      types, and specific requirements you may have. This
                      information enables BnByond to personalize your experience
                      and offer tailored recommendations.
                    </li>
                    <li>
                      You also directly provide information related to your
                      interactions with other members on the platform, including
                      messages exchanged through the platform's communication
                      channels. This data helps ensure a safe and respectful
                      community environment.
                    </li>
                  </ul>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    B. Information We Collect Automatically:
                  </h3>
                  <p className="text-gray-700 mb-4">
                    As you utilize BnByond's platform, certain information is
                    collected automatically. This may include your IP address,
                    details about the device you are using, and your browsing
                    activities. BnByond employs cookies and analogous
                    technologies to gather such information, which contributes
                    to enhancing the functionality and user experience of the
                    platform.
                  </p>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    C. Information From Third Parties:
                  </h3>
                  <p className="text-gray-700 mb-4">
                    BnByond may obtain personal information from sources
                    external to the platform. This could include data from
                    affiliated entities within Great Big Brains, Inc, business
                    partners, and other third-party sources. The acquisition of
                    this information aims to bolster the platform's services,
                    maintain accurate records, identify potential fraudulent
                    activity, and refine marketing strategies.
                  </p>
                </section>

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    II. How We Use Your Information
                  </h2>
                  <p className="text-gray-700 mb-3">
                    BnByond employs the information it collects for various
                    purposes aimed at providing, improving, and securing its
                    services, as well as engaging in marketing activities:
                  </p>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    A. Providing and Improving Services:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond utilizes your information to process bookings,
                      manage payments, and establish and administer your
                      account.
                    </li>
                    <li>
                      Your information also enables BnByond to communicate with
                      you regarding your bookings, account updates, and any
                      necessary customer support.
                    </li>
                    <li>
                      To facilitate communication and foster a sense of
                      community, BnByond may utilize your information to connect
                      you with other BnByond members, especially when you are
                      booking a stay or hosting other members at your property.
                    </li>
                    <li>
                      BnByond may also analyze platform usage patterns and
                      trends to enhance the platform's user interface and tailor
                      the user experience.
                    </li>
                  </ul>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    B. Marketing and Advertising:
                  </h3>

                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond may employ your information for marketing
                      endeavors, including sending you promotional materials,
                      tailoring advertisements to your preferences, and
                      evaluating the efficacy of marketing campaigns.
                    </li>
                    <li>
                      However, BnByond will only send marketing communications
                      if you have explicitly consented to receive them.
                    </li>
                  </ul>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    C. Security and Compliance:
                  </h3>

                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond prioritizes the security of its platform and
                      users.
                    </li>
                    <li>
                      Your information is utilized to detect and prevent
                      fraudulent activities, ensure compliance with legal
                      obligations, protect BnByond's rights and interests, and
                      safeguard the well-being of its users.
                    </li>
                    <li>
                      This may involve sharing your information with law
                      enforcement agencies or other relevant authorities as
                      required by law.
                    </li>
                  </ul>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    III. How We Share Your Information
                  </h2>
                  <p className="text-gray-700 mb-3">
                    BnByond may share your information with specific third
                    parties to facilitate its services, comply with legal
                    obligations, and for other legitimate purposes. These
                    parties include:
                  </p>

                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    A. ther BnByond Members:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      When you book a stay through BnByond, your name and
                      contact details will be shared with the host of the
                      property.
                    </li>
                    <li>
                      Similarly, if you host guests, their information will be
                      shared with you to facilitate communication and ensure a
                      smooth hosting experience
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    B. Travel Suppliers:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      To process bookings for services such as flights and car
                      rentals, BnByond may share your information with travel
                      suppliers. These suppliers will only use your information
                      for the purpose of fulfilling your travel arrangements.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    C. Service Providers:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond engages third-party service providers to support
                      various aspects of its operations, including payment
                      processing, marketing, and IT services. These providers
                      are contractually obligated to handle your information
                      securely and only utilize it to perform the services they
                      have been contracted for by BnByond.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    D. Business Partners:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond may collaborate with other businesses to offer you
                      a wider array of products and services. Your information
                      may be shared with these partners to facilitate such
                      offerings, and you will be informed of the involvement of
                      these partners.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    E. Government and Law Enforcement:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond may disclose your information when legally
                      mandated to do so, for instance, in response to a
                      subpoena, court order, or other legal proceedings.
                      Additionally, your information may be shared in connection
                      with investigations or to safeguard BnByond's rights and
                      interests, as well as the safety of its users.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    F. Social Media Platforms:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      To refine its marketing strategies and reach a wider
                      audience, BnByond may share your information with social
                      media platforms. This practice is in line with industry
                      standards for online marketing.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    G. Other Third Parties:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      In the event of corporate transactions such as mergers,
                      acquisitions, or bankruptcy, BnByond may disclose your
                      information to the relevant parties involved.
                    </li>
                  </ul>
                </section>

                {/* Additional sections III through IX would be added here, following the same structure */}

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    IV. Your Rights and Choices
                  </h2>
                  <p className="text-gray-700 mb-2">
                    You have certain rights pertaining to your personal
                    information, including the right to access, rectify, and
                    request the deletion of your data. You can also opt out of
                    marketing communications from BnByond.
                  </p>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    A. Access and Correct Your Information:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      If you have a BnByond account, you can access and modify
                      your personal information directly through your account
                      settings. Alternatively, you can contact BnByond to
                      request access to or correction of your data.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    B. Delete Your Information
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      You have the right to request the deletion of your
                      personal information held by BnByond. While BnByond
                      endeavors to accommodate such requests, there may be
                      instances where BnByond is legally obligated to retain
                      certain information.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    C. Opt Out of Marketing Communications:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      You can choose to stop receiving marketing materials from
                      BnByond. To exercise this right, you can click the
                      "unsubscribe" link found in any marketing email you
                      receive from BnByond, modify your communication
                      preferences within your account settings, or contact
                      BnByond directly.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    D. Control the Use of Cookies:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      BnByond's Cookie Statement provides detailed guidance on
                      how you can manage and control the use of cookies on the
                      platform.
                    </li>
                  </ul>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    E. Additional Rights Based on Location:
                  </h3>
                  <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
                    <li>
                      Your location may grant you further rights related to your
                      personal data. These rights may include the ability to
                      request a copy of your personal information, object to
                      certain uses or disclosures of your information, restrict
                      the processing of your data, or transfer your information
                      to another service provider. These rights vary based on
                      the specific laws of your region.
                    </li>
                  </ul>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    V. Security
                  </h2>
                  <p className="text-gray-700 mb-2">
                    BnByond recognizes the importance of protecting your
                    personal information and implements stringent security
                    measures. The platform employs physical, technical, and
                    organizational safeguards to mitigate the risk of
                    unauthorized access, use, or disclosure of your data. These
                    measures are designed to ensure the confidentiality and
                    integrity of your information while in BnByond's possession.
                  </p>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    VI. Minors
                  </h2>
                  <p className="text-gray-700 mb-2">
                    BnByond's platform is intended for use by adults and is not
                    directed toward individuals under the age of 18. Should a
                    minor provide BnByond with personal information without
                    parental consent, the parent or guardian should contact
                    BnByond immediately to address the situation.
                  </p>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    VII. Record Retention
                  </h2>
                  <p className="text-gray-700 mb-2">
                    BnByond adheres to legal requirements regarding the
                    retention of personal information. BnByond will retain your
                    information for the duration necessary to fulfill the
                    purposes outlined in this Privacy Statement, unless a longer
                    retention period is mandated by applicable law.
                  </p>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    VIII. Contact Us
                  </h2>
                  <p className="text-gray-700 mb-2">
                    Should you have any queries, concerns, or requests regarding
                    this Privacy Statement or BnByond's data handling practices,
                    please do not hesitate to contact BnByond through the
                    contact information provided on the platform's website.
                  </p>
                </section>
                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    IX. Updates to This Statement
                  </h2>
                  <p className="text-gray-700 mb-2">
                    BnByond may amend this Privacy Statement to reflect changes
                    in applicable laws, technological advancements, or business
                    operations. In the event of substantial modifications, you
                    will be duly notified.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-lg p-6 shadow-md sticky top-10">
            <h2 className="text-lg font-medium mb-4">Need to get in touch?</h2>
            <p className="text-gray-600 mb-4 text-sm font-normal">
              We’ll start with some questions and get you to the right place.
            </p>
            <Link to="/contactus" className="text-center">
              <ButtonOutlined text="Contact Us" />
            </Link>
            <p className="text-gray-600 mt-4 text-sm font-normal">
              You can also{" "}
              <Link to="#" className="text-black font-semibold underline">
                give us feedback
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyAndPolicy;
