import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { FiCopy } from "react-icons/fi";

const InputWithCopy = ({ label, value, field, copiedField, handleCopy }) => {
  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-end gap-x-3 flex-1 w-full">
      <label
        htmlFor={field}
        className="block text-gray-700 font-medium mb-1"
      >
        {label}
      </label>
      <div className="relative w-full lg:w-2/3">
        <div className="relative flex items-center">
          <input
            id={field}
            type="text"
            value={value}
            readOnly
            className="w-full p-3 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600 truncate"
            style={{ paddingRight: "2.5rem" }} 
          />
          <button
            onClick={() => handleCopy(value, field)}
            className="absolute right-3 text-gray-500 hover:text-gray-700"
            style={{ top: "50%", transform: "translateY(-50%)" }}
          >
            {copiedField === field ? (
              <IoMdCheckmark size={20} title="Copied!" />
            ) : (
              <FiCopy size={20} title="Copy to Clipboard" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputWithCopy;
