import React from 'react'

export const TaxDocuments = () => {
  return (
    <div className='space-y-6 mt-6 h-[500px]'>
      <div className='space-y-1 text-[#4B4B4B]'>
        <p>Tax documents required for filing taxes are available to review and download here.</p>
        <p>You can also file taxes using detailed earnings info, available in the <span className="border-b border-black text-[16px] font-medium text-black cursor-pointer">Earnings Summary</span>.</p>
      </div>
      <div className="gap-4 border border-[#AFB5C1] p-5 rounded-2xl mt-4 px-8 space-y-1 sm:w-1/2">
        <p className='font-medium text-[24px]'>2023</p>
        <p className='text-[#4B4B4B]'>For tax documents issued prior to 2019, <span className="border-b border-black text-[16px] font-medium cursor-pointer">contact us.</span></p>
      </div>
      <div>
        <p className="text-[16px] font-medium">Customer Support</p>
        <span className='flex justify-between items-center gap-4 md:gap-12'>
          <p className="text-[#4B4B4B] font-normal">Contact our support from 24/7 from anywhere in the world </p>
          <p className="text-[16px] font-medium"><span className="border-b border-black cursor-pointer">Visit Help Centre</span></p>
        </span>
      </div>
    </div>
  )
}
