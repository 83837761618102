import React, { useState } from 'react'
import pointReferral from '../../assets/img/pointReferral.png'
import redeem from '../../assets/img/redeem.png'
import opportunities from '../../assets/img/opportunities.png'
import pic from '../../assets/img/pic.png'
import vector1 from '../../assets/img/Vector 1.png'
import vector2 from '../../assets/img/Vector2.png'
import vector3 from '../../assets/img/Vector3.png'
import vector4 from '../../assets/img/Vector4.png'
import recieveVector from '../../assets/img/recieveVector.png'
import shareVector from '../../assets/img/shareVector.png'
import trackVector from '../../assets/img/trackVector.png'
import redeemVector from '../../assets/img/redeemVector.png'
import Footerbn from 'pages/Footer/Footerbn'
import Container from "@mui/material/Container";
import { useNavigate, Link } from "react-router-dom";

const Influencer = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div className=''>
            <section className="flex justify-center items-center lg:mx-[7%]">
                <div className='flex flex-col lg:flex-row justify-between items-center w-full lg:w-full  gap-5 h-full mt-10 px-4 lg:px-0'>
                    <div className="flex flex-col w-full md:w-[87%] lg:w-1/2 py-10 justify-center ">
                        <h1 className="text-[30px] md:text-[45px] text-center lg:text-start font-bold">
                            Become a BnByond Influencer & earn up to <br />
                            <span className="text-[30px] md:text-[45px] text-center lg:text-start text-[#58C0D0]">20% per referral!</span>
                        </h1>
                        <p className="text-[#797979] text-[18px] text-center lg:text-start mt-4">
                            Earn points, enjoy unique stays, and access exclusive travel experiences with BnByond.
                        </p>
                        <div className="flex gap-2 md:gap-3 mt-5 px-1 lg:px-0 justify-center lg:justify-start">
                            <button className="rounded-full px-5 py-2 bg-[#2459BF] text-white" onClick={() => navigate("/auth/influencer-signup")}>JOIN NOW</button>
                            <button className="rounded-full px-5 py-2 border border-[#2459BF] text-[#2459BF]" onClick={() => navigate("/contactus")}>CONTACT US</button>
                        </div>
                    </div>
                    {/* <div className="bg-[#797979] rounded-3xl w-full md:w-[70%] lg:w-1/2 h-full flex items-center justify-center mt-6 lg:mt-0">
                    <img src={videoIcon} alt="" className="py-24" />
                </div> */}
                    <div className="flex  items-center justify-center lg:justify-end mt-5 w-full lg:w-1/2">
                        <video className="w-full max-w-[600px] h-auto rounded-3xl" autoPlay loop>
                            <source src="\video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </section>


            <section className="bg-[#58C0D0] rounded-2xl my-10 mx-4 md:mx-6 lg:mx-[7%] px-4 md:px-6">
                <div className="flex flex-col items-center py-12">
                    <span className="text-2xl md:text-[36px] text-white text-center mb-8">
                        Why Join Our Influencer Program?
                    </span>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full lg:w-[85%]">
                        <div className="bg-white rounded-xl shadow-lg">
                            <img src={pointReferral} alt="Earn Points for Referrals" className="w-full h-40 object-cover rounded-t-xl" />
                            <div className="px-3 py-4">
                                <h1 className="text-xl font-bold lg:w-3/4 xl:w-2/3">Earn Points for Referrals</h1>
                                <p className="text-[#797979] text-[16px] mt-2">
                                    Get rewarded for your influence! Earn points for every follower who signs up using your unique affiliate code. The more you refer, the more you earn!
                                </p>
                            </div>
                        </div>
                        <div className="bg-white rounded-xl shadow-lg">
                            <img src={redeem} alt="Redeem Points for Unique Stays" className="w-full h-40 object-cover rounded-t-xl" />
                            <div className="px-3 py-4">
                                <h1 className="text-xl font-bold">Redeem Points for Unique Stays</h1>
                                <p className="text-[#797979] text-[16px] mt-2">
                                    Turn your points into unforgettable experiences. Redeem your earned points for stays at exclusive properties within the BnByond.com network and enjoy luxury accommodations.
                                </p>
                            </div>
                        </div>
                        <div className="bg-white rounded-xl shadow-lg ">
                            <img src={opportunities} alt="Access Exclusive Travel Opportunities" className="w-full h-40 object-cover rounded-t-xl" />
                            <div className="px-3 py-4">
                                <h1 className="text-lg font-bold">Access Exclusive Travel Opportunities</h1>
                                <p className="text-[#797979] text-[16px] mt-2">
                                    Unlock new adventures and content creation opportunities at no cost. As a BnByond influencer, you'll gain special access to travel experiences and unique stays around the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="flex flex-col lg:flex-row justify-between gap-8 py-8 px-4 lg:px-0 lg:mx-[7%]">
                <div className="flex flex-col justify-between w-full lg:w-1/2">
                    <div className="flex flex-col items-center lg:items-start  pt-7">
                        <h1 className="text-[24px] lg:text-[28px] font-bold mb-2">How it Works?</h1>
                        <span className="text-[16px] lg:text-[18px] text-[#555] mb-4">Join, Share, Earn, and Enjoy</span>
                    </div>
                    <div className="flex items-center justify-center w-full h-auto lg:h-[230px] relative">
                        <img src={pic} alt="How it works" className="w-full h-full object-cover rounded-3xl shadow-lg" />
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-full lg:w-1/2">
                    {[
                        {
                            step: "1",
                            title: "Register",
                            description: "Register with BnByond and receive your unique affiliate code to start earning."
                        },
                        {
                            step: "2",
                            title: "Share Your Code",
                            description: "Promote your affiliate code to your followers through social media and other channels."
                        },
                        {
                            step: "3",
                            title: "Earn Points",
                            description: "Get rewarded with points for every follower who joins BnByond.com using your affiliate code."
                        },
                        {
                            step: "4",
                            title: "Redeem for Rewards",
                            description: "Use your points to book stays and exclusive experiences within the BnByond.com network."
                        }
                    ].map(({ step, title, description }) => (
                        <div key={step} className="flex items-center gap-4 md:gap-6">
                            <div className="w-16 h-10 md:w-14 md:h-14 rounded-md flex items-center justify-center text-white font-bold bg-[#58C0D0]">
                                {step}
                            </div>
                            <div className="flex flex-col gap-1">
                                <h1 className="text-[18px] md:text-[20px] font-bold">{title}</h1>
                                <p className="text-[14px] md:text-[16px] text-[#797979]">{description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>


            <section className="bg-[#58C0D0] my-10 px-4 lg:px-[7%]">
                <div className="flex flex-col lg:flex-row items-center justify-between py-5 gap-8">
                    <div className="flex flex-col w-full lg:w-[45%] pt-10 pb-8">
                        <h1 className="text-[30px] lg:text-[36px] text-white text-center lg:text-start font-bold">
                            Maximize Your Earnings with Our Referral Incentives
                        </h1>
                        <span className="text-white text-[16px] text-center md:text-start lg:text-[18px] md:pl-5 lg:pl-0">
                            Unlock Higher Rewards with Every Referral
                        </span>

                        <div className="flex flex-col gap-3 pt-10 w-full md:w-[70%] lg:w-full md:pl-5 lg:pl-0">
                            <h1 className="text-white text-[20px] lg:text-[24px] font-bold">How do I Earn?</h1>
                            <div className="flex flex-col gap-2">
                                {[
                                    { followers: "1 Referred Follower", points: "50 points" },
                                    { followers: "10 Referred Followers", points: "500 points" },
                                    { followers: "100 Referred Followers", points: "5000 points" },
                                ].map(({ followers, points }, index) => (
                                    // <div key={index} className="flex w-full items-center justify-between bg-white rounded-md ">
                                    //     <span className="flex-1 py-2 w-3/4">{followers}</span>
                                    //     <span className="bg-[#C1E1C2] flex-1 text-center py-2 w-1/4">{points}</span>
                                    // </div>
                                    <div key={index} className='flex w-full items-center justify-center'>
                                        <span className='bg-white flex items-center justify-center w-[70%] py-2'>{followers} </span>
                                        <span className='bg-[#C1E1C2] w-[30%] text-center py-2'>{points}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col bg-white rounded-xl w-full lg:w-[45%] pb-3">
                        {[
                            {
                                img: vector1,
                                title: "Earn Points",
                                description: "Receive 50 points for each follower who signs up using your unique affiliate code.",
                            },
                            {
                                img: vector2,
                                title: "Unlimited Potential",
                                description: "There’s no cap on the number of points you can earn. The more you refer, the more you gain.",
                            },
                            {
                                img: vector3,
                                title: "Track Your Progress",
                                description: "Easily monitor your referrals and points through your influencer dashboard.",
                            },
                        ].map(({ img, title, description }, index) => (
                            <div key={index} className="flex items-center gap-5 p-5">
                                <div className="w-12 h-7 md:h-12 lg:h-10 p-2   flex items-center justify-center bg-blue-100 rounded-full">
                                    <img src={img} className="w-full h-full object-contain" alt={title} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <h1 className="text-[18px] lg:text-[20px] font-bold">{title}</h1>
                                    <p className="text-[14px] lg:text-[16px] text-[#797979]">{description}</p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </section>


            <section className="px-4 md:px-0 lg:mx-[7%] my-14">
                <div className='flex flex-col items-center justify-center gap-3'>
                    <h1 className="text-center font-semibold text-[28px] lg:text-[32px]">
                        Where You Can Stay
                    </h1>
                    <p className='text-[#797979] text-[18px]'>Explore Our Destinations</p>
                </div>
                <div className="flex gap-8 mb-4">
                    <button
                        className={`relative px-4 py-2 text-[22px] ${activeTab === 1 ? 'text-[#2459BF] after:content-[""] after:absolute after:left-0 after:right-0 after:bottom-[-2px] after:h-[2px] after:bg-[#2459BF]' : 'text-[#313131]'}`}
                        onClick={() => setActiveTab(1)}
                    >
                        Currently Operated
                    </button>
                    <button
                        className={`relative px-4 py-2 text-[22px] ${activeTab === 2 ? 'text-[#2459BF] after:content-[""] after:absolute after:left-0 after:right-0 after:bottom-[-2px] after:h-[2px] after:bg-[#2459BF]' : 'text-[#313131]'}`}
                        onClick={() => setActiveTab(2)}
                    >
                        Further Expansion
                    </button>
                </div>

                {activeTab === 1 && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 bg-blue-50 p-5">
                        {[1, 1, 1, 1].map((_, index) => (
                            <div key={index} className='flex flex-col'>
                                <h1 className='font-semibold text-[20px] lg:text-[24px] mb-2 text-[#5A5A5A]'>North America</h1>
                                <div>
                                    <p className='text-[#313131] text-[16px]'>Mexico</p>
                                    <p className='text-[#313131] text-[16px]'>United States</p>
                                    <p className='text-[#313131] text-[16px]'>St Pierre and Miquelon</p>
                                </div>
                            </div>
                        ))}
                        {[1, 1, 1, 1].map((_, index) => (
                            <div key={index} className='flex flex-col'>
                                <h1 className='font-semibold text-[20px] lg:text-[24px] mb-2 text-[#5A5A5A]'>Central America</h1>
                                <div>
                                    <p className='text-[18px] text-[#313131]'>Honduras</p>
                                    <p className='text-[18px] text-[#313131]'>Guatemala</p>
                                    <p className='text-[18px] text-[#313131]'>El Salvador</p>
                                    <p className='text-[18px] text-[#313131]'>Costa Rica</p>
                                    <p className='text-[18px] text-[#313131]'>Belize</p>
                                    <p className='text-[18px] text-[#313131]'>Panama</p>
                                    <p className='text-[18px] text-[#313131]'>Nicaragua</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {activeTab === 2 && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 bg-blue-50 p-5">
                        {[
                            {
                                subcontinent: "Africa",
                                countries: [
                                    "Reunion",
                                    "Rwanda",
                                    "São Tomé and Príncipe",
                                    "Senegal",
                                    "Seychelles",
                                    "Sierra Leone",
                                    "Somalia"]
                            },
                            {
                                subcontinent: "Middle East",
                                countries: ["Qatar", "Saudi Arabia", "Syria"]
                            },
                            {
                                subcontinent: "Asia",
                                countries: ["Philippines", "Singapore", "Sri Lanka"]
                            },

                        ].map(({ subcontinent, countries }, index) => (
                            <div key={index} className='flex flex-col '>
                                <h1 className='font-semibold text-[20px] lg:text-[24px] mb-2 text-[#5A5A5A]'>{subcontinent}</h1>
                                <div className=''>
                                    {countries.map((country, countryIndex) => (
                                        <p key={countryIndex} className='text-[#313131] text-[18px]'>{country}</p>
                                    ))}
                                </div>
                            </div>
                        ))}

                    </div>
                )}
            </section >



            <section className="mx-4 lg:mx-[7%] my-14">
                <h1 className="text-center font-semibold text-[28px] lg:text-[32px] mb-8 lg:mb-12">
                    How Affiliate Code Works
                </h1>
                <div className="flex flex-col lg:flex-row justify-between md:items-center lg:items-start gap-8">
                    {[
                        {
                            bgColor: "#58C0D0",
                            imgSrc: recieveVector,
                            title: "Receive Your Code",
                            description: "Once you sign up for our Influencer Program, you will receive a unique affiliate code. This code is your key to earning rewards.",
                        },
                        {
                            bgColor: "#C1E1C2",
                            imgSrc: shareVector,
                            title: "Share Your Code",
                            description: "Share your affiliate code with your followers through social media, blogs, or any other platforms. Encourage them to use your code when they sign up on BnByond.com.",
                        },
                        {
                            bgColor: "#E8AD21",
                            imgSrc: trackVector,
                            title: "Track Your Earnings",
                            description: "Monitor your referrals and earned points through your personal dashboard. Stay updated on your progress and see how much you've earned.",
                        },
                        {
                            bgColor: "#C0392D",
                            imgSrc: redeemVector,
                            title: "Redeem Your Points",
                            description: "Use your points to book stays at unique properties or access exclusive travel experiences within the BnByond.com network.",
                        },
                    ].map(({ bgColor, imgSrc, title, description }, index) => (
                        <div key={index} className="flex flex-col items-center w-full md:w-2/4 lg:w-1/4 text-center">
                            <div className={`bg-[${bgColor}] w-[60px] h-[60px] p-3 rounded-full flex items-center justify-center`}>
                                <img src={imgSrc} alt={title} className="w-[24px] h-[32px]" />
                            </div>
                            <h1 className="text-[18px] lg:text-[20px] font-semibold pb-2 mt-6 lg:mt-10 lg:h-16 xl:h-auto">
                                {title}
                            </h1>
                            <p className="text-[#909090] text-[14px] lg:text-[16px]">
                                {description}
                            </p>
                        </div>
                    ))}
                </div>
            </section>


            <section className="bg-[#58C0D0] rounded-xl mx-4 lg:mx-[7%] my-10">
                <div className="flex flex-col lg:flex-row items-center lg:justify-between p-4 lg:p-6">
                    <div className="flex items-center justify-center w-full lg:w-2/3 xl:w-1/3 mb-6 lg:mb-0">
                        <span className="text-white text-[24px] lg:text-[40px] text-center lg:text-left w-full lg:w-2/3">
                            Track and Redeem Your Points
                        </span>
                    </div>
                    <div className="w-full lg:w-2/3 flex flex-col gap-4 lg:gap-6 items-center lg:items-start text-center lg:text-left">
                        <p className="text-white text-[14px] lg:text-[16px] w-full lg:w-11/12">
                            Easily monitor your referrals and earned points through our intuitive dashboard. Stay informed about who's signed up using your affiliate code and how many points you've accumulated.
                        </p>
                        <button className="bg-white px-4 py-2 rounded-full text-[#E8AD21] w-32">
                            JOIN NOW
                        </button>
                    </div>
                </div>
            </section>


            <section className="mx-4 lg:mx-[7%] my-10">
                <h1 className="text-center text-[24px] lg:text-[36px] font-bold">
                    Coming Soon
                </h1>
                <p className="text-center text-[14px] lg:text-[16px] text-[#797979] mt-2 lg:mt-4">
                    This is where you'll be able to access all of your statistical information.
                </p>
            </section>


            <div className="flex justify-center  w-full">

                <section className=" flex relative z-50 md:top-20  w-[96%]  lg:mb-10  ">
                    <div className="">
                        <img src={require("../../assets/img/icon.png")} className="absolute left-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] z-[-10]  " />
                    </div>
                    <Container sx={{
                        width: '100%',        // xl:w-full
                        maxWidth: '100%',     // xl:w-full
                        '@media (min-width: 1024px)': {
                            width: '90%',       // lg:w-[90%]
                            maxWidth: '90%',    // lg:w-[90%]
                        },
                    }} style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                        <div
                            className="bg-[#AF3227]/95 md:h-[300px] h-full lg:w-[100%] xl:w-[100%] mx-auto rounded-2xl "
                            style={{
                                backgroundImage:
                                    "url(" + require("assets/img/badgebg.png") + ")",
                                backgroundRepeat: "no-repeat",
                            }}
                        >

                            <Container>

                                <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">

                                    <div className="lg:pl-10">
                                        <div className="md:w-[400px] xl:w-[460px] flex items-center justify-center  w-full">
                                            <p className="text-white text-[25px] md:pt-0 md:text-[36px] font-bold">
                                                Join Our Influencer Program Today
                                            </p>
                                        </div>
                                        <div className="relative pt-24">

                                            <button className="text-[#C0392D] absolute left-0 bottom-5 text-base font-medium bg-white rounded-full md:w-[150px] w-full h-[50px]">
                                                GET STARTED
                                            </button>
                                        </div>
                                    </div>
                                    <div className='pt-10 z-50 '>
                                        <img src={vector4} className=' absolute bottom-64 rotate-45 md:rotate-0 md:bottom-20 md:right-[320px] lg:right-[45%] w-36 md:w-40 xl:w-auto' />
                                    </div>
                                    <div className="relative top-14 md:top-0 lg:right-5 xl:right-16">
                                        <img src={require("assets/img/squad2.png")} />
                                        <img
                                            src={require("assets/img/squad.png")}
                                            className="absolute z-40 top-4"
                                        />
                                    </div>
                                </div>
                            </Container>
                        </div>

                    </Container>

                    <div className="">
                        <img src={require("../../assets/img/icon.png")} className="absolute right-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] transform scale-x-[-1] z-[-10]   " />
                    </div>

                </section>

            </div>

            <Footerbn />

        </div >
    )
}

export default Influencer;
