// import React from 'react';
// import Grid from '@mui/material/Grid';
// import ListingColor from 'components/Cards/ListingColor';

// export default function AddOtherSitesLinks({ state, setState }) {

//     const handleLinkChange = (e) => {
//         setState((prevState) => ({
//             ...prevState,
//             link: e.target.value,
//         }));
//     };

//     const handleAddLink = () => {
//         if (state.link.trim()) {
//             setState((prevState) => ({
//                 ...prevState,
//                 isPropertyAvaibleOnOtherSites: [
//                     ...(prevState.isPropertyAvaibleOnOtherSites || []), 
//                     prevState.link.trim()
//                 ],
//                 link: '',  // Reset the input field after adding the link
//             }));
//         }
//     };

//     return (
//         <div>
//             <Grid container spacing={5}>
//                 <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: '10vh' }}>
//                     <ListingColor
//                         bg='bg-color-darknavyblue'
//                         text='Provide links if this property is listed on other platforms'
//                         color='text-[#FFFFFF]'
//                         btn='step 9'
//                     />
//                 </Grid>
//                 <Grid item lg={7} md={6} sm={12} xs={12}
//                     style={{ marginTop: '15vh', marginBottom: '8vh', height: "83vh", overflowY: 'auto', overflowX: 'hidden' }}>
//                     <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-md mx-auto">
//                         <h2 className="text-lg font-semibold mb-4">
//                             Add Links to Other Sites
//                         </h2>
//                         <p className="text-gray-600 mb-4">
//                             If your property is listed on other sites, please provide the URLs for verification purposes.
//                         </p>
//                         <div className="flex mb-4">
//                             <input
//                                 type="text"
//                                 value={state.link || ''}
//                                 onChange={handleLinkChange}
//                                 placeholder="Paste link here"
//                                 className="flex-1 px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             />
//                             <button
//                                 onClick={handleAddLink}
//                                 className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             >
//                                 Add
//                             </button>
//                         </div>
//                         {state.isPropertyAvaibleOnOtherSites && state.isPropertyAvaibleOnOtherSites.length > 0 && (
//                             <div className="my-4">
//                                 <p className="text-gray-700 font-semibold mb-2">Added Links:</p>
//                                 <ul className="list-disc list-inside text-gray-600">
//                                     {state.isPropertyAvaibleOnOtherSites.map((link, index) => (
//                                         <li key={index} className="mb-1">{link}</li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         )}
//                     </div>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// }

import React from "react";
import Grid from "@mui/material/Grid";
import ListingColor from "components/Cards/ListingColor";
import { useSelector, useDispatch } from "react-redux";
import { updateFormData } from "redux/store/actions/userAction/formAction";

export default function AddOtherSitesLinks() {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form); // Access Redux state

  const handleLinkChange = (e) => {
    dispatch(updateFormData({ link: e.target.value })); // Update the `link` field in Redux
  };

  const handleAddLink = () => {
    if (formData.link && formData.link.trim()) {
      dispatch(
        updateFormData({
          isPropertyAvaibleOnOtherSites: [
            ...(formData.isPropertyAvaibleOnOtherSites || []),
            formData.link.trim(),
          ],
          link: "", // Clear the input field after adding
        })
      );
    }
  };

  const handleRemoveLink = (linkToRemove) => {
    dispatch(
      updateFormData({
        isPropertyAvaibleOnOtherSites: formData.isPropertyAvaibleOnOtherSites.filter(
          (link) => link !== linkToRemove
        ),
      })
    );
  };

  return (
    <div>
      <Grid container spacing={5}>
        <Grid
          item
          lg={5}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "85vh", marginTop: "10vh" }}
        >
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Provide links if this property is listed on other platforms"
            color="text-[#FFFFFF]"
            btn="step 9"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          style={{
            marginTop: "15vh",
            marginBottom: "8vh",
            height: "83vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-md mx-auto">
            <h2 className="text-lg font-semibold mb-4">Add Links to Other Sites</h2>
            <p className="text-gray-600 mb-4">
              If your property is listed on other sites, please provide the URLs
              for verification purposes.
            </p>
            <div className="flex mb-4">
              <input
                type="text"
                value={formData.link || ""}
                onChange={handleLinkChange}
                placeholder="Paste link here"
                className="flex-1 px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                onClick={handleAddLink}
                className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Add
              </button>
            </div>
            {formData.isPropertyAvaibleOnOtherSites &&
              formData.isPropertyAvaibleOnOtherSites.length > 0 && (
                <div className="my-4">
                  <p className="text-gray-700 font-semibold mb-2">Added Links:</p>
                  <ul className="list-disc list-inside text-gray-600">
                    {formData.isPropertyAvaibleOnOtherSites.map((link, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center mb-1"
                      >
                        <span>{link}</span>
                        <button
                          onClick={() => handleRemoveLink(link)}
                          className="text-red-500 hover:text-red-700 ml-2"
                          aria-label="Remove link"
                        >
                          &times; {/* Cross icon */}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

