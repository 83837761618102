import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Rating } from "@mui/material";
import image1 from "../../assets/img/dp.png";
import image2 from "../../assets/img/dp.png";
import image3 from "../../assets/img/dp.png";
import image4 from "../../assets/img/dp.png";
import star from "../../assets/img/star.png";
import hosting from "../../assets/img/hostingimg.png";
import identity from "../../assets/img/identitysign.png";
import locationImg from "../../assets/img/locationimg.png";
import { PropertyCard } from "components/Cards/PropertyCard";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { GiCancel } from "react-icons/gi";

export const HostProfile = () => {
  const location = useLocation();
  const { state } = location;
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slideData = [
    {
      id: 1,
      image: image1,
      name: "Aplla",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 2,
      image: image2,
      name: "Abc",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 3,
      image: image3,
      name: "def ",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 4,
      image: image4,
      name: "ghi",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 5,
      image: image3,
      name: "si",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 6,
      image: image4,
      name: "fddb",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 7,
      image: image3,
      name: "Ange",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
    {
      id: 8,
      image: image4,
      name: "Angela ",
      subtitle: "Happy Customer",
      descripation:
        "I've traveled the world, but finding hidden gems on this platform made my trips truly unforgettable. From cozy cabins to chic city apartments, every stay has been a delight!",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: currentSlide,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <div className="flex flex-col w-full gap-10 px-5 md:flex-row lg:px-32 py-7 md:py-20">
      <div className=" mx-auto w-full md:w-[400px] rounded-xl border border-[#C1E1C2] px-5 py-7">
        <div className="flex items-center justify-center">
          <img
            className="object-cover w-40 h-40 rounded-full"
            src={state.user.pic}
            alt="Profile"
          />
        </div>
        <div className="items-center pb-10 mt-4 text-center spacej-y-8">
          <div>
            <p className="text-4xl font-medium capitalize">
              {" "}
              {state.user.fname}
            </p>
            <p className="text-xl font-medium text-gray-500 capitalize">Host</p>
            <div className="flex items-center justify-center mb-4">
              <div className="px-2">
                <img src={locationImg} alt="location" />
              </div>
              <p className="flex font-semibold capitalize">
                {/* Los Angelos */}
                {state.user.address}
              </p>
            </div>
          </div>
          <hr className="my-4 border-t border-[#C1E1C2]" />
          <div className="flex flex-col items-center justify-center space-y-3">
            <p className="text-lg font-semibold"> Nela confirmed information</p>
            <p className="flex gap-2 text-xl font-normal">
              <span className=" mt-1 w-[16.36px] h-[20px]">
                <img src={star} alt="" />
              </span>{" "}
              Identity Verified
            </p>
            <p className="flex gap-2 text-xl font-normal">
              <span className=" mt-1 w-[16.36px] h-[20px]">
                <img src={hosting} alt="" />
              </span>{" "}
              Host
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-auto bg-white rounded-xl overflow-hidden border border-[#C1E1C2] md:p-10 p-2 space-y-6 ">
        <p className="text-2xl font-medium">About {state.user.fname}</p>
        <div className="flex flex-wrap space-x-8">
          <p className="flex gap-2 sm:px-0 px-7">
            <span>
              <img src={star} alt="" />
            </span>{" "}
            253 Reviews
          </p>
          <p className="flex items-center gap-2 py-2 sm:py-0">
            <span>
              {state.user.verify == "yes" ? (
                <img src={identity} alt="" />
              ) : (
                <GiCancel className="text-[#58C0D0] text-xl" />
              )}
            </span>
            {state.user.verify == "yes"
              ? "Identity Verfied"
              : "Identity Unverfied"}
          </p>
          <p className="flex gap-2">
            <span>
              <img src={hosting} alt="" />
            </span>{" "}
            1 Year Hosting
          </p>
          <p className="flex gap-2 py-1 pr-1 sm:py-0 sm:pr-0">
            <span>
              <img src={star} alt="" />
            </span>{" "}
            4.5 Rating
          </p>
        </div>
        <div className="px-4 space-y-2">
          <p className="font-normal text-md">{state.user.about}</p>
          <p className="font-normal underline text-md">Read More </p>
        </div>
        <hr className="my-4 bottom-4 border-[#C1E1C2]" />
        <div className="space-y-6">
          <span className="flex justify-between">
            <p className="text-2xl font-medium">About Antonio Tony</p>
            <p className="mt-1 font-medium underline">View All</p>
          </span>
          <Slider {...settings} ref={sliderRef} initialSlide={currentSlide}>
            {slideData.map((item) => (
              <div className="px-3 py-2">
                <div className=" px-4 py-4 rounded-xl bg-[#F8F8F8] space-y-3">
                  <div className="flex items-center gap-4">
                    <img src={item.image} className="h-[48px] w-[48px]" />
                    <div>
                      <h4 className="text-lg text-[#000000] font-normal">
                        {item.name}
                      </h4>
                      <p className="text-base text-[#646464] font-normal">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                  <Rating name="size-small" readOnly defaultValue={2} />
                  <p className="text-[#00000] text-base font-normal leading-7">
                    {item.descripation}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="flex justify-end space-x-5">
            <button
              className="p-2 transition duration-300 bg-gray-300 rounded-full hover:bg-yellow-400 focus:outline-none"
              onClick={handlePrev}
            >
              <FaChevronLeft />
            </button>
            <button
              className="p-2 transition duration-300 bg-gray-300 rounded-full hover:bg-yellow-400 focus:outline-none"
              onClick={handleNext}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
        <hr className="my-4 bottom-4 border-[#C1E1C2]" />

        <PropertyCard id={state.userId} />
      </div>
    </div>
  );
};
