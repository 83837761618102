import React, { useEffect, useState } from 'react'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';

const dummyData = [
    {
        bookingId: "ABCDEFGHI",
        checkInOut: "June 14, 2023 - Sep 14, 2023",
        location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
        transactions: "$3253",
        status: "Refund"
    },
    {
        bookingId: "JKLMNOPQR",
        checkInOut: "July 1, 2023 - July 7, 2023",
        location: "123 Main St, Anytown, USA 12345",
        transactions: "$1500",
        status: "Paid"
    },
    {
        bookingId: "STUVWXYZ1",
        checkInOut: "August 15, 2023 - August 22, 2023",
        location: "789 Beach Rd, Seaside, FL 67890",
        transactions: "$2100",
        status: "Refund"
    },
    {
        bookingId: "234567890",
        checkInOut: "September 3, 2023 - September 10, 2023",
        location: "456 Mountain View, Denver, CO 80201",
        transactions: "$1800",
        status: "Paid"
    },
];

export const TrackPayments = () => {
    const [allReservations, setAllReservations] = useState([])
    const [filteredReservations, setFilteredReservations] = useState([])
    const [filterStatus, setFilterStatus] = useState("All")

    // fetching reservation data
    const getReservation = () => {
        setAllReservations(dummyData);
        setFilteredReservations(dummyData);
    };

    useEffect(() => {
        getReservation();
    }, []);

    useEffect(() => {
        if (filterStatus === "All") {
            setFilteredReservations(allReservations);
        } else {
            setFilteredReservations(allReservations.filter(reservation => reservation.status === filterStatus));
        }
    }, [filterStatus, allReservations]);

    const handleFilterChange = (status) => {
        setFilterStatus(status);
    };

    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-[#C1E1C2] h-auto w-full p-4">
                <div className='p-5 flex justify-between'>
                    <p className="text-2xl capitalize font-medium">
                        Transaction History
                    </p>
                    <div className="h-[40px] w-[100px] rounded-[50px] border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2 relative cursor-pointer" onClick={() => document.getElementById('filterDropdown').classList.toggle('hidden')}>
                        <span>{filterStatus}</span>
                        <div className="my-auto">
                            <IoIosArrowDown />
                        </div>
                        <div id="filterDropdown" className="absolute top-full left-0 mt-1 w-full bg-white border border-[#AEAFB0] rounded-md shadow-lg hidden">
                            <div className="py-1">
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("All")}>All</div>
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("Refund")}>Refund</div>
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("Paid")}>Paid</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-fixed">
                        <thead className='bg-color-secondry bg-opacity-10'>
                            <tr className="space-x-3">
                                <th className="py-4 px-4 text-left font-semibold">Booking ID#</th>
                                <th className="py-4 px-4 text-left font-semibold">Check-In's & Out's</th>
                                <th className="py-5 px-4 text-left font-semibold">Location</th>
                                <th className="py-4 px-4 text-left font-semibold">Transactions</th>
                                <th className="py-4 px-4 text-left font-semibold">Status</th>
                                <th className="py-4 px-4 text-left font-semibold"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReservations.map((reservation, index) => (
                                <tr key={index} className="border-b-2 border-color-[#AFB5C1] text-[#4B4B4B] text-[16px]">
                                    <td className="py-5 px-4 font-normal">{reservation.bookingId}</td>
                                    <td className='py-5 px-4 font-normal'>{reservation.checkInOut}</td>
                                    <td className='py-5 px-4 font-normal'>{reservation.location}</td>
                                    <td className='py-5 px-4 font-normal'>
                                        <span className={`p-1 px-5 rounded-md 
                            ${reservation.status === 'Paid' ? 'text-[#2459BF] bg-[#58C0D0] bg-opacity-10 font-medium' : 'text-[#EC3434] bg-[#EC3434] bg-opacity-10'} font-medium`}>
                                            {reservation.transactions}
                                        </span>
                                    </td>
                                    <td className='py-5 px-4 font-normal'>{reservation.status}</td>
                                    <td className=" py-5 px-4">
                                        <IoIosArrowForward className="w-6 h-6 cursor-pointer" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}