import BlogPost from "pages/Blogs/BlogPost";
import AdminSettings from "pages/admin/AdminSettings/AdminSettings";
import FinancialTransactions from "pages/admin/FinancialTransactions/FinancialTransactions";
import AdminDashboard from "pages/admin/MainDashboard";
import Properties from "pages/admin/PropertyManagement/Properties";
import ReferralPrograms from "pages/admin/ReferralProgram";
import Reservation from "pages/admin/ReservationManagement/Reservation";
import AdminReviews from "pages/admin/Reviews/AdminReviews";
import Permissions from "pages/admin/Roles_Permissions";
import SupportTicketing from "pages/admin/SupportTicketing/SupportTicketing";
import UserManagement2 from "pages/admin/UserManagement/UserManagement2";
import RefundReservation from "pages/admin/RefundReservation/RefundReservation";
import AddUser from "pages/admin/AddNewUser/AddUser";
import AllPost from "pages/Blogs/AllPost";
import React from "react";
import { Route, Routes } from "react-router-dom";

const AdminSidebarSteps = ({
  active,
  Dashboard,
  Management,
  PropertiesDatials,
  ReservationDatials,
  Financial,
  Reviews,
  Referral,
  Settings,
  Supports,
  Roles,
  Refund,
  allProperty,
  blogPost,
}) => {
  return (
    <>
      <Routes>
        <Route path="" element={<AdminDashboard />} />
        <Route path="/user-management" element={<UserManagement2 />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/financial" element={<FinancialTransactions />} />
        <Route path="/reviews" element={<AdminReviews />} />
        <Route path="/referral-programs" element={<ReferralPrograms />} />
        <Route path="/support-ticketing" element={<SupportTicketing />} />
        <Route path="/permissions" element={<Permissions />} />
        <Route path="/settings" element={<AdminSettings />} />
        <Route path="/blog-post" element={<BlogPost />} />
        <Route path="/refund-reservation" element={<RefundReservation />} />
        <Route path="/all-posts" element={<AllPost />} />
        <Route path="/add-user" element={<AddUser />} />
      </Routes>

      {/* {active === 1 && <AdminDashboard Dashboard={Dashboard} />}
      {active === 2 && <UserManagement2 Management={Management} />}
      {active === 3 && <Properties PropertiesDatials={PropertiesDatials} />}
      {active === 4 && <Reservation ReservationDatials={ReservationDatials} />}
      {active === 5 && <FinancialTransactions Financial={Financial} />}
      {active === 6 && <AdminReviews Reviews={Reviews} />}
      {active === 7 && <ReferralPrograms Referral={Referral} />}
      {active === 8 && <SupportTicketing Supports={Supports} />}
      {active === 9 && <Permissions Roles={Roles} />}
      {active === 10 && <AdminSettings Settings={Settings} />}
      {active === 11 && <BlogPost Settings={Settings} />}
      {active === 12 && (
        <RefundReservation Refund={Refund} propertyData={allProperty} />
      )}
      {active === 13 && <AllPost blogPost={blogPost} active={active} />}
      {active === 14 && <AddUser blogPost={blogPost} active={active} />} */}
    </>
  );
};

export default AdminSidebarSteps;
