import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import bed from "../../assets/img/angela.png";
import user from "../../assets/img/angela.png";
import { useNavigate } from "react-router-dom";
const ReviewComponent = () => {
  const navigate = useNavigate("");
  return (
    <>
      <div className=" mx-auto p-5">
        <div className="mb-7 mt-5">
          <h1 className="text-2xl font-semibold">Add Review</h1>
        </div>
        <div

          className="  border rounded-lg overflow-hidden"
        >
          <p className="bg-gray-200 p-3">Review to write</p>
          <div className="flex flex-col md:flex-row items-center   mb-10  p-3 ">
            <div className="flex flex-col  ">
              <img
                src={bed}
                alt=""
                className="w-28 h-28 rounded-full object-cover mb-2 md:mb-0"
              />
              <div className=" ">
                <p className="ml-1 font-semibold text-md">Nela Scarlett</p>
                <div className="flex items-center gap-2">
                  <FaLocationDot />
                  <p className="text-sm">Los Angeles</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-2">
              <p className="mb-3">
                You have <strong>111 days</strong> to write a public review for
                your stay at <strong>Nela Scarlett</strong>
              </p>
              <p className="mb-3 text-red-500 text-sm cursor-pointer">
                Write Review
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => navigate("/rateandreview")} className="flex flex-col overflow-hidden border rounded-lg mb-8 mt-4">
          <div className="bg-gray-200 p-3 ">
            <p>Past Reviews</p>
          </div>
          <div className="flex flex-col md:flex-row  gap-4 p-3">
            <img
              src={user}
              alt=""
              className="w-20 h-20 rounded-full object-cover mb-2 md:mb-0 md:mr-2"
            />
            <div>
              <p className="font-bold">Hamza's Review</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Placeat, explicabo.Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Placeat, explicabo.Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Placeat, explicabo.Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Placeat,
                explicabo.Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Placeat, explicabo.Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Placeat, explicabo.Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Placeat, explicabo.
              </p>
              <p className="text-red-500">January 1, 2024</p>
            </div>
          </div>
          <hr />
          <div onClick={() => navigate("/rateandreview")} className="flex flex-col md:flex-row  gap-4 p-3">
            <img
              src={bed}
              alt=""
              className="w-20 h-20 rounded-full object-cover mb-2 md:mb-0 md:mr-2"
            />
            <div>
              <p className="font-bold">Review from Ishtiaq</p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Debitis, omnis. Lorem ipsum dolor, sit amet consectetur
                adipisicing elit. Debitis, omnis. Lorem ipsum dolor, sit amet
                consectetur adipisicing elit. Debitis, omnis. Lorem ipsum dolor,
                sit amet consectetur adipisicing elit. Debitis, omnis.
              </p>
              <p className="text-red-500">February 12, 2024</p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ReviewComponent;
