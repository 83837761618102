import * as React from "react";
import { useMutation } from "react-query";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { localStorageData, Logout, storeLocalData } from "services/auth/localStorageData";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { useChat } from "common/contexts/chatConversationsContext";
import { ChatNotificationsList } from "components/ChatNotificationsList";

const IndexDropdown = ({ onRoleChange }) => {
  let navigate = useNavigate();
  let location = useLocation();
  let loginAs = localStorageData("loginAs");
  let userId = localStorageData("_id");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openNotificationMenu, setOpenNotificationMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(openNotificationMenu); // determine if the notification menu is open
  const { conversationData2, isLoading2, error, fetchConversations } = useChat(); // Hook for chat conversations
  let userData = localStorageData("roles");

  let isInfluencer = userData?.includes("Influencer"); // Check if the user has the "Influencer" role

   const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event) => {
    setOpenNotificationMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenNotificationMenu(null);
  };

  // Function to change the user role
  const handleChangeRole = (role) => {
    mutate({ loginAs: role, userId });
  }

  //handling role change API request
  const { mutate, isLoading } = useMutation(
    (token) =>
      userServices.updatePostService("/userAuth/updateRole", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        storeLocalData(data?.data.user);
        toast.success("User role changed successfully");
        onRoleChange(data?.data.user.loginAs); // Call the callback to update the role in BnbNav
        navigate("/");
      },
    }
  );

  // Calculate the total number of unread messages
  const totalUnreadMessages = conversationData2?.reduce(
    (total, conversation) => total + conversation.unreadMessageCount,
    0
  );
  return (
    <>
      <div className="flex items-center gap-2">
        <div onClick={handleOpenMenu} className={` ${location.pathname == '/' ? 'bg-white' : 'border border-[#C1E1C2]'} relative bg-white flex items-center justify-center w-[37.27px] h-[37.27px] rounded-full`}>
          <div className="text-center text-[20px] flex items-center justify-center w-[17.58px] h-[20px] cursor-pointer">
            <img src={require('assets/img/ballicon.png')} />
          </div>
          {
            !openNotification && totalUnreadMessages ?
              (<div className="absolute flex items-center justify-center w-3 h-3 text-white bg-[#E8AD21] rounded-full top-1 right-1">
                {totalUnreadMessages > 0 ? <span className="text-[10px]">{totalUnreadMessages}</span> : ""}
              </div>) : ""
          }
        </div>
        <a onClick={handleClick}>
          <div className={`${location.pathname == '/' ? 'bg-white' : 'border border-[#C1E1C2]'} flex justify-between gap-x-3 items-center rounded-full w-auto h-[37.27px]`}>
            <img
              className="object-cover w-[28px] ml-2 h-[28px] rounded-full "
              src={localStorageData("pic")}
            />
            <button className="text-[#000000] text-base font-normal">
              {localStorageData("fname")}
            </button>
            <div>
              <IoIosArrowDown className="text-[#000000] mr-1" />
            </div>
          </div>
        </a>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-[300px] p-4 ">
            <div className="flex items-center gap-2 px-1">
              <div className="">
                <img src={localStorageData("pic")} className="rounded-full w-[60px] h-[60px] object-cover" />
              </div>
              <div>
                <h4 className="text-[#000000] text-lg  font-semibold">
                  {localStorageData("fname")} {localStorageData("lname")}
                </h4>
                <p>
                  <Link to="/profile" className="text-[#4B4B4B] text-md font-normal"> See Profile</Link>
                </p>
              </div>
            </div>
            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose(); // Close the menu
                navigate("/comming-soon");
              }}
            >
              Message
            </div>
            {loginAs === "Host" &&
              <div
                className="px-1 py-2 cursor-pointer hover:bg-gray-300"
                onClick={() => {
                  handleClose(); // Close the menu
                  navigate("/my-properties");
                }}
              >
                My Properties
              </div>
            }

            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose(); // Close the menu
                navigate("/mytrip");
              }}
            >
              My Trips
            </div>


            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose(); // Close the menu
                navigate("/wishlist");
              }}
            >
              Wishlist
            </div>

            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose(); // Close the menu
                navigate("/referrals");
              }}
            >
              Reward/points
            </div>
            {localStorageData("isAdmin") ? "" : null}
            {isInfluencer ? null : (
              loginAs === "Guest" ? (
                <div
                  className="px-1 py-2 cursor-pointer hover:bg-gray-300"
                  onClick={() => {
                    handleClose(); // Close the menu
                    handleChangeRole("Host");
                  }}
                >
                  Switch to Host
                </div>
              ) : (
                <div
                  className="px-1 py-2 cursor-pointer hover:bg-gray-300"
                  onClick={() => {
                    handleClose();
                    handleChangeRole("Guest");
                  }}
                >
                  Switch to Guest
                </div>
              )
            )}

            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                handleClose();
                navigate("/profile");
              }}
            >
              My Account
            </div>
            <Divider />
            <div
              className="px-1 py-2 cursor-pointer hover:bg-gray-300"
              onClick={() => {
                Logout();
                navigate("/auth/signin");
              }}
            >
              Logout
            </div>
          </div>
        </Menu>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={openNotificationMenu}
          open={openNotification}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="min-w-[200px] w-auto ">
            {totalUnreadMessages ? <ChatNotificationsList handleClose={handleCloseMenu} /> : <Typography py={2} px={4} variant="body1" color="initial">No Notifications Yet</Typography>}
          </div>
        </Menu>
      </div>
    </>
  );
};

export default IndexDropdown;
