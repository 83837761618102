import React, { useEffect, useState } from 'react'
import ActiveProperty from './ActiveProperty'
import PendingProperty from './PendingProperty'
import RejectedProperty from './RejectedProperty'
import userServices from 'services/httpService/userAuth/userServices'
import { localStorageData } from 'services/auth/localStorageData'

const PropertyListSteps = ({ active, PendingProperties, RejectedProperties, ActiveProperties }) => {
  const [activeProperty, setActiveProperty] = React.useState([]);
  const [pendingProperty, setPendingProperty] = useState([]);
  const [rejectedProperty, setRejectedProperty] = useState([]);

  const userId = localStorageData("_id");

  const getproperty = async () => {
    try {
      // Fetch properties by user ID using a common get service function
      let res = await userServices.commonGetService(`/property/getPropertyByUserId/${userId}`);
      if (res.data && Array.isArray(res.data.data)) {

        // Filter properties by their status
        const activeProperties = res.data.data.filter(property => property.status === "Active");
        const pendingProperties = res.data.data.filter(property => property.status === "Pending");
        const rejectedProperties = res.data.data.filter(property => property.status === "Rejected");
        setActiveProperty(activeProperties);
        setPendingProperty(pendingProperties);
        setRejectedProperty(rejectedProperties);
      } else {
        console.error("Error: Unable to fetch property data");
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
    }
  }

  useEffect(() => {
    getproperty();
  }, []);
  return (
    <>
      {active === 1 && <PendingProperty pendingProperty={pendingProperty} />}
      {active === 2 && <ActiveProperty activeProperty={activeProperty} />}
      {active === 3 && <RejectedProperty rejectedProperty={rejectedProperty} />}
    </>
  )
}

export default PropertyListSteps