import React from 'react';
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';

import { config } from '../../config';
import { useCreateGoogleAccount } from 'hooks/useCreateGoogleAccount';

function GoogleApi(props) {
  let navigate = useNavigate();
  const { mutateAsync: createGoogleAccount } = useCreateGoogleAccount();

  // Function to handle Google login success
  async function GoogleSuccessRequest(response) {
    // Create a new object with user data from Google login response
    let newdata = {
      email: response.profileObj.email,
      fname: response.profileObj.givenName,
      id: response.googleId,
      pic: response.profileObj.imageUrl,
    };

    // Call the function to create a Google account with the new data
    try {
      const res = await createGoogleAccount(newdata);
      console.log("Response data:", res);

      if (res.status) {
        let userData = res.data;
        userData['pic'] = config.ImageEndPoint + userData.pic;

        navigate('/');
      }
    } catch (error) {
      console.error("Error creating Google account:", error);
    }
  }

  // Function to handle Google login failure
  function GoogleFailureRequest(error) {
    console.error("Google login failed:", error);
  }

  return (
    <GoogleLogin
      clientId={config.googleapi}
      render={(renderProps) => (
        <button
          className='bg-white text-gray-900 rounded-full my-2 border border-gray-200 uppercase shadow-sm font-semibold  px-3 py-2 w-full items-center justify-center  outline-none focus:outline-none mr-2  hover:shadow-md ease-linear transition-all duration-150'
          onClick={renderProps.onClick}
          disabled={renderProps.disabled} 
        >
          <i className='fab fa-google'></i>
          <span className='ml-4'> {props.name}</span>
        </button>
      )}
      onSuccess={GoogleSuccessRequest}
      onFailure={GoogleFailureRequest}
      cookiePolicy={'single_host_origin'}
    />
  );
}

export default GoogleApi;
