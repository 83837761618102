import React from "react";
import CancelledCard from "./CancelledCard";
import HistoryCard from "./HistoryCard";
import UpComming from "./UpComming";

const TripCard = ({ active, upcoming, history, Cancelled, currentUserId }) => {
  return (
    <>
      {active === 1 && <UpComming upcoming={upcoming} />}
      {active === 2 && <CancelledCard Cancelled={Cancelled} />}
      {active === 3 && <HistoryCard history={history} userId={currentUserId} />}
    </>
  );
};

export default TripCard;
