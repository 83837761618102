import React, { useState } from 'react';

const CustomDropdown = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const options = [
        { value: 'Basic Permission', label: 'Basic Permission' },
        { value: 'Listing Management', label: 'Listing Management' },
        { value: 'Guest Management', label: 'Guest Management' },
        { value: 'Pricing & Availability', label: 'Pricing & Availability' },
        { value: 'Website Management', label: 'Website Management' },
        { value: 'Tickets Management', label: 'Tickets Management' }
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    return (
        <div className="relative w-full">
            <div className="relative">
                <button
                    type="button"
                    className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring focus:ring-indigo-300 focus:border-indigo-300"
                    onClick={toggleDropdown}
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                >
                    <span className="block truncate">{selectedOption ? selectedOption : 'Permission'}</span>
                    <svg
                        className="w-5 h-5 ml-2 -mr-1 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 9.293l-4.146-4.147a1 1 0 10-1.414 1.414l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414L10 9.293z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>

                {isOpen && (
                    <ul
                        className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white border border-gray-300 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        tabIndex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                    >
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className="relative py-2 pl-9 text-gray-900 cursor-default select-none hover:bg-gray-100"
                                onClick={() => handleOptionClick(option.value)}
                                role="option"
                            >
                                <span className="block truncate">{option.label}</span>
                                <input
                                    type="radio"
                                    className="absolute top-0 left-0 mt-3 ml-3"
                                    name="options"
                                    value={option.value}
                                    checked={selectedOption === option.value}
                                    onChange={() => { }}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default CustomDropdown;
